import { Box, Stack, Typography, Paper, Skeleton } from '@mui/material';
import NewsService from '../../../services/newsService';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import humanize from '../../../services/humanize';
import { Link } from 'react-router-dom';

export default function TopPerformersWidget() {
    const { data: mostGainerCompaniesData } = useQuery(['getMostGainerStockCompanies'], NewsService.getMostGainerStockCompanies);
    const [logoLoaded, setLogoLoaded] = useState(false);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%'
            }}
        >
            <Stack direction={'row'} mb={2}>
                <Typography sx={{ fontFamily: 'inter', fontSize: '24px', fontWeight: '600', color: 'white' }}>
                    Top performers
                </Typography>
            </Stack>
            <Stack direction={'row'} spacing={2}>
                {mostGainerCompaniesData?.slice(0, 3).map((company, index) => (
                    <Link
                        key={index}
                        to={`/detailedData/${company.symbol}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <Paper
                            key={index}
                            elevation={3}
                            style={{ borderRadius: '50px' }}
                            sx={{
                                padding: 2,
                                width: '170px', // Adjust width as necessary
                                height: '60px', // Adjust height as necessary
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#fff', // Change color as needed
                            }}
                        >
                            <Box>
                                <img
                                    src={`https://financialmodelingprep.com/image-stock/${company.symbol}.png`}
                                    alt="logo"
                                    height="40px"
                                    style={logoLoaded ? {} : { display: 'none' }}
                                    onLoad={() => setLogoLoaded(true)}
                                />
                                <Skeleton variant="circular" width={40} height={40} style={logoLoaded ? { display: 'none' } : {}} />
                            </Box>
                            <Stack sx={{ marginLeft: '20px' }} direction={'column'}>
                                <Typography sx={{ fontFamily: 'inter', fontSize: '16px', fontWeight: '700', color: '#000', textAlign: 'center' }}>
                                    {company.symbol}
                                </Typography>
                                <Typography sx={{ fontFamily: 'inter', fontSize: '16px', fontWeight: '500', color: '#0EAF00', textAlign: 'center' }}>
                                    +{humanize.compactNumber(company.changesPercentage, 2)}%
                                </Typography>
                            </Stack>
                        </Paper>
                    </Link>
                ))}
            </Stack>
        </Box>
    );
}