import { Box } from '@mui/material';
import AnalyticsGrid from '../../../components/analyticsGrid/analyticsGrid';
import ShareGridStateDto from '../../../models/dtos/shareGridStateDto';

interface QuickScreenerProps{
  sharedScreenConfiguration?: ShareGridStateDto | null;
}

export default function QuickScreener({
  sharedScreenConfiguration = undefined,
}: QuickScreenerProps) {
  return (
    <Box sx={{ minHeight: '100%', width: '100%', minWidth: '700px' }}>
      <AnalyticsGrid
        customColumnVisibilityModel={{
          consecutiveYearsOfDividendIncrease: false,
          qShiftPreviousYear: false,
          grahamNetNet: false,
          roe: false,
          roic: false,
          quickRatioTTM: false,
          currentRatioTTM: false,
          pegRatioTTM: false,
          priceCashFlowRatioTTM: false,
          priceToFreeCashFlowsRatioTTM: false,
          grossProfitMarginTTM: false,
          netProfitMarginTTM: false,
          returnOnAssetsTTM: false
        }}
        showGoalTextField={false}
        hiddenColumns={['moneyNeededToGoal']}
        screenerName="quick"
        showAll
        showFavourite
        showIgnored={false}
        sharedScreenConfiguration={sharedScreenConfiguration}
      />
    </Box>
  );
}
