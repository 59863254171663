import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAdsContext } from '../../../contexts/AdsContext';
import OverviewGrid from './components/OverviewGrid';

interface PortfolioOverviewProps {
  selectedPortfolioIds?: number[];
  selectedPortfolioName?: string;
}

export default function PortfolioOverview({selectedPortfolioIds, selectedPortfolioName} : PortfolioOverviewProps) {
  const adsContext = useAdsContext();

  return (
    <Box
      sx={{
        height: '90%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px',
      }}
    >
      <Helmet>
        <title>Portfolio Overview | Track Your Investment Performance | StockTradeIQ</title>
      </Helmet>
      <OverviewGrid selectedPortfolioIds={selectedPortfolioIds} selectedPortfolioName={selectedPortfolioName}/>
    </Box>
  );
}
