import { Box } from '@mui/material';
import MainLinearChart from '../../../../components/charts/MainLinearChart';

interface MainDividendChartProps{
  dividendValueHistoryQuery: any,
  isFetching: boolean,
  isLoading: boolean,
}

export default function MainDividendChart({
  dividendValueHistoryQuery, isFetching, isLoading,
}: MainDividendChartProps) {
  return (
    <Box sx={{ flexGrow: 1, height: '100%' }} className="center-items">
      <MainLinearChart
        data={dividendValueHistoryQuery}
        title="Dividend Value"
        step="end"
        isLoading={isFetching || isLoading}
        showTitle={false}
      />
    </Box>
  );
}
