import { useQuery } from '@tanstack/react-query';
import PieChart from '../../../../components/charts/PieChart';
import PortfolioAnalysisService from '../../../../services/portfolioAnalysisService';

interface Props {
  selectedPortfolioIds: number[]
}

export default function PieChartBySector({selectedPortfolioIds} : Props) {
  const { data, isFetching } = useQuery(
    ['getPortfolioValueBySectors', selectedPortfolioIds],
    () => PortfolioAnalysisService.getPortfolioValueBySectors(selectedPortfolioIds),
  );

  return (
    <PieChart
      data={data}
      label="Sectors"
      isLoading={isFetching}
      xName="sector"
      yName="value"
      seriesName="Sector"
    />
  );
}
