import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useTreeExchangeStyles = makeStyles((theme: Theme) => ({
  treeExchange: {
    zIndex: '1000',
    backgroundColor: theme.palette.background.paper,
    color: `${theme.palette.text.primary} !important`,
    maxWidth: '300px',
    '& .dropdown-trigger': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
    '& .tag': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: `${theme.shape.borderRadius}px !important`,
      backgroundColor: theme.palette.background.paper,
    },
    '& .search': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: `${theme.shape.borderRadius}px !important`,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    '& .dropdown-content': {
      borderRadius: `${theme.shape.borderRadius}px !important`,
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
    '& .node.focused': {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
    '& .arrow:after': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
}));

export default useTreeExchangeStyles;
