import axios from "axios";

const postNewAlert = async (data: Object) => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Alert/Create`;
    const result = await axios.post(URL, data);
    return result;
  };

  const AlertService = {
    postNewAlert
  };
  export default AlertService;