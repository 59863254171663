import { Box, Skeleton } from '@mui/material';
import Humanize from '../../../../../services/humanize';
import { ReactNode } from 'react';

interface TableRowPropsModel{
  label: string,
  value: ReactNode,
  isLoading: boolean,
  rowNumber: number 
}

function humanizeValue(value: string | number | ReactNode) {
  if (typeof value === 'number') {
    return Humanize.compactNumber(value, 3);
  }
  return value;
}

export default function TableRow({ label, value, isLoading, rowNumber }: TableRowPropsModel) {
  return (
    <Box sx={[{
      display: 'flex', width: '100%', justifyContent: 'space-between', padding: '0 10px 0 10px', borderRadius: '6px 6px 6px 6px', background: rowNumber % 2 ? "#F9F9F9" : "#FFFFFF",
    },
    {
      '&:hover': {
        bgcolor: 'action.hover',
      },
    },
    ]}
    >
      <Box sx={{
        textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%', textAlign: 'start',
      }}
      >
        {isLoading
          ? <Skeleton width="min(200px, 90%)" height="24px" />
          : label}
      </Box>
      <Box sx={{
        whiteSpace: 'nowrap', justifyContent: 'end', flexShrink: 0,
      }}
      >
        {isLoading
          ? <Skeleton width="100px" height="24px" />
          : humanizeValue(value)}
      </Box>
    </Box>
  );
}
