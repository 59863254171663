import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import useKeyTakeawaysStyles from '../useKeyTakeawaysStyles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


interface STIScorePropsModel {
  score: number,
  color?: string
}

export default function STIScore(props: STIScorePropsModel) {
  const classes = useKeyTakeawaysStyles();
  const { score } = props;
  return (
    <Stack sx={{ width: '100%', height: '100%' }} direction={"row"}>
      <Box
        sx={{
          backgroundColor: props.color ?? '#4CAF50',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          paddingLeft: '30%',
          paddingRight: '30%'
        }}
      >
        <Stack direction={"row"} alignContent={'center'}>
          <Typography variant="h6" color="white" align='center'>
            {"STI:"}
          </Typography>
          <Typography variant="h6" color="white" style={{ marginLeft: '5px' }}>
            {score}
          </Typography>
        </Stack>
      </Box>
      <Box position={'relative'}>
        <Tooltip style={{ position: 'absolute', top: '-10px'}} title="STI score = 10 - 2 * [number of adverse key takeaways] + [number of favorable key takeaways]">
          <IconButton size="small" >
            <HelpOutlineIcon style={{ fontSize: "1rem" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
}
