import { Box, Paper, Typography } from '@mui/material';
import useGlobalStyles from '../../../styles/useGlobalStyles';

export default function StockUnauthorizedKeyTakeaways() {
  const globalClasses = useGlobalStyles();

  return (
    <Paper sx={{ width: '100%', height: '100%', textAlign: 'center' }}>
      <Typography
        sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        className={globalClasses.header}
      >
        Key Takeaways
      </Typography>
      <Box sx={{ width: '100%', height: '150px' }} className="center-items">
        Sign in to see the content
      </Box>
    </Paper>
  );
}
