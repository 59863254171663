import {
  CircularProgress, Box,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  PropsWithChildren, useEffect, useMemo, useState,
} from 'react';
import useUserCultureData from '../../hooks/useUserCultureData';
import DashboardService from '../../services/dashboardService';
import PopperMenu from '../Menu/PopperMenu';
import StocksSearchMenuItem from './components/StocksSearchMenuItem';

interface StocksSearchProps{
  inputValue: string,
  onClickMenuItem: any,
  searchMenuIsOpen: boolean,
  setSearchMenuIsOpen: any,
  searchWidth?: string | number,
}

export default function StocksSearch({
  inputValue, onClickMenuItem, children, searchMenuIsOpen,
  setSearchMenuIsOpen, searchWidth = 'min(300px, 85vw)',
} : PropsWithChildren<StocksSearchProps>) {
  const [value, setValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: userIpData } = useUserCultureData();
  const { data: foundedElems, isLoading: isLoadingFoundedElems } = useQuery(
    ['getFoundedCompanies', value],
    () => DashboardService.getFoundedCompanies(value, userIpData?.country ?? 'US'),
    {
      retry: false,
    },
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setValue(() => inputValue);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  function handleClickSearchPaper(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
    setSearchMenuIsOpen(true);
  }

  function handleClickMenuItem(companySymbol: string) {
    if (companySymbol === '') return;
    onClickMenuItem(companySymbol);
  }

  const searchMenuItems = useMemo(() => {
    if (isLoadingFoundedElems || value !== inputValue) {
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0 10px 0',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    if (foundedElems) {
      return foundedElems.map((foundedElem: any) => (
        <StocksSearchMenuItem
          name={foundedElem.name}
          symbol={foundedElem.symbol}
          handleClickMenuItem={(symbol) => handleClickMenuItem(symbol)}
        />
      ));
    }
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0 10px 0',
        }}
      >
        {`No result for "${inputValue}"`}
      </Box>
    );
  }, [isLoadingFoundedElems, foundedElems, inputValue]);

  return (
    <>
      <Box
        sx={{ width: searchWidth }}
        onClick={(event) => handleClickSearchPaper(event)}
      >
        {children}
      </Box>
      <PopperMenu
        anchorEl={anchorEl}
        open={searchMenuIsOpen}
        onClose={() => setSearchMenuIsOpen(false)}
        sx={{ width: searchWidth }}
      >
        {searchMenuItems}
      </PopperMenu>
    </>
  );
}
