import {
  Box, Paper, Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../../components/Loader';
import SingleNews from '../../../components/SingleNews/SingleNews';
import Swiper from '../../../components/Swiper/Swiper';
import CompanyNewsDto from '../../../models/dtos/comapnyNewsDto';
import StockNewsDto from '../../../models/dtos/stockNews';
import NewsService from '../../../services/newsService';
import useGlobalStyles from '../../../styles/useGlobalStyles';
import './StockNews.css';

export default function StockNews() {
  const { data: stockNews } = useQuery(['getStockNews'], NewsService.getStockNews);
  const globalClasses = useGlobalStyles();

  return (
    <Paper sx={{ position: 'relative' }} className="center-items" elevation={0}>
      <Typography
        variant="h1"
        sx={{
          width: '100%', padding: '10px 0 10px 0', fontSize: '1.5rem', fontWeight: '400',
        }}
        className={globalClasses.header}
      >
        Stock News
      </Typography>
      <Swiper
        swiperHeight="360px"
        horizontalScrollDistanceOnClick={420}
      >
        {stockNews ? stockNews.map((news: StockNewsDto) => (
          <Box className="stock-news-carousel-cell">
            <SingleNews
              newsData={news as CompanyNewsDto}
            />
          </Box>
        ))
          : <Box sx={{ width: '100%' }} className="center-items"><Loader /></Box>}
      </Swiper>
    </Paper>
  );
}
