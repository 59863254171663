import { makeStyles } from '@mui/styles';

const useDrawerStyles = makeStyles((theme: any) => ({
  drawerPaper: {
    backgroundColor: '#FFFFFF',
    position: 'static',
  },
}));

export default useDrawerStyles;
