import { useIsAuthenticated } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import StockService from '../services/stockService';

export default function useFavouriteStocks() {
  const isAuthenticated = useIsAuthenticated();
  return useQuery(['getFavouriteStocks'], () => StockService.getFavouriteStocks(), {
    enabled: isAuthenticated,
    placeholderData: [],
  });
}
