import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';
import { useCallback, useEffect, useState } from 'react';
import Loader from '../../../../../../components/Loader';
import ChartServise from '../../../../../../services/chartsService';
import useCompanyPriceChartData from '../../../hooks/useCompanyPriceChartData';
import BasicPriceChartToolbar from './components/BasicPriceChartToolbar';

interface BasicChartPropModel{
    symbol: string,
    full: boolean
}

export default function BasicPriceChart(props: BasicChartPropModel) {
  const [zoomStart, setZoomStart] = useState<number>(0);
  const [zoomEnd, setZoomEnd] = useState<number>(100);
  const [isMounted, setIsMounted] = useState<boolean>();
  const [chartInstance, setChartInstance] = useState<any>();
  const { symbol } = props;
  const [interval, setInterval] = useState<string>('1day');
  const [chartType, setChartType] = useState<string>('candlestick');
  const { data: chartDataQuery } = useCompanyPriceChartData(symbol, interval, props.full);
  const [option, setOption] = useState<any>({});
  const [timeRange, setTimeRange] = useState<string>('1year');
  const theme: Theme = useTheme();

  useEffect(() => {
    setIsMounted(() => true);
    return (
      setIsMounted(() => false)
    );
  }, []);

  useEffect(() => {
    if (timeRange && chartDataQuery) {
      const {
        newStart, newEnd,
      } = ChartServise.calculateStartEndZoom(
        chartDataQuery,
        timeRange,
        setZoomStart,
        setZoomEnd,
      );
      const newOption = ChartServise.getCandleChartOption(theme, chartDataQuery, newStart, newEnd, '$', chartType, symbol);
      setOption(() => newOption);
    }
  }, [timeRange]);

  useEffect(() => {
    if (chartDataQuery) {
      const {
        newStart, newEnd,
      } = ChartServise.calculateStartEndZoom(
        chartDataQuery,
        timeRange,
        setZoomStart,
        setZoomEnd,
      );
      const newOption = ChartServise.getCandleChartOption(theme, chartDataQuery, newStart, newEnd, '$', chartType, symbol);
      setOption(() => newOption);
    }
  }, [chartDataQuery, isMounted]);

  useEffect(() => {
    if (chartDataQuery && theme && chartInstance) {
      chartInstance.resize();
      const newOption = ChartServise.getCandleChartOption(theme, chartDataQuery, zoomStart, zoomEnd, '$', chartType, symbol);
      setOption(() => newOption);
    }
  }, [chartType, theme]);

  const onDataZoom = useCallback((params: any) => {
    if (params.start && params.end) {
      setZoomStart(params.start);
      setZoomEnd(params.end);
    } else {
      setZoomStart(params.batch[0].start);
      setZoomEnd(params.batch[0].end);
    }
  }, []);

  const onEvent = {
    dataZoom: onDataZoom,
  };

  return (
    <Box style={{ height: '100%' }}>
      <BasicPriceChartToolbar
        interval={interval}
        setInterval={setInterval}
        chartType={chartType}
        setChartType={setChartType}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
      {chartDataQuery
        ? (
          <ReactECharts

            notMerge
            onChartReady={(instance: any) => {
              setChartInstance(instance);
            }}
            option={option}
            style={{ flexGrow: '1', height: '60%' }}
            onEvents={onEvent}
          />
        )
        : <Box sx={{ flexGrow: '1', height: '60%' }} className="center-items"><Loader /></Box>}
    </Box>
  );
}
