import { Button } from '@mui/material';

interface SnackbarUndoButtonPropsModel {
  undoClick: () => void;
}

export default function SnackbarUndoButton({ undoClick }: SnackbarUndoButtonPropsModel) {
  return (
    <Button onClick={undoClick} size="small" variant="contained" sx={{ margin: '5px 5px 5px 5px' }}>
      Undo
    </Button>
  );
}
