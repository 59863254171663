import React, {
  createContext, useState, useContext, useMemo,
} from 'react';

type LoaderContextType = {
  isLoaderOpened: boolean;
  setIsLoaderOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

type LoaderContextProviderProps = {
  children: React.ReactNode;
};

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

function LoaderContextProvider({ children }: LoaderContextProviderProps) {
  const [isLoaderOpened, setIsLoaderOpened] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      isLoaderOpened,
      setIsLoaderOpened,
    }),
    [isLoaderOpened],
  );

  return (
    <LoaderContext.Provider value={value}>
      { children }
    </LoaderContext.Provider>
  );
}

const useLoaderContext = () => {
  const context = useContext(LoaderContext);
  if (context === undefined) {
    throw new Error('useLoaderContext must be used within a LoaderContextProvider');
  }
  return context;
};

export { LoaderContextProvider, useLoaderContext };
