import { useQuery, useQueryClient } from '@tanstack/react-query';
import CompanyDetailedDataService from '../../../../services/companyDetailedDataService';
import CompanyKeyMetricsModel from '../models/CompanyKeyMetricsModel';

export default function useCompanyKeyMetrics(symbol: string | undefined) {
  const QueryClient = useQueryClient();
  if (!symbol) return { data: [{}] as CompanyKeyMetricsModel[], isLoading: true };
  return useQuery(['getCompanyKeyMetrics', symbol], () => CompanyDetailedDataService.getCompanyKeyMetrics(symbol), {
    onSuccess: (data: CompanyKeyMetricsModel[]) => {
      QueryClient.setQueryData(['getCompanyKeyMetrics', symbol], data.map((row: CompanyKeyMetricsModel) => ({
        ...row,
        date: row.date.slice(0, 4),
      })));
    },
  });
}
