import { makeStyles } from '@mui/styles';

const useToolbarStyles = makeStyles((theme: any) => ({
  icon: {
    color: '#707070',
  },
  text: {
    color: theme.palette.common.black,
  },
}));

export default useToolbarStyles;
