import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import useCompanyProfile from '../../../../../../../hooks/useCompanyProfile';
import BasicTable from '../../../../../components/BasicTable/BasicTable';
import useCompanyOverview from '../../../../hooks/useCompanyOverview';

interface GeneralTablePropsType {
  stockSymbol: string | undefined,
  paperHeight: string
}

export default function GeneralTable({ stockSymbol, paperHeight }: GeneralTablePropsType) {
  const {
    data: companyProfile,
    isLoading: isLoadingCompanyProfile,
  } = useCompanyProfile(stockSymbol as string);

  const {
    data: companyOverview,
    isLoading: isLoadingCompanyOverview,
  } = useCompanyOverview(stockSymbol as string);

  function getWebsiteName(address: string | undefined){
    if (address === undefined || address === null)
      return "";

    const url = new URL(address);
    let hostName = url.hostname;
    return hostName.replace('https://', '').replace('www.', '');
  }

  return (
    <Paper sx={{ width: '100%',  height: paperHeight }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ padding: '15px 50px 5px 15px', width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '700' }}>
            ABOUT
          </Typography>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Box sx={{ width: '100%' }}>
            <BasicTable
              data={{
                Symbol: stockSymbol,
                Name: companyProfile?.companyName,
                Industry: companyProfile?.industry,
                Sector: companyProfile?.sector,
                Price: companyProfile?.price,
                'Market Cap': companyProfile?.mktCap,
                'Enterprise value': companyOverview?.enterpriseValue,
                Currency: companyProfile?.currency,
              }}
              isLoading={isLoadingCompanyProfile || isLoadingCompanyOverview}
            />
          </Box>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Box sx={{ width: '100%' }}>
            <BasicTable
              data={{
                Ceo: companyProfile?.ceo,
                'Full Time Employees': companyProfile?.fullTimeEmployees,
                Website: <a target='_blank' style={{ color: '#2196F3', textDecoration: 'none' }} href={companyProfile?.website}>{getWebsiteName(companyProfile?.website)}</a>,
                'Ipo Date': companyProfile?.ipoDate,
                City: companyProfile?.city,
                Address: companyProfile?.address,
              }}
              isLoading={isLoadingCompanyProfile || isLoadingCompanyOverview}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
