import { Paper, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import CartesianChart from '../../../../../components/charts/CartesianChart';
import CompanyDividendDto from '../../../../../models/dtos/companyDividendDto';

interface DividendsChartProps{
  dividendsDataQuery: CompanyDividendDto[],
  isLoadingDividendsData: boolean,
}

export default function DividendsChart({
  dividendsDataQuery, isLoadingDividendsData,
}: DividendsChartProps) {
  const [dataToGraph, setDataToGraph] = useState([]);

  const theme = useTheme();

  const parseDate = (value: any) => new Date(value);

  useEffect(() => {
    if (dividendsDataQuery) {
      const newDataGraph: any = [];
      const dateThreshold = new Date('1900-01-01');
      dividendsDataQuery.forEach((row: CompanyDividendDto) => {
        newDataGraph.push({
          dividend: row.dividend,
          date: parseDate(row.paymentDate !== null &&(parseDate(row.paymentDate) > dateThreshold)
            ? row.paymentDate
            : row.date).toLocaleDateString(),
        });
      });
      setDataToGraph(() => newDataGraph);
    }
  }, [dividendsDataQuery, isLoadingDividendsData]);
  return (
    <Paper sx={{ width: '90%', height: '400px' }}>
      <CartesianChart
        data={dataToGraph}
        isLoading={isLoadingDividendsData}
        series={[{
          type: 'line',
          encode: {
            x: 'date',
            y: 'dividend',
          },
          lineStyle: { color: theme.palette.primary.light },
          itemStyle: { color: theme.palette.primary.light },
        }]}
        inverse
        yValueSufix=""
      />
    </Paper>
  );
}
