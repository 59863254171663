import { makeStyles } from '@mui/styles';

const useKeyTakeawaysStyles = makeStyles((theme: any) => ({
  keyTakeawaysTooltip: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    maxWidth: '200px',
  },
}));

export default useKeyTakeawaysStyles;
