import { makeStyles } from '@mui/styles';

const useGlobalStyles = makeStyles((theme: any) => ({
  header: {
    color: theme.palette.common.black,
    textAlign: 'left',
  },
}));

export default useGlobalStyles;
