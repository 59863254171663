import {
  Dashboard, SupportAgent, Timeline, ManageSearch, Newspaper, Article, PersonOutline,
  VisibilityOutlined,
  Search,
  Share,
  DeleteOutline,
  Insights,
  Edit,
  MailOutline,
  Drafts,
  DraftsOutlined,
  StartOutlined,
  WarningAmberOutlined,
  FormatAlignLeft,
  PhoneIphoneOutlined,
  StarSharp,
} from '@mui/icons-material';
import ROUTES from '../../constants/routes';
import { DrawerItem } from '../DrawerItem';

const DRAWER_LIST_UP: DrawerItem[] = [
  {
    route: ROUTES.dashboard,
    literal: 'Dashboard',
    Icon: Dashboard,
    submenu: [],
  },
  {
    route: 'screener',
    routePrefix: 'stockScreener',
    literal: 'Stock Screener',
    // Icon: ManageSearch,
    Icon: Search,
    submenu: [
      // {
      //   route: ROUTES.stockScreener.quick,
      //   literal: 'Quick',
      // },
      {
        route: ROUTES.stockScreener.dividend,
        Icon: ManageSearch,
        literal: 'Screener',
      },
      {
        route: ROUTES.stockScreener.shared,
        Icon: Share,
        literal: 'Shared',
      },
      // {
      //   route: ROUTES.stockScreener.favourite,
      //   literal: 'Favourite',
      // },
      {
        route: ROUTES.stockScreener.ignored,
        Icon: DeleteOutline,
        literal: 'Ignored',
      },
    ],
  },
  {
    route: 'analysis',
    routePrefix: 'portfolio',
    literal: 'Portfolio',
    Icon: PersonOutline,
    submenu: [
      {
        route: ROUTES.portfolio.analysis,
        Icon: Insights,
        literal: 'Analysis',
      },
      // {
      //   route: ROUTES.portfolio.transactions,
      //   literal: 'Transactions',
      // },
      {
        route: ROUTES.portfolio.management,
        Icon: Edit,
        literal: 'Management',
      },
    ],
  },
  {
    route: ROUTES.watchlist,
    literal: 'Watchlist',
    Icon: VisibilityOutlined,
    submenu: [],
  },
  {
    route: 'contactUS',
    routePrefix: 'support',
    literal: 'Support',
    Icon: MailOutline,
    submenu: [
      {
        route: ROUTES.support.contactUS,
        Icon: DraftsOutlined,
        literal: 'Contact us',
      },
      {
        route: ROUTES.support.featureRequest,
        Icon: StarSharp,
        literal: 'Feature Request',
      },
      {
        route: ROUTES.support.bugReport,
        Icon: WarningAmberOutlined,
        literal: 'Bug Report',
      },
    ],
  },
  {
    route: ROUTES.blog,
    literal: 'Blog',
    Icon: FormatAlignLeft,
    submenu: [],
  },
  {
    route: ROUTES.news,
    literal: 'News',
    Icon: PhoneIphoneOutlined,
    submenu: [],
  }
];

const DRAWER_LIST_DOWN: DrawerItem[] = [];

const DRAWER_LIST = {
  UP: DRAWER_LIST_UP,
  DOWN: DRAWER_LIST_DOWN,
};

export default DRAWER_LIST;
