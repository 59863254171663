import { Box } from '@mui/material';
import './Loader.css';

export default function Loader() {
  return (
    <Box className="line-chart-loader">
      <Box className="line-chart-loader-strap" sx={{ bgcolor: 'text.primary' }} />
      <Box className="line-chart-loader-strap" sx={{ bgcolor: 'text.primary' }} />
      <Box className="line-chart-loader-strap" sx={{ bgcolor: 'text.primary' }} />
      <Box className="line-chart-loader-strap" sx={{ bgcolor: 'text.primary' }} />
    </Box>
  );
}
