import {
  Box,
  Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import useWatchlistNames from '../../../../hooks/useWatchlistNames';
import { useEffect, useState } from 'react';
import WatchlistService from '../../../../services/watchlistService';
import useStockWatchlists from '../../../../hooks/useStockWatchlists';
import Loader from '../../../Loader';

interface AddToWatchlistDialogProps {
  symbol: string,
  isOpen: boolean,
  closePopup: () => void
}

export default function AddToWatchlistDialog({ isOpen, closePopup, symbol }: AddToWatchlistDialogProps) {
  const queryClient = useQueryClient();
  const [selectedWatchlist, setSelectedWatchlist] = useState<string>('');

  const { data: watchlists, isFetching } = useWatchlistNames();
  const { data: stockWatchlists, isFetching: isStockWatchlistsFetching } = useStockWatchlists(symbol);

  const postNewWatchlistStock = useMutation((data: Object) => (
    WatchlistService.postStock(data)), {
    onMutate: ((variables: any) => {
      const updatedRow = { ...variables, isNew: false };
      return { updatedRow };
    }),
    onError: (() => {
      enqueueSnackbar('Entered data is incorrect', { variant: 'error' });
    }),
    onSuccess: ((data: { data: any; }, variables: any, context: any) => {
      queryClient.refetchQueries(['getWatchlistStocks']);
      queryClient.refetchQueries(['getWatchlistNames']);
      enqueueSnackbar('New item has been saved succesfully');
    }),
  });

  const handleChange = (event: SelectChangeEvent<typeof selectedWatchlist>) => {
    const {
      target: { value },
    } = event;

    setSelectedWatchlist(value);
  };

  interface FormValues {
    watchlistNames: string[];
    symbol: string;
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      watchlistNames: [],
      symbol
    },
    onSubmit: (values) => {
      postNewWatchlistStock.mutate(values);
      closePopup();
    },
  });

  useEffect(() => {
    if (stockWatchlists) {
      const watchlistNames = stockWatchlists.map((s: { name: any; }) => s.name);
      formik.setValues({ ...formik.values, watchlistNames });
    }
  }, [stockWatchlists])

  return (
    <Dialog
      open={isOpen}
      onClose={closePopup}
      PaperProps={{
        onSubmit: (event: any) => {
          event.preventDefault();
          closePopup();
        },
      }}
    >
      <DialogTitle>Add to watchlist</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter details to add stock to your watchlist
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
            {isStockWatchlistsFetching || !stockWatchlists ?
              (<Box sx={{ width: '100%', height: '150px' }} className="center-items">
                <Loader />
              </Box>)

              : (<Box>
                <FormLabel>Watchlist</FormLabel>
                <Select
                  sx={{ width: '100%' }}
                  input={<OutlinedInput label="Select portolio" />}
                  id="watchlistNames"
                  name="watchlistNames"
                  type="string"
                  variant='standard'
                  multiple
                  value={formik.values.watchlistNames ?? []}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )} onChange={formik.handleChange}
                  error={formik.touched.watchlistNames && Boolean(formik.errors.watchlistNames)}
                >
                  {watchlists?.map((watchlist: any) => (
                    <MenuItem key={watchlist.id} value={watchlist.name}>
                      <Checkbox checked={formik.values.watchlistNames.indexOf(watchlist.name) > -1} />
                      <ListItemText primary={watchlist.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>)}

          </FormControl>
          <DialogActions>
            <Button onClick={closePopup}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
