import axios from 'axios';
import MostGainerStockCompany from '../models/dtos/mostGainerStockCompany';
import MostLoserStockCompany from '../models/dtos/mostLoserStockCompany';
import PriceTargetRSSFeed from '../models/dtos/priceTargetRSSFeed';
import StockNews from '../models/dtos/stockNews';

const getMostGainerStockCompanies = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/StockDynamicPriceChanges/gainers`;
  const response = await axios.get<MostGainerStockCompany[]>(URL);
  return response.data;
};

const getMostLoserStockCompanies = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/StockDynamicPriceChanges/losers`;
  const response = await axios.get<MostLoserStockCompany[]>(URL);
  return response.data;
};

const getMostActiveStockCompanies = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/StockDynamicPriceChanges/actives`;
  const response = await axios.get<MostLoserStockCompany[]>(URL);
  return response.data;
};

const getPriceTargetRSSFeed = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/StockPriceTarget`;
  const response = await axios.get<PriceTargetRSSFeed[]>(URL);
  return response.data.slice(0, 20);
};

const getStockNews = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/StockNews/Latest?limit=20`;
  const response = await axios.get<StockNews[]>(URL);
  return response.data.slice(0, 20);
};

const NewsService = {
  getMostGainerStockCompanies,
  getMostLoserStockCompanies,
  getMostActiveStockCompanies,
  getPriceTargetRSSFeed,
  getStockNews,
};
export default NewsService;
