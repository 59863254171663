import {
  createContext, useState, useContext, useMemo,
} from 'react';

  type AdsContextType = {
    adsAreVisible: boolean;
    toggleAdsAreVisible: () => void;
  };

  type AdsContextProviderProps = {
    children: React.ReactNode;
  };

const AdsContext = createContext<AdsContextType | undefined>(undefined);

function AdsContextProvider({ children }: AdsContextProviderProps) {
  const [adsAreVisible, setAdsAreVisible] = useState(false);

  function toggleAdsAreVisible() {
    setAdsAreVisible((oldValue) => !oldValue);
  }

  const value = useMemo(() => ({
    adsAreVisible,
    toggleAdsAreVisible,
  }), [adsAreVisible]);

  return (
    <AdsContext.Provider value={value}>
      {children}
    </AdsContext.Provider>
  );
}

const useAdsContext = () => {
  const context = useContext(AdsContext);
  if (context === undefined) {
    throw new Error('useAdsContext must be used within a AdsContextProvider');
  }
  return context;
};

export { AdsContextProvider, useAdsContext };
