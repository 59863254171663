import { Typography, Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  createRef, useCallback, useEffect, useState,
} from 'react';
import ReactECharts from 'echarts-for-react';
import ChartServise from '../../services/chartsService';
import Loader from '../Loader';

interface MainLinearChartPropsModel{
    data: any,
    title: string,
    step: string | boolean,
    isLoading: boolean,
    showTitle: boolean,
}

export default function MainLinearChart(props: MainLinearChartPropsModel) {
  const {
    data, title, step, isLoading, showTitle,
  } = props;
  const theme: Theme = useTheme();
  const [option, setOption] = useState<any>();
  const [zoomStart, setZoomStart] = useState<number>(0);
  const [zoomEnd, setZoomEnd] = useState<number>(100);
  const chartRef: any = createRef();

  const onDataZoom = useCallback((params: any) => {
    if (params.start && params.end) {
      setZoomStart(params.start);
      setZoomEnd(params.end);
    } else {
      setZoomStart(params.batch[0].start);
      setZoomEnd(params.batch[0].end);
    }
  }, []);

  const onEvents = {
    dataZoom: onDataZoom,
  };

  useEffect(() => {
    if (data) {
      const seriesList: any = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const group of data) {
        if (group.id === 'SUMMED') {
          continue;
        }
        seriesList.push({
          showSymbol: false,
          name: group.id,
          type: 'line',
          step,
          data: group.data.map((row: any) => ([row.x, row.y.toFixed(3)])),
          markPoint: {
            symbol: 'none',
          },
        });
      }
      const customOption = {
        title: {
          show: showTitle,
          text: title,
          left: 'center',
        },
        legend: {
          orient: 'vertical',
          right: 'right',
          top: '50px',
        },
        xAxis: {
          type: 'time',
        },
        series: seriesList,
        dataZoom: [{
          type: 'inside',
          start: zoomStart,
          end: zoomEnd,
        },
        {
          type: 'slider',
          start: zoomStart,
          end: zoomEnd,
        },
        ],
      };
      const newOption = ChartServise.getLineChartOption(theme, customOption);
      setOption(() => (newOption));
    }
  }, [data, theme, isLoading]);

  return (
    <Box sx={{ height: '100%', width: '100%' }} className="center-items">
      { option && data?.length === 0 && !isLoading && <Typography> No data to display </Typography>}
      {(!option || isLoading)
            && <Loader />}
      {option && !isLoading && data.length !== 0
                && (
                <ReactECharts
                  onEvents={onEvents}
                  notMerge
                  ref={chartRef}
                  option={option}
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                />
                )}
    </Box>
  );
}
