import {
  Box, Button, FormControl, TextField, Typography, Link,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import SupportFormWrapper from '../../../components/SupportForm';
import SupportService from '../../../services/supportService';
import useGlobalStyles from '../../../styles/useGlobalStyles';

export default function ContactUsNotLoggedUsers() {
  const postFeature = useMutation((feature: any) => SupportService
    .postContactNoLoggedUser(feature));

  const { enqueueSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();

  const formik = useFormik({
    initialValues: {
      message: '',
      email: '',
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .min(20, 'Must be at least 20 characters')
        .max(500, 'Must be 500 characters or less')
        .required('Required'),
      email: Yup.string()
        .email('Must be a valid email')
        .required('Required'),
    }),
    onSubmit: (values) => {
      enqueueSnackbar('Message has been sent. Thank you for you effort to improve the STI platform');
      postFeature.mutate(JSON.stringify(values, null, 2));
    },
  });

  return (
    <Box sx={{ minHeight: '100%', width: '100%' }}>
      <Helmet>
        <title>Contact Us | Get In Touch with StockTradeIQ Support</title>
      </Helmet>
      <Box sx={{ height: '100%', width: '100%' }} className="center-items">
        <Box className="support-form-container">
          <SupportFormWrapper
            header="Contact us"
            subheader="If you have any questions, concerns, or feedback about our product, please don't hesitate to contact us using the form below and we'll get back to you as soon as possible! "
          >
            <Box sx={{ width: '100%' }} className={globalClasses.header}>
              <Typography variant="h4" sx={{ padding: '10px 0px 10px 0px' }} />
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <FormControl sx={{ width: '90%' }}>
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  multiline
                  margin="normal"
                  fullWidth
                  minRows={5}
                  maxRows={8}
                  variant="filled"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  multiline
                  margin="normal"
                  fullWidth
                  minRows={1}
                  maxRows={2}
                  variant="filled"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '80px' }}>
                <Typography variant="caption" sx={{ lineHeight: '14px', display: 'block', width: '90%' }}>
                  The administrator of personal data is STOCKTRADEIQ sp. z o.o. ( Czarna Rola 30 Street, 61-625 Poznan). Personal data provided in the form are processed for the purposes of responding to the contact. Each user whose personal data is processed by the Administrator has certain rights, i.e. access, rectification, deletion, restriction, transfer, objection, filing a complaint against processing to the appropriate authority. More information on the processing of personal data is contained in the Privacy Policy
                  {' '}
                  <Link href="https://www.stocktradeiq.com/documents/PrivacyPolicy">[link]</Link>
                  .
                </Typography>
              </Box>
              <Box sx={{ position: 'absolute', bottom: '20px', width: '100%' }} className="center-items">
                <Button variant="contained" sx={{ margin: '5px 5px 5px 5px' }} size="large" type="submit">
                  Send
                </Button>
              </Box>
            </form>
          </SupportFormWrapper>
        </Box>
      </Box>
    </Box>
  );
}
