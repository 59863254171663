import { Theme, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import BlogOverviewDto from '../../../models/dtos/blogOverviewDto';
import './BlogOverview.css';
import ROUTES from '../../../constants/routes';

interface BlogOverviewProps{
  data: BlogOverviewDto,
}

export default function BlogOverview({ data }: BlogOverviewProps) {
  const theme: Theme = useTheme();

  return (
    <Link component={RouterLink} underline="none" to={(`${ROUTES.blog}/${data.urlName}` as string)}>
      <article className="post-wrapper">
        <div className="post-text" style={{ color: theme.palette.text.primary }}>
          <h1>{data.title}</h1>
          <p className="author" style={{ color: theme.palette.text.secondary }}>{data.author}</p>
          <div className="post-content" style={{ color: theme.palette.text.secondary }}>
            <p>{data.overview}</p>
          </div>
        </div>
        <div className="post-image">
          <img src={data.overviewImageUrl} alt={data.title} />
        </div>
      </article>
    </Link>
  );
}
