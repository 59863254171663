import { Box } from '@mui/material';
import PredictionsCarousel from './components/PredictionsCarousel';
import MostGainerStocks from './components/MostGainerStocks';
import MostLoserStocks from './components/MostLoserStocks';
import StockNews from './components/StockNews';
import './news.css';
import MostActiveStocks from './components/MostActiveStocks';
import NewsAds from './components/NewsAds';
import { Helmet } from 'react-helmet';

export default function News() {
  return (
    <Box sx={{
      padding: '20px 20px 20px 20px', width: '100%', gap: '20px', display: 'flex', flexDirection: 'column',
    }}
    >
      <Helmet>
        <title>Stock Market Insights | News, Predictions & Top Movers | StockTradeIQ</title>
      </Helmet>
      <StockNews />
      <PredictionsCarousel />
      <MostActiveStocks />
      <NewsAds />
      <MostGainerStocks />
      <MostLoserStocks />
    </Box>
  );
}
