import { LinearProgress, Paper } from '@mui/material';
import {
  DataGridPro, GridColDef, GridRenderCellParams, GridRowModel,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import StockQuickViewCellButton from '../../../../components/StockQuickViewCellButton';
import usePortfolioValueByCompanyByPortfolioIds from '../../../../hooks/usePortfoliosValueByCompany';
import CustomCell from '../../../../components/analyticsGrid/CustomCell';
import usePortfolioValueByCompanyByName from '../../../../hooks/usePortfolioValueByCompanyByName';

interface OverviewGridProps {
  selectedPortfolioIds?: number[];
  selectedPortfolioName?: string;
}

export default function OverviewGrid({ selectedPortfolioIds, selectedPortfolioName }: OverviewGridProps) {
  let transactionsDataQuery: any;
  let isLoadingTransactionsData: any;
  
  if (selectedPortfolioIds){
    const {
      data: internalTransactionsDataQuery,
      isLoading: internalIsLoadingTransactionsData,
  } = usePortfolioValueByCompanyByPortfolioIds(selectedPortfolioIds!);

    transactionsDataQuery = internalTransactionsDataQuery;
    isLoadingTransactionsData = internalIsLoadingTransactionsData;
  } else if (selectedPortfolioName){
    const {
      data: internalTransactionsDataQuery,
      isLoading: internalIsLoadingTransactionsData,
    } = usePortfolioValueByCompanyByName(selectedPortfolioName!);

    transactionsDataQuery = internalTransactionsDataQuery;
    isLoadingTransactionsData = internalIsLoadingTransactionsData;
  }
  const [rows, setRows] = useState<GridRowModel[]>([]);

  useEffect(() => {
    if (transactionsDataQuery) {
      setRows(transactionsDataQuery);
    }
  }, [transactionsDataQuery]);

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Name',
      editable: false,
      flex: 1,
      width: 200,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <StockQuickViewCellButton
          name={params.value}
          columnWidth={params.colDef.width}
          symbol={params.row.symbol}
          key={params.row.id}
        />
      ),
    },
    {
      field: 'quantity',
      type: 'number',
      headerName: 'Quantity',
      headerAlign: 'left',
      editable: false,
      flex: 1,
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
    {
      field: 'sector',
      headerName: 'Sector',
      editable: false,
      flex: 1,
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      editable: false,
      flex: 1,
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
  ];

  return (
    <Paper sx={{
      width: '100%', height: '100%',
    }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        autoHeight
        density="standard"
        loading={isLoadingTransactionsData}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        getRowId={(row) => row.symbol}
      />
    </Paper>
  );
}
