import axios from 'axios';
import BlogOverview from '../models/dtos/blogOverviewDto';
import BlogPostDto from '../models/dtos/blogPostDto';

const getBlogArticlesOverviews = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Blog/overviews`;
  const response = (await axios.get<BlogOverview[]>(URL)).data;
  return response;
};

const getBlogPost = async (id: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Blog/${id}`;
  const response = (await axios.get<BlogPostDto>(URL)).data;
  return response;
};

const BlogService = {
  getBlogArticlesOverviews,
  getBlogPost
};
export default BlogService;
