import axios from 'axios';
import CompanyProfileModel from '../models/dtos/companyProfileModel';
import CompanyNewsDto from '../models/dtos/comapnyNewsDto';
import CompanyKeyMetricsModel from '../pages/stockDetailedData/Tabs/models/CompanyKeyMetricsModel';
import CompanyOverviewModel from '../pages/stockDetailedData/Tabs/models/CompanyOverviewModel';
import StockKeyTakeawaysDto from '../models/dtos/stockKeyTakeawaysDto';
import CompanyDividendDto from '../models/dtos/companyDividendDto';

const getCompanyProfile = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Company/profile?symbol=${symbol}`;
  const response = await axios.get<CompanyProfileModel>(URL);
  return response.data;
};

const getCompanyOverview = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Company/overview?symbol=${symbol}`;
  const response = await axios.get<CompanyOverviewModel>(URL);
  const result = {
    ...response.data,
    dividendYieldTTM: response.data.dividendYieldTTM * 100,
    payoutRatioTTM: response.data.payoutRatioTTM * 100,
    returnOnAssetsTTM: response.data.returnOnAssetsTTM * 100,
    roic: response.data.roic * 100,
    grossProfitMarginTTM: response.data.grossProfitMarginTTM * 100,
    netProfitMarginTTM: response.data.netProfitMarginTTM * 100,
  };
  return result;
};

const getHistoricalPriceChartData = async (symbol: string, interval: string, full: boolean) => {
  let URL;
  if (interval === '1day') {
    if (full) {
      URL = `${process.env.REACT_APP_SERVER_URL}/Stock/fullHistoricalPrices?symbol=${symbol}`;
    }
    else {
      URL = `${process.env.REACT_APP_SERVER_URL}/Stock/historicalPrices?symbol=${symbol}`;
    }
  } else {
    URL = `${process.env.REACT_APP_SERVER_URL}/Stock/historicalPricesByInterval?symbol=${symbol}&interval=${interval}`;
  }
  const response = await axios.get(URL);
  if (interval === '1day') {
    return response.data.historical;
  }
  return response.data;
};

const getCompanyKeyMetrics = async (symbol: string) => {
  const URL = `https://financialmodelingprep.com/api/v3/key-metrics/${symbol}?limit=10&apikey=6eb01c7f9b0bb5280c2cab7ed6b43710`;
  const response = await axios.get<CompanyKeyMetricsModel[]>(URL);
  return response.data;
};

const getCompanyNews = async (symbol: string, limit: number) => {
  const URL = `https://financialmodelingprep.com/api/v3/stock_news?tickers=${symbol}&limit=${limit}&apikey=6eb01c7f9b0bb5280c2cab7ed6b43710`;
  const response = await axios.get<CompanyNewsDto[]>(URL);
  return response.data;
};

const getCompanyDividends = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Dividend/${symbol}`;
  const response = await axios.get<CompanyDividendDto[]>(URL);
  return response.data;
};

const getStockKeyTakeaways = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Stock/keyTakeaways?symbol=${symbol}`;
  const response = await axios.get<StockKeyTakeawaysDto>(URL);
  return response.data;
};

const CompanyDetailedDataService = {
  getCompanyProfile,
  getCompanyOverview,
  getHistoricalPriceChartData,
  getCompanyDividends,
  getCompanyKeyMetrics,
  getCompanyNews,
  getStockKeyTakeaways,
};
export default CompanyDetailedDataService;
