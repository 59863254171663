import {
  AppBar, IconButton, Toolbar, Box, Button, Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ChevronLeft, Menu } from '@mui/icons-material';
import { loginRequest } from '../../authConfig';
import { useDrawerContext } from '../../contexts/DrawerContext';
import './Header.css';
import header from '../../constants/header';
import useToolbarStyles from '../styles/useToolbarStyles';
import useHeaderStyles from '../styles/useHeaderStyles';
import AuthorizedUserMenu from './AuthorizedUserMenu';
import DocumentsMenu from './DocumentsMenu';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Header() {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const location = useLocation();
  const { instance } = useMsal();
  const theme: Theme = useTheme();
  const toolbarClasses = useToolbarStyles();
  const headerClasses = useHeaderStyles();

  const [isBasePath, setIsBasePath] = useState(true);

  useEffect(() => {
    // Determine the color based on the current path
    const isBasePath = location.pathname === '/' || location.pathname === '/stocktradeiq.com';
    setIsBasePath(isBasePath);
  }, [location.pathname]); // Re-run this effect whenever the pathname changes

  return (
    <Box
      className="header"
      sx={{ width: '100%', overflow: 'auto', height: header.height, backgroundColor: 'transparent', marginTop: '5px' }}
    >
      <Toolbar variant="dense" classes={{ root: headerClasses.header }}>
        <Box className="navigationBar">
          <AuthenticatedTemplate>
            <div style={{ color: isBasePath ? 'black' : 'black' }}>
              <AuthorizedUserMenu />
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div style={{ display: 'relative' }}>
              <div style={{ float: 'left', paddingTop: '10px', color: isBasePath ? 'black' : 'black' }}>
                <DocumentsMenu />
              </div>
              <div style={{ float: 'left', paddingTop: '12px', height: '100%' }}>
                <Button variant="text" style={{ width: '100%', height: '100%', padding: '12px' }} onClick={() => instance.loginRedirect(loginRequest)}>
                  <Typography style={{ fontWeight: '600', fontSize: '0.874rem', textTransform: 'none', color: isBasePath ? 'black' : 'black' }}>
                    Login
                  </Typography>
                </Button>
              </div>
              <div style={{ float: 'left' }}>
                <Button
                  style={{
                    padding: '12px 50px',
                    margin: '10px',
                    textTransform: 'none',
                    borderRadius: '50px',  // Rounded edges
                    background: 'linear-gradient(90deg, #330867 0%, #30CFD0 100%)',  // Gradient background
                    color: 'white'
                  }}
                  variant="contained"
                  size="medium"
                  onClick={() => instance.loginRedirect(loginRequest)}
                  aria-label="Sign up button"
                >
                  Sign up for free
                </Button>
              </div>
            </div>
          </UnauthenticatedTemplate>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default Header;
