import { CircularProgress, LinearProgress, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, MuiEvent,
} from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import DataGridService from '../../../../services/dataGridService';
import ShareButton from '../../../../components/analyticsGrid/customToolbar/components/ShareButton';

export default function SharedConfigurationsGrid() {
  const queryClient = useQueryClient();

  const { data: sharedConfigurationsDataQuery, isLoading } = useQuery(['getSharedConfigurationsData'], DataGridService.getSharedConfigurations);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });  
  const [rowIsInWaitingMode, setRowIsInWaitingMode] = useState<any>();

  useEffect(() => {
    if (sharedConfigurationsDataQuery) {
      const newRowIsInWaitingMode = Object();
      sharedConfigurationsDataQuery.forEach((element: GridRowModel) => {
        newRowIsInWaitingMode[element.id] = false;
      });
      setRowIsInWaitingMode(newRowIsInWaitingMode);

      setRows(sharedConfigurationsDataQuery.map((row: GridRowModel) => (
        { ...row }
      )));
    }
  }, [isLoading, sharedConfigurationsDataQuery]);

  const deleteScreenShareById = useMutation((params: any) => (
    DataGridService.deleteScreenShareConfiguration(params.row.configurationHash)), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(`"${variables.row.name}" screen share has been deleted succesfully`);
    },
  });

  function updateRows(newRows: GridRowModel[]) {
    queryClient.setQueryData(['getSharedConfigurationsData'], newRows);
    return newRows;
  }

  const updateConfigurationNameMutation = useMutation(
    (data: any) => (
      DataGridService.putScreenShareConfigurationName(data.configurationHash, data.name)),
    {
      onError: (() => {
        enqueueSnackbar('Updating name failed', { variant: 'error' });
      }),
      onSuccess: ((data, variables: any) => {
        enqueueSnackbar('The share name updated succesfully');
      }),
      onSettled: ((data, error, variables: any) => {
        const updatedRows = rows.map((share, i) => {
          if (share.id === variables.id) {
            share.name = variables.name;
          }
          return share;
        });

        setRows(updatedRows);
        updateRows(updatedRows);
        setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [variables.id]: false }));
      }
      ),
    },
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleDeleteClick = (params: any) => () => {
    setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== params.id)));
    deleteScreenShareById.mutate(params);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== id)));
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (params: any) => () => {
    setRowModesModel((oldModel) => ({ ...oldModel, [params.row.id]: { mode: GridRowModes.View } }));
    setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [params.id]: true }));
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    updateConfigurationNameMutation.mutate(newRow);
    return newRow;
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      flex: 0,
      getActions: (params: GridRowParams) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(params.id)}
            />,
          ];
        }

        if (rowIsInWaitingMode[params.id]) {
          return [
            <CircularProgress />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(params.id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params)}
          />,
        ];
      },
    },
    {
      field: 'name', type: 'string', headerName: 'Name', editable: true, flex: 1, align: 'center', headerAlign: 'center'
    },
    {
      field: 'screenerName', type: 'string', headerName: 'Screener type', editable: false, flex: 1, align: 'center', headerAlign: 'center'
    },
    {
      field: 'visitsNumber', type: 'number', headerName: 'Visits number', editable: false, flex: 1, align: 'center', headerAlign: 'center',
    },
    {
      field: 'share',
      headerName: '',
      width: 200,
      flex: 0,
      renderCell: (params: any) => {
        return <ShareButton existingScreenShareKey={params.row.configurationHash}/>
      }
    },
  ];

  return (
    <Paper sx={{
      width: '90%', flexFlow: 'column', flex: '0 1 auto',
    }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        sortModel={[{ field: 'visitsNumber', sort: 'desc' }]}
        density="compact"
        editMode="row"
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 15]}
        autoHeight
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowModesModelChange={(newModel) => (
          setRowModesModel((oldModel) => ({ ...oldModel, ...newModel }))
        )}
        getRowId={(row) => row.id}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={isLoading}
        componentsProps={{
          toolbar: {
            setRows, setRowModesModel, setRowIsInWaitingMode,
          },
        }}
      />
    </Paper>
  );
}
