import { Box, Paper, Typography } from '@mui/material';
import BasicTable from '../../../../../components/BasicTable/BasicTable';
import useCompanyOverview from '../../../../hooks/useCompanyOverview';

interface FundamentalThirdTablePropsType{
  stockSymbol: string | undefined,
  width : string
  paperHeight : string
}

export default function FundamentalThirdTable(
  { stockSymbol, width, paperHeight }: FundamentalThirdTablePropsType,
) {
  const {
    data: companyOverview,
    isLoading: isLoadingCompanyOverview,
  } = useCompanyOverview(stockSymbol as string);

  return (
    <Box sx={{ width, maxWidth: '100%' }}>
      <Typography variant="h6" sx={{ padding: '5px 0px 5px 15px', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700', visibility: 'hidden' }}>
        FUNDAMENTALS
      </Typography>
      <Paper sx={{ width, maxWidth: '100%', height: paperHeight }}>
        <BasicTable
          data={{
            'PEG': companyOverview?.pegRatioTTM,
            'P/CF': companyOverview?.priceCashFlowRatioTTM,
            'P/FCF': companyOverview?.priceToFreeCashFlowsRatioTTM,
            'RoA %': companyOverview?.returnOnAssetsTTM,
            'RoIC %': companyOverview?.roic,
            'Gross Profit Margin %': companyOverview?.grossProfitMarginTTM,
            'Quick Ratio': companyOverview?.quickRatioTTM,
            'Current Ratio': companyOverview?.currentRatioTTM,
            'Net Profit Margin %': companyOverview?.netProfitMarginTTM,
            'Net-Net': companyOverview?.grahamNetNetTTM,
          }}
          isLoading={isLoadingCompanyOverview}
        />
      </Paper>
    </Box>
  );
}
