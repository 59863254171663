import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItemText, Paper, Stack, TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { useMutationsContext } from '../../../../contexts/MutationsContext';

interface Props {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    portfolioName: string,
    portfolioId: number,
}



export default function ImportTransactionsDialog({
    isOpen, setIsOpen, portfolioName, portfolioId
}: Props) {

    const { postTransactionsData } = useMutationsContext();
    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        postTransactionsData.mutate({ file: event.target.files[0], portfolioId: portfolioId});
        event.target.value = '';
        setIsOpen(false);
    };

    return (
        <Dialog
            onClose={() => setIsOpen(false)}
            open={isOpen}
            sx={{ width: '100%', minWidth: '800px' }}
        >
            <DialogTitle
                sx={{ textAlign: 'center' }}
            >
                <Typography variant="h1" sx={{ padding: '10px 0px 10px 0px', fontSize: '38px', fontWeight: '500' }}>
                    {portfolioName} IMPORT
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    width: '100%',
                    minHeight: '50px',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Box sx={{ width: '100%', minHeight: '100%' }}>
                    <Box sx={{ height: '100%', width: '100%' }} className="center-items">
                        <Box sx={{
                            flexGrow: '1', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap',
                        }}
                        >
                            <Grid container sx={{ width: '100%', height: '100%' }} textAlign={"center"}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginTop: '10%', width: '100%', textAlign: 'center', fontWeight: '600' }}>
                                        INSTRUCTION
                                    </Typography>
                                    <List sx={{ height: '50%', textAlign: 'left', margin: '0% 20% 10% 20%' }}>
                                        <ListItemText primary="1. Download our .CSV template">
                                        </ListItemText>
                                        <ListItemText sx={{ marginTop: '3%' }} primary="2. Fill it with your transactions">
                                        </ListItemText>
                                        <ListItemText sx={{ marginTop: '3%' }} primary="3. Click import button">
                                        </ListItemText>
                                    </List>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction={'column'} spacing={4} sx={{ width: '100%', paddingLeft: '50px', paddingRight: '50px', paddingBottom: '40px'}}>
                    <Box sx={{ width: '100%', paddingLeft: '120px', paddingRight: '120px' }}>
                        <a href={require('../../../../assets/importTransactionExampleCsv.csv')} download="importTransactionExampleCsv" style={{ all: 'unset' }}>
                            <Button sx={{ color: 'white', width: '100%', background: '#2196F3', height: '60px', borderRadius: '4px' }} variant='contained' component="label">
                                Download template
                            </Button>
                        </a>
                    </Box>
                    <Stack direction={'row'} sx={{ width: '100%' }} spacing={5}>
                        <Box sx={{ width: '100%' }}>
                            <Button sx={{ color: 'white', width: '100%', background: '#2196F3', height: '60px', borderRadius: '4px' }} variant='contained' onClick={() => setIsOpen(false)} component="label">
                                Close
                            </Button>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Button sx={{ color: 'white', width: '100%', background: '#2196F3', height: '60px', borderRadius: '4px' }} variant='contained' component="label">
                                Import
                                <input type="file" hidden onChange={handleChangeFile} accept=".csv" />
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
