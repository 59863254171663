import { InputBase } from '@mui/material';
import {
  GridRenderEditCellParams, useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useState } from 'react';
import StocksSearch from '../../../../components/StockSearch/StocksSearch';

export default function SymbolEditCell(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState<boolean>(false);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    setSearchMenuIsOpen(() => true);
  };

  function handleClickMenuItem(companySymbol: string) {
    const newValue = companySymbol;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    setSearchMenuIsOpen(() => false);
  }

  return (
    <StocksSearch
      inputValue={value}
      onClickMenuItem={handleClickMenuItem}
      searchMenuIsOpen={searchMenuIsOpen}
      setSearchMenuIsOpen={setSearchMenuIsOpen}
      searchWidth={'max(700px, 10%)'}
    >
        <InputBase sx={{ width: '100%'}} value={value} onChange={handleValueChange} placeholder='Enter a stock symbol'/>
    </StocksSearch>
  );
}
