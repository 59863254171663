import {
  createContext, useState, useContext, useMemo, useEffect,
} from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import useCookiesEnabled from '../hooks/useCookiesEnabled';

type ColorModeContextType = {
  isLightMode: boolean;
  toggleIsLightMode: () => void;
};

type ColorModeContextProviderProps = {
  children: React.ReactNode;
};

const ColorModeContext = createContext<ColorModeContextType | undefined>(undefined);

function ColorModeContextProvider({ children }: ColorModeContextProviderProps) {
  const lightThemeMq = window.matchMedia('(prefers-color-scheme: light)');

  function getBrowserThemeIsLight() {
    return lightThemeMq.matches;
  }

  const [initialIsLightMode, setInitialIsLightMode] = useLocalStorage('isLightMode', getBrowserThemeIsLight());
  const [isLightMode, setIsLightMode] = useState<boolean>(true);//useState<boolean>(initialIsLightMode);
  
  const mqListener = ((e: MediaQueryListEvent) => {
    setIsLightMode(e.matches);
  });

  useEffect(() => {
    if (useCookiesEnabled('functional')){
      setInitialIsLightMode(isLightMode);
    }
  }, [isLightMode]);

  useEffect(() => {
    lightThemeMq.addEventListener('change', mqListener);
    return () => lightThemeMq.removeEventListener('change', mqListener);
  }, []);

  function toggleIsLightMode() {
    setIsLightMode((oldValue) => !oldValue);
  }

  const value = useMemo(() => ({
    isLightMode,
    toggleIsLightMode,
  }), [isLightMode]);

  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
}

const useColorModeContext = () => {
  const context = useContext(ColorModeContext);
  if (context === undefined) {
    throw new Error('useColorModeContext must be used within a ColorModeContextProvider');
  }
  return context;
};

export { ColorModeContextProvider, useColorModeContext };
