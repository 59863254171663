/* eslint-disable no-unused-vars */
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import React from 'react';

interface ChipsNavigatorPropsModel {
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  menuItems: string[]
}

const UnselectedButton = styled(Button)({
  background: 'none',
  color: '#6c757d', // gray color for unselected state
  '&:hover': {
    background: 'none',
    color: '#343a40', // darker gray on hover
  },
});

const SelectedButton = styled(Button)({
  background: 'none',
  color: '#007BFF', // blue color for selected state
  '&:hover': {
    background: 'none',
    color: '#0056b3', // dark blue on hover
  }
});

export default function ChipsNavigator({ value, setValue, menuItems }: ChipsNavigatorPropsModel) {
  return (
    <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft: '50px', marginBottom: '10px', width: '100%' }}>
      {menuItems.map((elem: string) => (
        renderButton(elem, value, setValue)
      ))}
    </Stack>
  );

  function renderButton(elem: string, value: string, setValue: any): JSX.Element {

    const Underline = styled('div')(({ theme }) => ({
      width: '100%',
      height: '2px',
      backgroundColor: '#2196f3', // Custom underline color
      marginTop: '4px',
    }));

    if (elem === value) {
      return <Box>
          <SelectedButton
          key={elem}
          onClick={() => setValue(elem)}
          sx={{ margin: '10px 5px 0px 5px' }}
          variant={value === elem ? 'text' : 'text'}
        >
          <Typography sx={{ alignSelf: 'center', fontSize: '1rem', fontWeight: '400' }} variant="h2">
            {elem}
          </Typography>
        </SelectedButton>
        <Underline/>
        </Box>;
    }

    return <UnselectedButton
      key={elem}
      onClick={() => setValue(elem)}
      sx={{ margin: '10px 5px 6px 5px' }}
      variant={value === elem ? 'text' : 'text'}
    >
      <Typography sx={{ alignSelf: 'center', fontSize: '1rem', fontWeight: '400' }} variant="h2">
        {elem}
      </Typography>
    </UnselectedButton>;

  }
}
