import {
  Paper, Typography, Box, Button,
} from '@mui/material';
import { useMsal } from '@azure/msal-react';
import useGlobalStyles from '../../../styles/useGlobalStyles';
import { loginRequest } from '../../../authConfig';

export default function NotLoggedUserDialog() {
  const globalClasses = useGlobalStyles();
  const { instance } = useMsal();

  return (
    <Paper
      sx={{
        width: '40%',
        height: '500px',
        minWidth: 'min(90% ,450px)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          height: 'fit-content',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        className={globalClasses.header}
      >
        <Typography sx={{ minHeight: '50px', fontSize: '2rem', fontWeight: '400' }} className="center-items" variant="h1">
          Explore Stocks with StockTradeIQ
        </Typography>
        <Typography sx={{ minHeight: '40px', fontSize: '1rem', fontWeight: '200' }} className="center-items" variant="h2">
          Stock Screener & Portfolio Management for Smart Investing
        </Typography>
      </Box>
      <Box className="center-items">
        <Button
          variant="contained"
          sx={{ margin: '25px', marginTop: '150px' }}
          size="large"
          type="button"
          onClick={() => instance.loginRedirect(loginRequest)}
        >
          <Typography
            sx={{
              height: '80%', textAlign: 'center', fontSize: '1rem', fontWeight: '200', textTransform: 'none',
            }}
            className="center-items"
            variant="h2"
          >
            Sign In for Full Access to Our Comprehensive Stock Market Analysis Tools
          </Typography>
        </Button>
      </Box>
    </Paper>
  );
}
