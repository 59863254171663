import {
  Box, Button, Dialog, Grid, Paper, Stack, Switch, Typography, styled,
} from '@mui/material';
import React from 'react';
import useGlobalStyles from '../../styles/useGlobalStyles';
import FreeResourcesExceededDialogSubscription from './FreeResourceExceededDialogSubscription';
import { useAccount, useMsal } from '@azure/msal-react';

interface FreeResourcesExceededDialogPropsModel {
  isDialogOpened: boolean,
  setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>,
  dialogText: string
}

export default function FreeResourcesExceededDialog({
  isDialogOpened,
  setIsDialogOpened, dialogText,
}: FreeResourcesExceededDialogPropsModel) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isAnnual, setIsAnnual] = React.useState(false);
  const paidSubscriptionResources = [
    'Price alerts (5 in a month)',
    'Fundamental alerts (5 in a month)',
    '100 Portfolios (500 tickers)',
    '3 Watchlists (25 tickers)',
  ];

  const premiumSubscriptionResources = [
    'Price alerts (30 in a month)',
    'Fundamental alerts (30 in a month)',
    '200 Portfolios (800 tickers)',
    'Unlimited watchlists',
    'Unlimited STI score',
  ];

  function openCheckout(path: string): void {
    (window as any).fastspring.builder.push({
      products: [
        {
          path,
        },
      ],
      paymentContact: {
        email: account?.idTokenClaims?.email,
      },
      checkout: true,
    });
  }

  const handleIsAnnualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnnual(event.target.checked);
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '90%',
  }));

  function CornerSVG() {
    return (
      <svg viewBox="0 0 20 20" width="50" height="50">
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#990000" />
            <stop offset="80%" stopColor="#D70000" />
            <stop offset="99%" stopColor="#B50000" />
          </linearGradient>
        </defs>
        <path
          d="M0 0 L20 0 L20 20 Z"
          fill="transparent"
        />
        <path d="M0 0 L20 0 L20 20" fill="url(#myGradient)" />

        <text x="0" y="11" fill="white" fontSize="3px" transform="rotate(45 12 15)">POPULAR</text>
      </svg>
    );
  }

  function getSubscriptionOldPrice(isTheMostPremium: boolean, isTrial: boolean): number {
    if (isAnnual) {
      if (isTheMostPremium) {
        if (isTrial) {
          return 119.99;
        }
        else {
          return 119.99;
        }
      }
      else {
        if (isTrial) {
          return 99.99;
        }
        else {
          return 99.99;
        }
      }
    }
    else {
      if (isTheMostPremium) {
        if (isTrial) {
          return 19.99;
        }
        else {
          return 19.99;
        }
      }
      else {
        if (isTrial) {
          return 14.99;
        }
        else {
          return 14.99;
        }
      }
    }
  }

  function getSubscriptionPrice(isTheMostPremium: boolean, isTrial: boolean): number {
    if (isAnnual) {
      if (isTheMostPremium) {
        if (isTrial) {
          return 99.99;
        }
        else {
          return 99.99;
        }
      }
      else {
        if (isTrial) {
          return 79.99;
        }
        else {
          return 79.99;
        }
      }
    }
    else {
      if (isTheMostPremium) {
        if (isTrial) {
          return 9.99;
        }
        else {
          return 9.99;
        }
      }
      else {
        if (isTrial) {
          return 7.99;
        }
        else {
          return 7.99;
        }
      }
    }
  }

  function getSubscriptionName(isTheMostPremium: boolean, isTrial: boolean): string {
    if (isAnnual) {
      if (isTheMostPremium) {
        if (isTrial) {
          return 'diamond-yearly-trial';
        }
        else {
          return 'diamond-yearly';
        }
      }
      else {
        if (isTrial) {
          return 'premium-yearly-trial';
        }
        else {
          return 'premium-yearly';
        }
      }
    }
    else {
      if (isTheMostPremium) {
        if (isTrial) {
          return 'diamond-monthly-trial';
        }
        else {
          return 'diamond-monthly';
        }
      }
      else {
        if (isTrial) {
          return 'premium-monthly-trial';
        }
        else {
          return 'premium-monthly';
        }
      }
    }
  }

  function buyPremium(isTrial: boolean): void {
    openCheckout(getSubscriptionName(true, isTrial));
  }

  function buyPaid(isTrial: boolean): void {
    openCheckout(getSubscriptionName(false, isTrial));
  }

  return (
    <Dialog open={isDialogOpened} onClose={() => setIsDialogOpened(false)}>
      <Box sx={{
        width: '600px', height: '600px', maxWidth: '80vw', minWidth: '320px',
      }}
      >
        <Box sx={{ paddingTop: '30px', paddingBottom: '10px' }}>
          <Typography variant="h5" className="center-items" style={{ fontWeight: '600', fontSize: '24px' }}>
            YOU HAVE EXCEEDED FREE LIMIT OF
          </Typography>
          <Typography variant="h5" style={{ color: '#9E00FF', fontWeight: '600', fontSize: '24px' }} className="center-items">
            {dialogText}
          </Typography>
          <Typography variant="h5" style={{ marginTop: '10px', fontWeight: '700', fontSize: '15px' }} className="center-items">
            Choose plan that fits your needs:
          </Typography>
        </Box>
        <Grid
          sx={{
            height: '77%', paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px',
          }}
          container
          spacing={5}
        >
          <Grid item xs={6}>
            <Item>
              <FreeResourcesExceededDialogSubscription isAnnual={isAnnual} buySubscription={buyPaid} subscriptionName="Standard" price={getSubscriptionPrice(false, false)} oldPrice={getSubscriptionOldPrice(false, false)} isPrefered={false} subscriptionResources={paidSubscriptionResources} />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <CornerSVG />
              </div>
              <FreeResourcesExceededDialogSubscription isAnnual={isAnnual} buySubscription={buyPremium} subscriptionName="Premium" price={getSubscriptionPrice(true, false)} oldPrice={getSubscriptionOldPrice(true, false)} isPrefered subscriptionResources={premiumSubscriptionResources} />
            </Item>
          </Grid>
        </Grid>
        <Box>
          <Stack direction="row" component="label" alignItems="center" justifyContent="center">
            <Typography style={{ fontWeight: '600' }}>Monthly</Typography>
            <Switch color='secondary' checked={isAnnual} onChange={handleIsAnnualChange} />
            <Typography style={{ fontWeight: '600', marginRight: '4px' }}>Annually:</Typography>
            <Typography style={{ marginRight: '4px' }}>Additional</Typography>
            <Typography style={{ fontWeight: '600', marginRight: '4px' }}>20%</Typography>
            <Typography>discount</Typography>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
}
