import axios from 'axios';

async function getWatchlistNames() {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Watchlist`;
    const response = await axios.get(URL);
    return response.data;
}

async function getStockWatchlists(stock: string) {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Watchlist/ByStock?stockSymbol=${stock}`;
    const response = await axios.get(URL);
    return response.data;
}

async function postUpsertWatchlist(name: string) {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Watchlist`;
    const response = await axios.post(URL, name);
    return response.data;
}

async function postStock(data: any) {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Watchlist/Stock`;
    const response = await axios.post(URL, data);
    return response.data;
}

const deleteWatchlistById = async (id: number) => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Watchlist?id=${id}`;
    await axios.delete(URL);
  };

const WatchlistService = {
    getWatchlistNames,
    getStockWatchlists,
    postUpsertWatchlist,
    deleteWatchlistById,
    postStock
};

export default WatchlistService;