import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import ChipsNavigator from '../../../components/ChipsNavigator';
import PricePosition from '../../../components/PricePosition';
import humanize from '../../../services/humanize';

interface HeaderPropsModel {
  companyProfile: any;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  menuItems: string[];
}

export default function Header({
  companyProfile,
  value,
  setValue,
  menuItems,
}: HeaderPropsModel) {
  const [logoLoaded, setLogoLoaded] = useState(false);
  const headerLabel = `${companyProfile?.companyName} (${companyProfile?.symbol})`;

  return (
    <Stack direction={'column'}>
      <Box
        style={{
          flexDirection: 'row',
          display: 'flex',
          width: '100%',
          minHeight: '80px',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: '0 10px 0 10px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', gap: '10px', flexGrow: 10 }}>
          {companyProfile?.image ? (
            <img
              src={companyProfile?.image}
              alt="logo"
              height="60px"
              style={logoLoaded ? {} : { display: 'none' }}
              onLoad={() => setLogoLoaded(true)}
            />
          ) : (
            <Skeleton variant="circular" width={60} height={60} />
          )}
          {companyProfile?.symbol ? (
            <Typography sx={{ alignSelf: 'center', fontSize: '1.8rem', fontWeight: '400' }} variant="h1">
              {headerLabel}
            </Typography>
          ) : (
            <Skeleton variant="rectangular" width={200} height={40} />
          )}
        </Box>
      </Box>
      <Box>
        <Stack direction={'row'}>
          <ChipsNavigator value={value} setValue={setValue} menuItems={menuItems}/>
          <PricePosition currency={companyProfile?.currency} price={companyProfile?.price} positionQuantity={companyProfile?.positionQuantity} change={companyProfile?.changes} />
        </Stack>
      </Box>
    </Stack>
  );
}
