import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box } from '@mui/material';
import { useEffect } from 'react';

export default function Auth() {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
  }, [instance]);

  return (
    <Box sx={{ width: '100%' }} />
  );
}
