import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import useUserCultureData from '../../../../hooks/useUserCultureData';
import ExchangesByCountry, { Constituent } from '../../../../models/dtos/exchangesByCountry';
import DataGridService from '../../../../services/dataGridService';
import useTreeExchangeStyles from '../../styles/useTreeExchangeStyles';
import './TreeExchangeSelect.scss';

interface TreeExchangeSelectPropsModel {
  selectedExchanges: string[],
  selectedIndexes: string[],
  setSelectedExchanges: React.Dispatch<React.SetStateAction<string[]>>,
  setSelectedIndexes: React.Dispatch<React.SetStateAction<string[]>>,
  constituentsTreeData: TreeNode[],
  setConstituentsTreeData: React.Dispatch<React.SetStateAction<TreeNode[]>>,
  isLoading: boolean,
}

export default function TreeExchangeSelect({
  selectedExchanges,
  selectedIndexes,
  setSelectedExchanges,
  setSelectedIndexes,
  constituentsTreeData,
  setConstituentsTreeData,
  isLoading,
}: TreeExchangeSelectPropsModel) {
  const { data: userIpData } = useUserCultureData();
  const { data: constituentsQuery } = useQuery(['getConstituents'], () => DataGridService.getExchanges());
  const classes = useTreeExchangeStyles();
  const theme = useTheme();

  useEffect(() => {
    if (constituentsQuery) {
      const { country } = userIpData ?? { country: 'US' };
      const newConstituentsTreeData = [] as any[];
      let newChoosenExchange: string[] = [country];
      let newChoosenIndexes: string[] = [];

      constituentsQuery.forEach((elem: ExchangesByCountry) => {
        newConstituentsTreeData.push({
          label: elem.country,
          checked: elem.country === country,
          className: `flag-${elem.country.toLowerCase()}`,
          tagClassName: `flag-${elem.country.toLowerCase()}`,
          children: elem.constituents.map((constituent: Constituent) => {
            const constituentSymbol = constituent.symbol;
            if (country === 'US' && elem.country === country) {
              newChoosenIndexes = ['S&P500'];
            } else if (elem.country === country) {
              newChoosenExchange.push(constituentSymbol);
            }
            return {
              label: constituentSymbol,
              checked: (country !== 'US' && elem.country === country) || (elem.country === country && constituentSymbol === 'S&P500'),
              tagClassName: `flag-${elem.country.toLowerCase()}`,
              type: constituent.type,
            };
          }),
        });
      });

      if (newChoosenIndexes.length > 0) {
        setSelectedIndexes(newChoosenIndexes);
      }
      else {
        setSelectedExchanges(newChoosenExchange);
      }
      setConstituentsTreeData(newConstituentsTreeData);
    }
  }, [constituentsQuery, userIpData]);

  useEffect(() => {
    if (constituentsTreeData.length > 0) {
      setConstituentsTreeData((oldModel) => (oldModel.map((elem) => ({
        ...elem,
        checked: (selectedExchanges.includes(elem.label) || selectedIndexes.includes(elem.label)),
        disabled: isLoading,
        children: elem.children.map((child: any) => ({
          ...child,
          checked: (selectedExchanges.includes(child.label) || selectedIndexes.includes(child.label)),
          disabled: isLoading,
        })),
      }))));
    }
  }, [isLoading]);

  function onChange(currentNode: TreeNode) {
    let constituentsToSwitch = [];
    if (currentNode._depth === 0) {
      constituentsToSwitch = (constituentsTreeData.find((elem: any) => (
        elem.label === currentNode.label
      )) as any).children.map((elem: any) => ({ symbol: elem.label, type: elem.type }));
    }
    constituentsToSwitch.push({ symbol: currentNode.label, type: currentNode.type });

    const exchangesToSwitch = [] as string[];
    const indexesToSwitch = [] as string[];
    constituentsToSwitch.forEach((constituent: Constituent) => {
      if (constituent.type === 'Index') {
        indexesToSwitch.push(constituent.symbol);
      } else {
        exchangesToSwitch.push(constituent.symbol);
      }
    });

    if (currentNode.checked) {
      setSelectedExchanges((oldExchanges) => [
        ...exchangesToSwitch, ...oldExchanges,
      ]);
      setSelectedIndexes((oldIndexes) => [
        ...indexesToSwitch, ...oldIndexes,
      ]);
    } else {
      setSelectedExchanges((oldExchanges) => (
        oldExchanges.filter((elem) => !exchangesToSwitch.includes(elem) && elem !== currentNode.label)
      ));
      setSelectedIndexes((oldIndexes) => (
        oldIndexes.filter((elem) => !indexesToSwitch.includes(elem) && elem !== currentNode.label)
      ));
    }
  }

  const dropdownTree = useMemo(() => (
    <DropdownTreeSelect
      data={constituentsTreeData}
      className={classes.treeExchange}
      keepOpenOnSelect
      clearSearchOnChange
      onChange={onChange}
    />
  ), [constituentsTreeData, theme]);

  return (
    <Box sx={{ display: 'flex' }}>
      {dropdownTree}
    </Box>
  );
}
