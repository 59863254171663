import { Box, Typography } from '@mui/material';
import {
  FacebookShareButton, FacebookIcon, FacebookMessengerShareButton,
  FacebookMessengerIcon, TwitterShareButton, TwitterIcon, TelegramShareButton,
  TelegramIcon, WhatsappShareButton, WhatsappIcon, RedditShareButton,
  RedditIcon, EmailShareButton, EmailIcon,
} from 'react-share';
import './SocialMediaShareIcons.css';

interface SocialMediaShareIconsProps{
  shareUrl: string,
  title: string
}

export default function SocialMediaShareIcons({
  shareUrl, title,
}: SocialMediaShareIconsProps) {
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box className="share-icon-container">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
        >
          <FacebookIcon size={50} round />
        </FacebookShareButton>
        <Typography variant="inherit">
          Facebook
        </Typography>
      </Box>
      <Box className="share-icon-container">
        <TwitterShareButton
          url={shareUrl}
          title={title}
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>
        <Typography variant="inherit">
          Twitter
        </Typography>
      </Box>
      <Box className="share-icon-container">
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="1123142615047106"
        >
          <FacebookMessengerIcon size={50} round />
        </FacebookMessengerShareButton>
        <Typography variant="inherit">
          Messenger
        </Typography>
      </Box>
      <Box className="share-icon-container">
        <TelegramShareButton
          url={shareUrl}
          title={title}
        >
          <TelegramIcon size={50} round />
        </TelegramShareButton>
        <Typography variant="inherit">
          Telegram
        </Typography>
      </Box>
      <Box className="share-icon-container">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=": "
        >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
        <Typography variant="inherit">
          Whatsapp
        </Typography>
      </Box>
      <Box className="share-icon-container">
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
        >
          <RedditIcon size={50} round />
        </RedditShareButton>
        <Typography variant="inherit">
          Reddit
        </Typography>
      </Box>
      <Box className="share-icon-container">
        <EmailShareButton
          url={shareUrl}
          subject={title}
        >
          <EmailIcon size={50} round />
        </EmailShareButton>
        <Typography variant="inherit">
          Email
        </Typography>
      </Box>
    </Box>
  );
}
