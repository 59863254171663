import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PortfolioOverview from '../overview/portfolioOverview';
import Header from './components/header';
import Transactions from '../transactions/transactions';
import usePortfolioNames from '../../../hooks/usePortfolioNames';

interface MyProps {
  index: string | undefined;
  value: string | undefined;
}

const menuItems = ['transactions', 'preview'];

function TabPanel({ index, value, children }: React.PropsWithChildren<MyProps>) {
  return (
    <Box hidden={value !== index} sx={{ width: '80%', height: '100%', marginBottom: '30px' }}>
      {children}
    </Box>
  );
}

export default function PortfolioManagementDetails() {
  const { portfolio: portfolioName } = useParams();
  const { data: portfolioNames } = usePortfolioNames();

  const [searchParams, setSearchParams] = useSearchParams();
  const [chosenTab, setChosenTab] = useState<string>('transactions');

  function getPortfolioId(){
    if (!portfolioNames){
      return null;
    }

    return portfolioNames.find((element: any) => element.name == portfolioName).id;
  }

  useEffect(() => {
    if (searchParams.has('tab')) {
      const tabFromUrl = searchParams.get('tab')?.toLowerCase();
      const exists = menuItems.some((item) => item.toLowerCase() === tabFromUrl);

      if (exists && tabFromUrl) {
        setChosenTab(tabFromUrl);
      }
    }
  }, []);

  useEffect(() => {
    const searchParams = { tab: chosenTab };
    setSearchParams(searchParams);
  }, [chosenTab]);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Helmet>
        <title>{stockSymbol as string}: Analysis, Price, Dividends, News | StockTradeIQ</title>
      </Helmet> */}
      <Header
        value={chosenTab}
        setValue={setChosenTab}
        menuItems={menuItems}
        portfolioName={portfolioName!}
      />
      <Box className="center-items" sx={{ width: '100%', flexGrow: '1' }}>
        <TabPanel value={chosenTab} index="transactions">
          <Transactions portfolioName={portfolioName as string} portfolioId={getPortfolioId()}/>
        </TabPanel>
        <TabPanel value={chosenTab} index="preview">
          <PortfolioOverview selectedPortfolioName={portfolioName}/>
        </TabPanel>
      </Box>
    </Box>
  );
}
