import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAdsContext } from '../../contexts/AdsContext';
import NotLoggedUserWelcomeDialog from './components/NotLoggedUserWelcomeDialog';

export default function NotLoggedUser() {
  const adsContext = useAdsContext();

  return (
    <Box sx={{ width: '100%', minHeight: '100%', display: 'block' }}>
      <Helmet>
        <title>Create Your Account | Unlock Full Access to StockTradeIQ Features</title>
      </Helmet>
      <Box sx={{ height: !adsContext.adsAreVisible ? '100%' : '80%', width: '100%' }} className="center-items">
        <NotLoggedUserWelcomeDialog />
      </Box>
      {adsContext.adsAreVisible
        && (
        <Box sx={{ height: '20%', width: '100%' }} className="center-items ad">
          ads
        </Box>
        )}
    </Box>
  );
}
