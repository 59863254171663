import { useQuery } from '@tanstack/react-query';
import NewsService from '../../../services/newsService';
import MostChangeStocks from './MostChangeStocks';

export default function MostGainerStocks() {
  const { data: mostGainerCompaniesData } = useQuery(['getMostGainerStockCompanies'], NewsService.getMostGainerStockCompanies);

  return (
    <MostChangeStocks
      label="Gainers"
      data={mostGainerCompaniesData}
    />
  );
}
