import { useParams } from 'react-router-dom';
import useGetScreenShareConfiguration from '../../../components/analyticsGrid/hooks/useGetScreenShareConfiguration';
import DividendStockScreener from '../dividend/dividendStockScreener';
import QuickScreener from '../quick/quickScreener';

export default function SharedStockScreener() {
  const { configurationId } = useParams();
  const { data: sharedScreenConfiguration } = useGetScreenShareConfiguration(configurationId);
  if (sharedScreenConfiguration?.screenerName === 'dividend') {
    return (
      <DividendStockScreener
        sharedScreenConfiguration={sharedScreenConfiguration}
      />
    );
  }
  return (
    <QuickScreener
      sharedScreenConfiguration={sharedScreenConfiguration}
    />
  );
}
