import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './components/header';
import usePortfolioNames from '../../../hooks/usePortfolioNames';
import PortfolioOverview from '../overview/portfolioOverview';
import Dividends from '../dividends/dividends';
import OldAnalysis from './oldAnalysis';

interface MyProps {
  index: string | undefined;
  value: string | undefined;
}

const menuItems = ['overview', 'analytics', 'dividends'];

function TabPanel({ index, value, children }: React.PropsWithChildren<MyProps>) {
  return (
    <Box hidden={value !== index} sx={{ width: '80%', height: '100%', marginBottom: '30px' }}>
      {children}
    </Box>
  );
}

export default function Analysis() {
  const { data, isFetching } = usePortfolioNames();

  const [searchParams, setSearchParams] = useSearchParams();
  const [chosenTab, setChosenTab] = useState<string>('overview');
  const [selectedPortfoliosIds, setSelectedPortfoliosIds] = useState<number[]>([]);

  useEffect(() => {
    if (searchParams.has('tab')) {
      const tabFromUrl = searchParams.get('tab')?.toLowerCase();
      const exists = menuItems.some((item) => item.toLowerCase() === tabFromUrl);

      if (exists && tabFromUrl) {
        setChosenTab(tabFromUrl);
      }
    }
  }, []);

  useEffect(() => {
    const searchParams = { tab: chosenTab };
    setSearchParams(searchParams);
  }, [chosenTab]);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Helmet>
        <title>{stockSymbol as string}: Analysis, Price, Dividends, News | StockTradeIQ</title>
      </Helmet> */}
      <Header
        value={chosenTab}
        setValue={setChosenTab}
        menuItems={menuItems}
        portfolios={data}
        setSelectedPortfolioIds={setSelectedPortfoliosIds}
      />
      <Box className="center-items" sx={{ width: '100%', flexGrow: '1' }}>
        <TabPanel value={chosenTab} index="overview">
          <PortfolioOverview selectedPortfolioIds={selectedPortfoliosIds}/>
        </TabPanel>
        <TabPanel value={chosenTab} index="analytics">
          <OldAnalysis selectedPortfolioIds={selectedPortfoliosIds}/>
        </TabPanel>
        <TabPanel value={chosenTab} index="dividends">
          <Dividends selectedPortfolioIds={selectedPortfoliosIds}/>
        </TabPanel>
      </Box>
    </Box>
  );
}
