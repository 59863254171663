import { Box, Button, Divider, Paper, Skeleton, Stack, Typography } from "@mui/material";
import Swiper from "../../../../../../components/Swiper/Swiper";
import STIScore from "../../../../../../components/KeyTakeaways/components/STIScore";
import BasicTable from "../../../../components/BasicTable/BasicTable";
import BasicPriceChart from "../../../PriceChart/components/BasicPriceChart/BasicPriceChart";
import CompanyOverviewChart from "../CompanyOverviewChart";
import useSimilarCompanies from "../../../../../../hooks/useSimilarCompanies";
import { useRef, useState } from "react";
import ROUTES from "../../../../../../constants/routes";

interface SimilarCompaniesPropsType {
    stockSymbol: string | undefined
}



export default function SimilarCompanies({ stockSymbol }: SimilarCompaniesPropsType) {
    const [logoLoaded, setLogoLoaded] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { data: similarCompanies, dataUpdatedAt: similarCompaniesUpdatedAt } = useSimilarCompanies(stockSymbol!);

    function handleStockButtonClick(symbol: string) {
        window.open(ROUTES.stockDetailedData(symbol), '_blank');
        window.scrollTo(0, 0);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ padding: '15px 50px 5px 15px', width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '700' }}>
                SIMILAR COMPANIES
            </Typography>
            <Swiper
                swiperHeight="500px"
            >
                <Stack direction={"row"} gap={"120px"}>
                    {similarCompanies?.map((company: any) => (
                        <Paper key={company.symbol} sx={{ padding: '30px', width: '550px' }}>
                            <Stack direction={"column"}>
                                <Stack direction={"row"} sx={{ marginLeft: '80px', marginRight: '80px' }}>
                                    <Box>
                                        <img
                                            src={`https://financialmodelingprep.com/image-stock/${company.symbol}.png`}
                                            alt="logo"
                                            height="60px"
                                            style={logoLoaded ? {} : { display: 'none' }}
                                            onLoad={() => setLogoLoaded(true)}
                                        />
                                        <Skeleton variant="circular" width={60} height={60} style={logoLoaded ? { display: 'none' } : {}} />
                                    </Box>
                                    <Stack direction={"column"} sx={{ marginLeft: '40px' }}>
                                        <Button
                                            sx={{
                                                maxWidth: '100%',
                                                height: '100%',
                                                textAlign: 'left',
                                                alignItems: 'center',
                                            }}
                                            variant="text"
                                            ref={buttonRef}
                                            onClick={() => handleStockButtonClick(company.symbol)}
                                        >
                                            <Typography sx={{ overflow: 'hidden' }} variant="inherit">
                                                {company.name} ({company.symbol})
                                            </Typography>
                                        </Button>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <BasicTable
                                    data={{
                                        'Country': company.country,
                                        'Sector': company.sector,
                                        'Industry': company.industry,
                                        'Dividend yield': `${company.dividendYieldPercentageTTM}%`,
                                        'Payout Ratio': `${company.payoutRatioTTM * 100}%`,
                                        'P/E': company.peRatioTTM
                                    }}
                                    isLoading={false}
                                />
                                <Box>
                                    <CompanyOverviewChart symbol={company.symbol} currency={"USD"} showDatePicker={false} height={200} full={false} />
                                </Box>
                            </Stack>
                        </Paper>
                    ))}
                </Stack>

            </Swiper>
        </Box>
    );
}
