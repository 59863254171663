import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useColorModeContext } from '../../../../../../contexts/ColorModeContext';
import TradingViewWidget from './TradingViewChartEmbed';
import BasicPriceChart from '../BasicPriceChart/BasicPriceChart';

interface AdvancedPriceChartPropsModel {
  symbol: string;
  full: boolean;
}

export default function AdvancedPriceChart({ symbol }: AdvancedPriceChartPropsModel) {
  const colorMode = useColorModeContext();

  const chart = useMemo(() => (
    <BasicPriceChart
      symbol={symbol}
      full
    />
  ), [symbol, colorMode.isLightMode]);

  return <Box sx={{ height: '100%', width: '100%' }}>{chart}</Box>;
}
