import { Box } from '@mui/material';
import useCompanyDividends from '../hooks/useCompanyDividends';
import DividendsChart from './components/DividendsChart';
import DividendsGrid from './components/DividendsGrid';

interface DividendsPropsModel {
  symbol: string;
}

export default function Dividends({ symbol }: DividendsPropsModel) {
  const {
    data: dividendsDataQuery,
    isLoading: isLoadingDividendsData,
  } = useCompanyDividends(symbol);

  return (
    <Box
      sx={{
        minHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', gap: '20px',
      }}
    >
      <DividendsChart
        dividendsDataQuery={dividendsDataQuery || []}
        isLoadingDividendsData={isLoadingDividendsData}
      />
      <DividendsGrid
        dividendsData={dividendsDataQuery || []}
        isLoadingDividendsData={isLoadingDividendsData}
      />
    </Box>
  );
}
