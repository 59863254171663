import UserCultureData from '../models/dtos/userIpData';
import fullTimeZoneToCountriesMapping from '../constants/fullTimeZoneToCountriesMapping';
import countries from '../constants/countries';

function getCountry(): string {
  const culture = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryName = fullTimeZoneToCountriesMapping[(culture as keyof typeof fullTimeZoneToCountriesMapping)];
  const country = countries.find(c=> c.name === countryName);

  if (country === undefined) {
    return 'US';
  }

  return country.code;
} 

const getUserCultureData = async () => {
  let response = {} as UserCultureData;
  response.country = getCountry();
  return response;
};

const userInfoService = {
  getUserCultureData: getUserCultureData,
};

export default userInfoService;
