import { useState, useEffect } from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export default function DateEditCell(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  
  // Convert the value to a dayjs object if it's not already
  const [dateValue, setDateValue] = useState(value ? dayjs(value) : null);

  useEffect(() => {
    if (value) {
      setDateValue(dayjs(value));
    }
  }, [value]);

  const handleValueChange = (newValue: any) => {
    if (!newValue) {
      return;
    }

    const newDate = newValue.toDate();
    apiRef.current.setEditCellValue({ id, field, value: newDate });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: '100%', height: '100%', marginTop: '10px' }}
        value={dateValue}
        onChange={handleValueChange}
        label='Choose transaction date'
      />
    </LocalizationProvider>
  );
}