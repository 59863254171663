import { Box, Grid, Paper, Typography, styled } from '@mui/material';
import FreeResourcesExceededDialogSubscription from '../../layout/components/FreeResourceExceededDialogSubscription';

export default function Pricing() {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '100%',
        margin: 'auto'
    }));

    function openCheckout(path: string): void {
        (window as any).fastspring.builder.push({
            products: [
                {
                    path,
                },
            ],
            paymentContact: {
                email: 'myname@email.com',
                firstName: 'John',
                lastName: 'Doe',
            },
            checkout: true,
        });
    }

    const paidSubscriptionResources = [
        'Price alerts (5 in a month)',
        'Fundamental alerts (5 in a month)',
        '100 Portfolios (500 tickers)',
        '3 Watchlists (25 tickers)',
    ];

    const premiumSubscriptionResources = [
        'Price alerts (30 in a month)',
        'Fundamental alerts (30 in a month)',
        '200 Portfolios (800 tickers)',
        'Unlimited watchlists',
        'Unlimited STI score',
    ];

    function buyPremium(): void {
        openCheckout('Premium');
    }

    function buyPaid(): void {
        openCheckout('paid');
    }

    return (
        <Grid container sx={{ width: '80%', margin: 'auto' }} alignItems={'stretch'}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: "64px", fontWeight: "600", maxWidth: '60%', margin: 'auto', textAlign: 'center' }}>
                    Head: Lorem ipsum lorem ipsum lorem ipsum
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems={'stretch'}>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ marginTop: '10px', fontWeight: '700', fontSize: '15px' }} className="center-items">
                            Choose plan that fits your needs:
                        </Typography>
                    </Grid>
                    <Grid item xs={3}/>
                    <Grid item xs={3}>
                        <Item>
                            {/* <FreeResourcesExceededDialogSubscription clickButton={buyPaid} subscriptionName="Standard" price="8" oldPrice="13.90" isPrefered={false} subscriptionResources={paidSubscriptionResources} /> */}
                        </Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Item >
                            {/* <FreeResourcesExceededDialogSubscription clickButton={buyPremium} subscriptionName="Premium" price="9.90" oldPrice="19.90" isPrefered subscriptionResources={premiumSubscriptionResources} /> */}
                        </Item>
                    </Grid>
                    <Grid item xs={3}/>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: "48px", fontWeight: "600", maxWidth: '60%', margin: 'auto', textAlign: 'center' }}>
                    Compare plans
                </Typography>
            </Grid>
        </Grid>
    );
}
