import { Box, Divider } from '@mui/material';
import './blogsOverview.css';
import { Helmet } from 'react-helmet';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import BlogService from '../../services/blogService';
import BlogOverviewDto from '../../models/dtos/blogOverviewDto';
import Loader from '../../components/Loader';
import BlogOverview from './components/BlogOverview';

export default function BlogSOverview() {
  const { data: blogOverviews } = useQuery(['getblogOverviews'], BlogService.getBlogArticlesOverviews);

  return (
    <Box sx={{
      padding: '20px', marginTop: '50px', width: '100%', gap: '20px', display: 'flex', flexDirection: 'column',
    }}
    >
      <Helmet>
        <title />
      </Helmet>
      <div className="articles">
        <Stack spacing={1} divider={<Divider flexItem />}>
          {blogOverviews ? blogOverviews.map((data: BlogOverviewDto) => (
            <BlogOverview data={data} />
          ))
            : <Box sx={{ width: '100%' }} className="center-items"><Loader /></Box>}
        </Stack>
      </div>
    </Box>
  );
}
