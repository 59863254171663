import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';

interface IntervalSelectPropsModel{
    label: string,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    valuesToSelect: string[],
}

export default function IntervalSelect({
  label, value, setValue, valuesToSelect,
}: IntervalSelectPropsModel) {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <FormControl sx={{ margin: '10px 10px 10px 10px' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label="Interval"
        onChange={handleChange}
        size="small"
      >
        {valuesToSelect.map((valueToSelect: string) => (
          <MenuItem value={valueToSelect} key={valueToSelect}>{valueToSelect}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
