import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import CartesianChart from '../../../../../../../components/charts/CartesianChart';

interface StatisticsChartPropsModel{
  selectedRows: string[],
  data: any,
  isLoading: boolean,
  rowLabels: Record<string, string>,
}

export default function StatisticsChart({
  selectedRows,
  data,
  isLoading,
  rowLabels,
}: StatisticsChartPropsModel) {
  const [chartSeries, setChartSeries] = useState<any[]>([]);

  useEffect(() => {
    if (selectedRows) {
      const newChartSeries = selectedRows.map((value: string) => (
        {
          type: 'line',
          name: rowLabels[value],
          showSymbol: false,
          encode: {
            x: 'calendarYear',
            y: value,
            tooltip: value,
          },
        }
      ));
      setChartSeries(() => newChartSeries);
    }
  }, [selectedRows]);

  return (
    <Paper sx={{ width: '90%', height: '500px' }}>
      <CartesianChart
        data={data}
        isLoading={isLoading}
        series={chartSeries}
        inverse
        yValueSufix=""
        showLegend
      />
    </Paper>
  );
}
