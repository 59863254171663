import { Box, Paper, Typography } from '@mui/material';
import BasicTable from '../../../../../components/BasicTable/BasicTable';
import useCompanyOverview from '../../../../hooks/useCompanyOverview';

interface FundamentalSecondTablePropsType{
  stockSymbol: string | undefined,
  width : string
  paperHeight : string
}

export default function FundamentalSecondTable(
  { stockSymbol, width, paperHeight }: FundamentalSecondTablePropsType,
) {
  const {
    data: companyOverview,
    isLoading: isLoadingCompanyOverview,
  } = useCompanyOverview(stockSymbol as string);

  return (
    <Box sx={{ width, maxWidth: '100%' }}>
      <Typography variant="h6" sx={{ padding: '5px 0px 5px 15px', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700', visibility: 'hidden' }}>
        FUNDAMENTALS
      </Typography>
      <Paper sx={{ width, maxWidth: '100%', height: paperHeight }}>
        <BasicTable
          data={{
            'Net debt/ebidta': companyOverview?.netDebtToEBITDATTM,
            'Interest coverage': companyOverview?.interestCoverageTTM,
            'Research And Developement To Revenue': companyOverview?.researchAndDevelopementToRevenueTTM,
            'Intangile to total assets': companyOverview?.intangiblesToTotalAssetsTTM,
            'Capex to operating cash flow': companyOverview?.capexToOperatingCashFlowTTM,
            'Capex to revenue': companyOverview?.capexToRevenueTTM,
            'Capex to depreciation': companyOverview?.capexToDepreciationTTM,
            'Return on tangible assets': companyOverview?.returnOnTangibleAssetsTTM,
            'Debt to market cap': companyOverview?.debtToMarketCapTTM,
            'Piotroski Score': companyOverview?.piotroskiScore,
          }}
          isLoading={isLoadingCompanyOverview}
        />
      </Paper>
    </Box>
  );
}
