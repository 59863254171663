import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useCompanyProfile from '../../hooks/useCompanyProfile';
import Header from './components/Header';
import CompanyNews from './Tabs/CompanyNews/CompanyNews';
import StockDividends from './Tabs/Dividends/Dividends';
import Overview from './Tabs/Overview/Overview';
import StockPriceChart from './Tabs/PriceChart/StockPriceChart';
import Statistics from './Tabs/Statistics/Statistics';

interface MyProps {
  index: string | undefined;
  value: string | undefined;
}

const menuItems = ['overview', 'price', 'dividends', 'statistics', 'news'];

function TabPanel({ index, value, children }: React.PropsWithChildren<MyProps>) {
  return (
    <Box hidden={value !== index} sx={{ width: '100%', height: '100%', marginBottom: '30px' }}>
      {children}
    </Box>
  );
}

export default function StockDetailedData() {
  const { stock: stockSymbol } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [chosenTab, setChosenTab] = useState<string>('overview');
  const { data: companyProfile } = useCompanyProfile(stockSymbol as string);

  useEffect(() => {
    if (searchParams.has('tab')) {
      const tabFromUrl = searchParams.get('tab')?.toLowerCase();
      const exists = menuItems.some((item) => item.toLowerCase() === tabFromUrl);

      if (exists && tabFromUrl) {
        setChosenTab(tabFromUrl);
      }
    }
  }, []);

  useEffect(() => {
    const searchParams = { tab: chosenTab };
    setSearchParams(searchParams);
  }, [chosenTab]);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Helmet>
        <title>{stockSymbol as string}: Analysis, Price, Dividends, News | StockTradeIQ</title>
      </Helmet>
      <Header
        companyProfile={companyProfile}
        value={chosenTab}
        setValue={setChosenTab}
        menuItems={menuItems}
      />
      <Box className="center-items" sx={{ width: '100%', flexGrow: '1' }}>
        <TabPanel value={chosenTab} index="overview">
          <Overview stockSymbol={stockSymbol as string} />
        </TabPanel>
        <TabPanel value={chosenTab} index="price">
          <StockPriceChart symbol={stockSymbol as string} />
        </TabPanel>
        <TabPanel value={chosenTab} index="dividends">
          <StockDividends symbol={stockSymbol as string} />
        </TabPanel>
        <TabPanel value={chosenTab} index="statistics">
          <Statistics symbol={stockSymbol as string} />
        </TabPanel>
        <TabPanel value={chosenTab} index="news">
          <CompanyNews symbol={stockSymbol} />
        </TabPanel>
      </Box>
    </Box>
  );
}
