import { Box, Grid } from '@mui/material';
import PieChartByCompany from './charts/PieChartByCompany';
import PieChartBySector from './charts/PieChartBySector';
import ResponsiveLinePortfolioValue from './charts/ResponsiveLinePortfolioValue';
import './oldAnalysis.css';
import { useAdsContext } from '../../../contexts/AdsContext';
import { Helmet } from 'react-helmet';

interface Props {
  selectedPortfolioIds: number[];
}

export default function OldAnalysis({ selectedPortfolioIds }: Props) {
  const adsContext = useAdsContext();

  return (
    <Box
      sx={{
        width: '100%', height: '100%',
      }}
      className="center-items"
    >
      <Helmet>
        <title>Portfolio Analysis Tool | Track & Optimize Investments | StockTradeIQ</title>
      </Helmet>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ResponsiveLinePortfolioValue selectedPortfolioIds={selectedPortfolioIds} />
        </Grid>
        <Grid item xs={12} md={6} className='analysis-pie-chart'>
          <PieChartBySector selectedPortfolioIds={selectedPortfolioIds} />
        </Grid>
        <Grid item xs={12} md={6} className='analysis-pie-chart'>
          <PieChartByCompany selectedPortfolioIds={selectedPortfolioIds} />
        </Grid>
      </Grid>
    </Box>
  );
}
