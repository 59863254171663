import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material';
import ChipsNavigator from '../../../../components/ChipsNavigator';
import React from 'react';

interface HeaderPropsModel {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    menuItems: string[];
    portfolioName: string
}

export default function Header({
    value,
    setValue,
    menuItems,
    portfolioName
}: HeaderPropsModel) {

    const headerLabel = `Portfolio: ${portfolioName}`;

    return (
        <Stack direction={'row'}>
            <Stack direction={'column'}>
                <Box
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        width: '100%',
                        minHeight: '80px',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 10px 0 10px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '10px', flexGrow: 10 }}>
                        <Typography sx={{ alignSelf: 'center', fontSize: '42px', fontWeight: '500', marginLeft: '25px', marginTop: '25px' }}>
                            {headerLabel}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <ChipsNavigator value={value} setValue={setValue} menuItems={menuItems} />
                </Box>
            </Stack>
        </Stack>
    );
}
