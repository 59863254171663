import { Box, Typography } from '@mui/material';
import pageContent from '../../../constants/pageContent';
import dashboardBackground from '../../../assets/dashboardBackground.jpg';
import './BackgroundImage.css';

export default function BackgroundImage() {
  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        overflow: 'hidden',
        zIndex: '0',
        height: pageContent.height,
      }}
    >
      <div style={{display: 'relative'}}>
        <img
          src={dashboardBackground}
          alt="Mountain horizon"
          className="dashboard-background-image"
        />
        {/* <Typography sx={{ overflow: 'hidden', position: 'absolute', right: '115px', bottom: '120px', paddingLeft:'750px', color: '#fff' }} variant="body1">Welcome to StockTradeIQ, the ultimate stock screener and portfolio analyzer tool. Boost your investment decisions with our powerful features that enable you to effortlessly screen stocks based on customizable criteria and gain valuable insights into your portfolio's performance. Discover your winning edge and make smarter trades with StockTradeIQ.</Typography> */}
        {/* <Typography sx={{ overflow: 'hidden', position: 'absolute', right: '115px', bottom: '50px', color: '#fff' }} variant="body2">© 2023 STOCKTRADEIQ SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ</Typography> */}
      </div>
    </Box>
  );
}
