import {
  Box, Button, Paper, Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import PriceTargetRSSFeed from '../../../models/dtos/priceTargetRSSFeed';
import NewsService from '../../../services/newsService';
import './PredictionsCarousel.css';
import Loader from '../../../components/Loader';
import Swiper from '../../../components/Swiper/Swiper';
import useGlobalStyles from '../../../styles/useGlobalStyles';

export default function Carousel() {
  const { data: pricePredictions } = useQuery(['getPriceTargetRSSFeed'], NewsService.getPriceTargetRSSFeed);
  const globalClasses = useGlobalStyles();

  function handleNewsTitleClick(URL: string) {
    window.open(URL);
  }

  return (
    <Paper className="center-items" sx={{ position: 'relative' }} elevation={0}>
      <Box sx={{ width: '100%' }} className={globalClasses.header}>
        <Typography
          variant="h2"
          sx={{
            width: '100%', padding: '10px 0 10px 0', fontSize: '1.5rem', fontWeight: '400',
          }}
        >
          {' '}
          Price Predictions

        </Typography>
      </Box>
      <Swiper
        swiperHeight="320px"
      >
        {pricePredictions ? pricePredictions.map((pricePrediction: PriceTargetRSSFeed) => (
          <Paper className="prediction-carousel-cell" elevation={10} key={pricePrediction.newsTitle} sx={{ backgroundColor: 'inherit', textAlign: 'center' }}>
            <Box sx={{
              whiteSpace: 'normal', overflow: 'hidden', height: '65px', textOverflow: 'ellipsis',
            }}
            >
              <Button sx={{ fontSize: '12px' }} onClick={() => { handleNewsTitleClick(pricePrediction.newsURL); }}>{pricePrediction.newsTitle}</Button>
            </Box>
            <Box sx={{ display: 'flex', height: '100px', marginTop: '10px' }} className="center-items">
              <Box sx={{ width: '30%' }}>
                <Box sx={{}}>
                  <Typography variant="h5">
                    {pricePrediction.priceWhenPosted}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ width: '100%', whiteSpace: 'normal', fontSize: '10px' }}>
                    Price when posted:
                    {' '}
                    {pricePrediction.publishedDate.slice(0, 10)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: '20%' }}>
                {(pricePrediction.priceTarget > pricePrediction.priceWhenPosted)
                  ? <KeyboardDoubleArrowUpIcon sx={{ color: 'green' }} fontSize="large" />
                  : <KeyboardDoubleArrowDownIcon sx={{ color: 'red' }} fontSize="large" />}
              </Box>
              <Box sx={{ width: '30%' }}>
                <Box>
                  <Typography variant="h5">
                    {pricePrediction.priceTarget}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ width: '100%', whiteSpace: 'normal', fontSize: '10px' }}>
                    Price target by:
                    {' '}
                    {pricePrediction.analystName}
                    {' '}
                    (
                    {pricePrediction.analystCompany}
                    )
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        ))
          : <Box sx={{ width: '100%' }} className="center-items"><Loader /></Box>}
      </Swiper>
    </Paper>
  );
}
