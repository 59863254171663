import { Box, TableCell } from "@mui/material";
import Humanize from "../../services/humanize";

export default function CustomCell(params: any) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', // Vertically centers the content
          background: "rgba(240, 240, 240, 0.4)",
          width: '100%',
          height: '80%', 
          paddingLeft: '10px', // Use padding instead of margin
          paddingRight: '40px',
          borderRadius: '8px',
        }}
      >
        <TableCell 
          {...params}
          sx={{
            height: '100%', // Ensures the TableCell takes the full height of the Box
            padding: 0, // Removes default padding
            display: 'flex',
            alignItems: 'center', // Ensures content inside TableCell is vertically centered
          }}
        >
          {Humanize.readableValue(params.value)}
        </TableCell>
      </Box>
    );
  };