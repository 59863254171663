/* eslint-disable no-alert */
/* eslint-disable max-len */
import './App.scss';
import {
  BrowserRouter, Route, Routes, useNavigate,
} from 'react-router-dom';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import RequestInterceptor from './interceptors/RequestInterceptor';
import Transactions from './pages/Portfolio/transactions/transactions';
import Layout from './layout/Layout';
import { DrawerContextProvider } from './contexts/DrawerContext';
import StockDetailedData from './pages/stockDetailedData/stockDetailedData';
import Dividends from './pages/Portfolio/dividends/dividends';
import { LoaderContextProvider } from './contexts/LoaderContext';
import Dashboard from './pages/dashboard/dashboard';
import FavouriteStocksScreener from './pages/stocksScreener/favourite/favouriteStocksScreener';
import IgnoredStocksScreener from './pages/stocksScreener/ignored/ignoredStocksScreener';
import { ColorModeContextProvider } from './contexts/ColorModeContext';
import NotLoggedUser from './pages/notLoggedUser/notLoggedUser';
import { DialogContextProvider } from './contexts/DialogContext';
import BugReport from './pages/support/bugReport/BugReport';
import ContactUS from './pages/support/contactUS/ContactUS';
import FeatureRequest from './pages/support/featureRequest/FeatureRequest';
import DividendStockScreener from './pages/stocksScreener/dividend/dividendStockScreener';
import ImportTransactions from './pages/Portfolio/import/importTransactions';
import News from './pages/news/news';
import QuickScreener from './pages/stocksScreener/quick/quickScreener';
import PortfolioOverview from './pages/Portfolio/overview/portfolioOverview';
import SharedStockScreener from './pages/stocksScreener/shared/sharedStockScreener';
import ContactUsNotLoggedUsers from './pages/support/contactUsNotLoggedUsers/ContactUsNotLoggedUsers';
import PrivacyPolicy from './pages/documents/PrivacyPolicy';
import CookiePolicy from './pages/documents/CookiePolicy';
import TermsOfService from './pages/documents/TermsOfService';
import SharedConfigurations from './pages/stocksScreener/sharedConfigurations/sharedConfigurations';
import BlogsOverview from './pages/blogsOverview/blogsOverview';
import BlogPost from './pages/blogPost/blogPost';
import Auth from './pages/auth/auth';
import { FreeResourcesExceededDialogContextProvider } from './contexts/FreeResourcesExceededDialogContext';
import Pricing from './pages/pricing/pricing';
import Analysis from './pages/Portfolio/analysis/analysis';
import PortfolioManagement from './pages/Portfolio/management/PortfolioManagement';
import PortfolioManagementDetails from './pages/Portfolio/managementDetails/PortfolioManagementDetails';
import WatchlistManagement from './pages/watchlist/WatchlistManagement';
import TermsOfServiceForPaid from './pages/documents/TermsOfServiceForPaid';

function Pages() {
  return (
    <Routes>
      <Route
        path=":configurationId"
        element={(
          <>
            <AuthenticatedTemplate>
              <SharedStockScreener />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <SharedStockScreener />
            </UnauthenticatedTemplate>
          </>
        )}
      />
      <Route path="stockScreener">
        <Route
          path="quick"
          element={(
            <>
              <AuthenticatedTemplate>
                <QuickScreener />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <QuickScreener />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="screener"
          element={(
            <>
              <AuthenticatedTemplate>
                <DividendStockScreener />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <DividendStockScreener />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="shared"
          element={(
            <>
              <AuthenticatedTemplate>
                <SharedConfigurations />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="favourite"
          element={(
            <>
              <AuthenticatedTemplate>
                <FavouriteStocksScreener />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="ignored"
          element={(
            <>
              <AuthenticatedTemplate>
                <IgnoredStocksScreener />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
      </Route>
      <Route
        path="detailedData/:stock"
        element={(
          <>
            <AuthenticatedTemplate>
              <StockDetailedData />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <StockDetailedData />
            </UnauthenticatedTemplate>
          </>
        )}
      />
      <Route path="portfolio">
        <Route
          path="Analysis"
          element={(
            <>
              <AuthenticatedTemplate>
                <Analysis />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="management"
          element={(
            <>
              <AuthenticatedTemplate>
                <PortfolioManagement />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="management/details/:portfolio"
          element={(
            <>
              <AuthenticatedTemplate>
                <PortfolioManagementDetails />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
      </Route>
      <Route
        path="pricing"
        element={(
          <>
            <Pricing />
          </>
        )}
      />
      <Route
        path="watchlist"
        element={(
          <>
            <WatchlistManagement />
          </>
        )}
      />
      <Route path="support">
        <Route
          path="bugReport"
          element={(
            <>
              <AuthenticatedTemplate>
                <BugReport />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="contactUS"
          element={(
            <>
              <AuthenticatedTemplate>
                <ContactUS />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <ContactUsNotLoggedUsers />
              </UnauthenticatedTemplate>
            </>
          )}
        />
        <Route
          path="featureRequest"
          element={(
            <>
              <AuthenticatedTemplate>
                <FeatureRequest />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NotLoggedUser />
              </UnauthenticatedTemplate>
            </>
          )}
        />
      </Route>
      <Route path="documents">
        <Route
          path="PrivacyPolicy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="CookiePolicy"
          element={<CookiePolicy />}
        />
        <Route
          path="TermsAndConditions"
          element={<TermsOfService />}
        />
        <Route
          path="TermsAndConditionsForPaid"
          element={<TermsOfServiceForPaid />}
        />
      </Route>
      <Route path="auth" element={<Auth />} />
      <Route path="news" element={<News />} />
      <Route path="blog" element={<BlogsOverview />} />
      <Route path="blog/:id" element={<BlogPost />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="*" element={<DividendStockScreener />} />
    </Routes>
  );
}

function App({ msalInstance }: any) {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  LicenseInfo.setLicenseKey('2c54a92a863b933216a5931e6a1d614cTz05MTAzMSxFPTE3NDc5MzQxODIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: twentyFourHoursInMs,
        cacheTime: twentyFourHoursInMs,
      },
    },
  });

  return (
    <div className="App">
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <RequestInterceptor>
            <QueryClientProvider client={queryClient}>
              <ColorModeContextProvider>
                <DrawerContextProvider>
                  <LoaderContextProvider>
                    <FreeResourcesExceededDialogContextProvider>
                      <DialogContextProvider>
                        <Layout>
                          <Pages />
                        </Layout>
                        {process.env.REACT_APP_REACT_QUERY_DEVTOOL_IS_SHOWN === 'true' && (
                          <ReactQueryDevtools initialIsOpen={false} />
                        )}
                      </DialogContextProvider>
                    </FreeResourcesExceededDialogContextProvider>
                  </LoaderContextProvider>
                </DrawerContextProvider>
              </ColorModeContextProvider>
            </QueryClientProvider>
          </RequestInterceptor>
        </MsalProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
