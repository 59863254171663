import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';

// TODO
// eslint-disable-next-line react/prop-types
const DefaultSnackbar = forwardRef<HTMLDivElement>(({ id, action, message }: any, ref) => {
  const theme = useTheme();
  return (
    <Paper
      ref={ref}
      sx={{
        height: 'auto',
        backgroundColor: theme.palette.primary.main,
        margin: '0',
        padding: '0',
        minHeight: '48px',
      }}
      className="center-items"
    >
      <Box sx={{ margin: '0px 5px 0px 5px' }} className="center-items">
        {action && action(id)}
        {message}
      </Box>
    </Paper>
  );
});

export default DefaultSnackbar;
