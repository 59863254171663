export class Parameter {
    static Price = {
        Value: 1,
        Description: 'Price'
    }
    static PE = {
        Value: 2,
        Description: 'P/E'
    }
    static PS = {
        Value: 3,
        Description: 'P/S'
    }
    static PB = {
        Value: 4,
        Description: 'P/B'
    }
    static DebtEquity = {
        Value: 5,
        Description: 'Debt/Equity'
    }
}