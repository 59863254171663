import { Box, Button, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import ShareScreenDialog from '../../Dialog/ShareScreenDialog/ShareScreenDialog';
import usePostScreenShareConfiguration from '../../hooks/usePostScreenShareConfiguration';
import DataGridConfigurationModel from '../../../../models/dataGridConfigurationModel';
import ShareGridState from '../../../../models/shareGridState';

interface ShareButtonPropsModel {
  getActualGridConfiguration?: () => DataGridConfigurationModel,
  existingScreenShareKey?: string
  screenerName?: string,
}

export default function ShareButton({
  getActualGridConfiguration, screenerName, existingScreenShareKey,
}: ShareButtonPropsModel) {
  const queryClient = useQueryClient();

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [screenShareKey, setScreenShareKey] = useState<string>('');
  const postScreenShareConfiguration = usePostScreenShareConfiguration();

  useEffect(() => {
    if (dialogIsOpen) {
      if (existingScreenShareKey) {
        setScreenShareKey(existingScreenShareKey);
      } else if (getActualGridConfiguration && screenerName) {
        const configurationJSON = JSON.stringify(getActualGridConfiguration());
        const postData: ShareGridState = {
          ScreenerName: screenerName,
          Configuration: configurationJSON,
        };
        postScreenShareConfiguration.mutate(postData, {
          onSuccess(data: any) {
            setScreenShareKey(data);
            queryClient.refetchQueries(['getSharedConfigurationsData']);
          },
        });
      }
    }
  }, [dialogIsOpen]);

  return (
    <Box>
      <Button sx={{ color: 'white', width: 'max(160px, 20%)', background: '#2196F3', height: '30px', borderRadius: '4px' }} variant='contained' startIcon={<ShareIcon />} onClick={() => { setDialogIsOpen(() => true); }} >
        <Typography sx={{ color: 'white', width: '100%', textAlign: 'center', fontSize: '14px' }} >
          Share
        </Typography>
      </Button>
      <ShareScreenDialog
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
        link={`${window.location.host}/${screenShareKey}`}
      />
    </Box>
  );
}
