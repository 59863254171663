import { Box, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import PortfoliosGrid from './components/portfoliosGrid';

export default function PortfolioManagement() {

  return (
    <Box
      sx={{
        minHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', gap: '20px',
      }}
    >
      <Box
        style={{
          width: '100%',
          minHeight: '80px',
          padding: '0 10px 0 10px',
        }}
      >
        <Typography sx={{ alignSelf: 'center', fontSize: '36px', fontWeight: '500', marginLeft: '25px', marginTop: '25px' }}>
          Portfolio management
        </Typography>
      </Box>
      <PortfoliosGrid />
    </Box>
  );
}
