import {
  GridColDef,
  GridRenderCellParams,
  GridColumnVisibilityModel,
  GridValueFormatterParams,
  GridColTypeDef,
} from '@mui/x-data-grid';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { LinearProgress, Paper } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { TreeNode } from 'react-dropdown-tree-select';
import StockDetailedData from '../../models/stockDetailedData';
import CustomToolbar from './customToolbar/CustomToolbar';
import DataGridConfigurationModel from '../../models/dataGridConfigurationModel';
import ActionsCell from './ActionsCell';
import './analyticsGrid.css';
import { useLoaderContext } from '../../contexts/LoaderContext';
import Humanize from '../../services/humanize';
import useIgnoredStocks from '../../hooks/useIgnoredStocks';
import useFavouriteStocks from '../../hooks/useFavouriteStocks';
import useGetStockDetailedData from './hooks/useGetStockDetailedData';
import StockQuickViewCellButton from '../StockQuickViewCellButton';
import ShareGridStateDto from '../../models/dtos/shareGridStateDto';
import usePortfolioValueByCompanyByPortfolioIds from '../../hooks/usePortfoliosValueByCompany';
import useWatchlistNames from '../../hooks/useWatchlistNames';
import { forEach } from 'lodash';

interface AnalyticsGridProps {
  customColumnVisibilityModel: GridColumnVisibilityModel;
  showGoalTextField: boolean;
  hiddenColumns: string[];
  screenerName: string;
  showAll: boolean;
  showFavourite: boolean;
  showIgnored: boolean;
  sharedScreenConfiguration?: ShareGridStateDto | null;
  autoSave?: string;
  onlyFavourite?: boolean;
  onlyIgnored?: boolean;
  showOnlyDividendStocksButton?: boolean;
}

const defaultColumnVisibilityModel = {
  debtToAssetsTTM: false,
  priceToSalesRatioTTM: false,
  freeCashFlowYieldTTM: false,
  ptbRatioTTM: false,
  earningsYieldTTM: false,
  sector: false,
  industry: false,
};

function getFloatNumberColDef(round: number) {
  return (
    {
      type: 'number',
      valueGetter: ({ value }) => Number(value?.toFixed(round)),
    } as GridColTypeDef
  );
}

export default function AnalyticsGrid(props: AnalyticsGridProps) {
  const dataGridRef = useGridApiRef();
  const {
    customColumnVisibilityModel,
    showGoalTextField,
    hiddenColumns,
    screenerName,
    showAll,
    showFavourite,
    showIgnored,
    sharedScreenConfiguration = undefined,
    autoSave = 'false',
    onlyFavourite = false,
    onlyIgnored = false,
    showOnlyDividendStocksButton = false,
  } = props;
  const [selectedWatchlists, setSelectedWatchlists] = useState<string[]>([]);
  const [selectedExchanges, setSelectedExchanges] = useState<string[]>([]);
  const [selectedIndexes, setSelectedIndexes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const { data: allTableDataQuery, isLoading: isLoadingAllTableDataQuery } = useGetStockDetailedData(
    selectedExchanges,
    selectedIndexes,
    onlyFavourite,
    onlyIgnored,
  );
  const [allTableData, setAllTableData] = useState<StockDetailedData[]>();
  const { data: ignoredStocks, dataUpdatedAt: ignoredDataUpdatedAt } = useIgnoredStocks();
  const [onlyDividendStocks, setOnlyDividendStocks] = useState<boolean>(showOnlyDividendStocksButton);
  const { data: favouriteStocks, dataUpdatedAt: favouriteDataUpdatedAt } = useFavouriteStocks();
  const { data: portfolioByCompany, dataUpdatedAt: portfolioByCompanyUpdatedAt } = usePortfolioValueByCompanyByPortfolioIds([]); // STOP using that one
  const { data: watchlists, dataUpdatedAt: watchlistsUpdatedAt } = useWatchlistNames();
  const [actualInitialState, setActualInitialState] = useState<GridInitialStatePro>();
  const [dividendGoal, setDividendGoal] = useState(1000);
  const [constituentsTreeData, setConstituentsTreeData] = useState<TreeNode[]>([]);
  const [startConfiguration, setStartConfiguration] = useState<DataGridConfigurationModel | null>(
    null,
  );
  const [startInitialStateToReset, setStartInitialStateToReset] = useState<GridInitialStatePro | null>(null);
  const { setIsLoaderOpened } = useLoaderContext();

  useEffect(() => {
    setIsLoaderOpened(() => true);
    return () => {
      setIsLoaderOpened(() => false);
    };
  }, []);

  function getActualGridConfiguration() {
    return {
      initialState: actualInitialState,
      exchanges: selectedExchanges,
      indexes: selectedIndexes,
      dividendGoal,
      showOnlyDividendStocks: onlyDividendStocks,
    } as DataGridConfigurationModel;
  }

  function applyNewDividendGoal(data: StockDetailedData[], newGoal: number) {
    function calculateNeededMoneyToGoal(dividendYieldPercentageTTM: number | null) {
      if (dividendYieldPercentageTTM) {
        return ((newGoal / dividendYieldPercentageTTM) * 100).toFixed(2);
      }
      return null;
    }
    return data.map((row: any) => ({
      ...row,
      moneyNeededToGoal: calculateNeededMoneyToGoal(row.dividendYieldPercentageTTM),
    }));
  }

  function applyPortfolioValues(data: StockDetailedData[], portfolioByCompany: any) {
    data.forEach(element => {
      const portfolioValues = portfolioByCompany?.find((p: { symbol: string | null; }) => p.symbol == element.symbol)

      if (portfolioValues != undefined) {
        element.portfolioQuantity = portfolioValues.quantity;
        element.portfolioValue = portfolioValues.value;
      }
    });

    return data;
  }

  function createInitialStateToReset() {
    setStartInitialStateToReset(getActualGridState());
  }

  function createStartState() {
    setStartConfiguration(getActualGridConfiguration());
    createInitialStateToReset();
  }

  const [tableData, setTableData] = useState<StockDetailedData[]>([]);
  const [lastDisplayedConfiguration, setLastDisplayedConfiguration] = useState<DataGridConfigurationModel>();
  const [lastDisplayedConfigurationLabel, setLastDisplayedConfigurationLabel] = useState<string>('');
  const [isCustomStatesMenuOpen, setIsCustomStatesMenuOpen] = useState(false);
  const [isSaveButtonShown, setIsSaveButtonShown] = useState<boolean>(false);

  function findSymbolsByWatchlistNames(watchlistNames: string[]) {
    const filteredSymbols = watchlistNames.flatMap(name => {
      const watchlist = watchlists?.find((wl: { name: string; }) => wl.name === name);
      return watchlist ? watchlist.symbols : [];
    });

    return filteredSymbols;
  }

  function filterIgnoredFavouriteDividend(data: StockDetailedData[]) {
    let selectedData = data.filter((row) => {
      if (ignoredStocks.includes(row.symbol as string)) {
        if (showIgnored) {
          return true;
        }
      } else if (favouriteStocks.includes(row.symbol as string)) {
        if (showFavourite) {
          return true;
        }
      } else if (showAll) {
        return true;
      }
      return false;
    });

    if (selectedWatchlists.length > 0) {
      const watchlistsSymbols = findSymbolsByWatchlistNames(selectedWatchlists);
      selectedData = selectedData.filter((row) => watchlistsSymbols.includes(row.symbol))
    }

    if (onlyDividendStocks) {
      selectedData = selectedData.filter((row) => (row.dividendYieldPercentageTTM > 0));
    }
    return selectedData;
  }

  useEffect(() => {
    setPaginationModel({ ...paginationModel, page: 0 });
    setIsSaveButtonShown(true);
  }, [selectedExchanges, selectedIndexes, onlyDividendStocks]);

  useEffect(() => {
    if (allTableDataQuery) {
      const newTableData = applyNewDividendGoal(allTableDataQuery, dividendGoal);
      setAllTableData(() => newTableData);
      if (tableData) {
        setTableData((oldModel: any) => applyPortfolioValues(oldModel as StockDetailedData[], portfolioByCompany));
        setAllTableData((oldModel: any) => applyPortfolioValues(oldModel as StockDetailedData[], portfolioByCompany));
      }
      const newChoosenData = filterIgnoredFavouriteDividend(newTableData);
      setTableData(() => newChoosenData);
    }
  }, [allTableDataQuery]);

  useEffect(() => {
    if (allTableData) {
      setTableData(() => filterIgnoredFavouriteDividend(allTableData));
      handleStateChange(false);
    }
  }, [ignoredDataUpdatedAt, favouriteDataUpdatedAt, selectedWatchlists, watchlistsUpdatedAt, onlyDividendStocks]);

  useEffect(() => {
    let exchangesToAdd: string[] = [];

    selectedWatchlists.forEach(watchlistname => {
      if (!watchlists)
        return;

      let watchlist = watchlists.find((w: { name: string; }) => w.name == watchlistname);

      if (watchlist) {
        watchlist.exchanges.forEach((exchange: string) => {
          if (!selectedExchanges.includes(exchange))
            exchangesToAdd.push(exchange);
        });
      }
    })

    if (exchangesToAdd.length > 0){
      setSelectedExchanges((oldValue) => [...oldValue, ...exchangesToAdd])
    }
  }, [selectedWatchlists]);

  useEffect(() => {
    if (startConfiguration) {
      setIsLoaderOpened(() => false);
      setIsLoading(false);

      if (!lastDisplayedConfiguration) {
        setLastDisplayedConfiguration(startConfiguration);
      }
    }
  }, [startConfiguration]);

  useEffect(() => {
    if (
      !startConfiguration
      || JSON.stringify(getActualGridConfiguration()) === JSON.stringify(lastDisplayedConfiguration)
      || (autoSave === 'false'
        && JSON.stringify(startConfiguration) === JSON.stringify(getActualGridConfiguration()))
    ) {
      setIsSaveButtonShown(false);
    } else {
      setIsSaveButtonShown(true);
    }
  }, [dividendGoal, actualInitialState, lastDisplayedConfiguration]);

  useEffect(() => {
    if (
      !startConfiguration
      && allTableData
      && dividendGoal
      && (onlyFavourite || onlyIgnored || selectedExchanges.length > 0 || selectedIndexes.length > 0)
    ) {
      createStartState();
    }
  }, [allTableData, dividendGoal, selectedExchanges, selectedIndexes]);

  useEffect(() => {
    if (tableData) {
      setTableData((oldModel: any) => applyNewDividendGoal(oldModel as StockDetailedData[], dividendGoal));
    }
  }, [dividendGoal]);

  useEffect(() => {
    if (tableData) {
      setTableData((oldModel: any) => applyPortfolioValues(oldModel as StockDetailedData[], portfolioByCompany));
    }
    if (allTableData) {
      setAllTableData((oldModel: any) => applyPortfolioValues(oldModel as StockDetailedData[], portfolioByCompany));
    }
  }, [portfolioByCompanyUpdatedAt]);

  function changeDividendGoal(newGoal: number) {
    setDividendGoal(newGoal);
  }

  function refreshTableData(newGoal: number) {
    setDividendGoal(newGoal);
  }

  function resetDataGridState() {
    if (startInitialStateToReset) {
      dataGridRef.current.restoreState(startInitialStateToReset);
    }
  }

  function updateConstituentsTree(newSelectedExchanges: string[], newSelectedIndexes: string[]) {
    if (!newSelectedExchanges) return;
    setConstituentsTreeData((oldExchanges: any) => oldExchanges.map((exchange: any) => ({
      ...exchange,
      checked: newSelectedExchanges.includes(exchange.label) || newSelectedIndexes.includes(exchange.label),
      children: exchange.children.map((elem: any) => ({
        ...elem,
        checked: newSelectedExchanges.includes(elem.label) || newSelectedIndexes.includes(elem.label),
      })),
    })));
  }

  function displayCustomGridState(
    newConfiguration: DataGridConfigurationModel,
    label: string,
    notUpdateLastConfiguration = false,
  ) {
    resetDataGridState();
    setSelectedExchanges(newConfiguration.exchanges);
    setSelectedIndexes(newConfiguration.indexes);
    setOnlyDividendStocks(newConfiguration.showOnlyDividendStocks);
    updateConstituentsTree(newConfiguration.exchanges, newConfiguration.indexes);
    refreshTableData(newConfiguration.dividendGoal);
    if (!notUpdateLastConfiguration) {
      setLastDisplayedConfiguration(newConfiguration);
    }
    setLastDisplayedConfigurationLabel(label);
    dataGridRef.current.restoreState(newConfiguration.initialState);
    setIsSaveButtonShown(false);
  }

  useEffect(() => {
    if (startInitialStateToReset && startConfiguration && sharedScreenConfiguration && sharedScreenConfiguration.screenerName === screenerName) {
      const newConfiguration = JSON.parse(sharedScreenConfiguration.configuration);
      displayCustomGridState(newConfiguration, '');
    }
  }, [startInitialStateToReset, startConfiguration, sharedScreenConfiguration]);

  function resetDataGridConfiguration() {
    displayCustomGridState(startConfiguration as DataGridConfigurationModel, '', true);
    setSelectedWatchlists([]);
  }

  function getActualGridState() {
    return dataGridRef.current.exportState();
  }

  function handleStateChange(resetPage: boolean) {
    if (resetPage) {
      setPaginationModel({ ...paginationModel, page: 0 });
    }

    const state = getActualGridState();
    setActualInitialState(state);
  }

  const defaultColumns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      resizable: false,
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <ActionsCell symbol={params.row.symbol} stockData={params.row} />
      ),
      cellClassName: 'actions-column',
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 0,
      resizable: true,
      width: 70,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <StockQuickViewCellButton
          name={params.value}
          columnWidth={params.colDef.width}
          symbol={params.row.symbol}
          key={params.value}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0,
      resizable: true,
      width: 200,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <StockQuickViewCellButton
          name={params.value}
          columnWidth={params.colDef.width}
          symbol={params.row.symbol}
          key={params.value}
        />
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 80,
    },
    {
      field: 'portfolioQuantity',
      headerName: 'Portfolio Quantity',
      description: 'Number of shares in porfolio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 150,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'portfolioValue',
      headerName: 'Portfolio Value',
      description: 'Value of shares in portfolio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 150,
      ...getFloatNumberColDef(3),
    },
    // { field: 'indexes', headerName: 'indexes', flex: 0, resizable: true, width: 220,
    //   renderCell: (params: GridRenderCellParams<Array<string>>) => (
    //     <Box>
    //       {params && params?.value?.join(', ')}
    //     </Box>
    //   )},
    {
      field: 'moneyNeededToGoal',
      headerName: 'Investment to goal',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 150,
    },
    {
      field: 'dividendYieldPercentageTTM',
      headerName: 'Dividend Yield %',
      description: 'Dividend Yield Percentage',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 130,
    },
    {
      field: 'payoutRatioTTM',
      headerName: 'Payout Ratio %',
      description: 'Payout Ratio Percentage',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(2),
    },
    {
      field: 'consecutiveYearsOfDividendIncrease',
      headerName: 'Years Of Div. Increase',
      description: 'Years Of Dividend Increase',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 180,
    },
    {
      field: 'qShiftPreviousYear',
      headerName: 'Q-Shift',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 80,
    },
    {
      field: 'peRatioTTM',
      headerName: 'P/E',
      description: 'Price To Earnings Ratio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 80,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'pbRatioTTM',
      headerName: 'P/B',
      description: 'Price To Book Ratio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 80,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'freeCashFlowPerShareTTM',
      headerName: 'FCF Per Share',
      description: 'Free Cash Flow Per Share',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'dividendPerShareTTM',
      headerName: 'Dividend per Share',
      description: 'Dividend Per Share',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 150,
    },
    {
      field: 'debtToEquityTTM',
      headerName: 'Debt/Equity',
      description: 'Debt To Equity',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
    },
    {
      field: 'evToFreeCashFlowTTM',
      headerName: 'EV/FCF',
      description: 'Enterprise Value To Free Cash Flow',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 80,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'marketCap',
      headerName: 'Market Cap',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) => Humanize.compactNumber(params.value, 3),
    },
    {
      field: 'debtToAssetsTTM',
      headerName: 'Debt/Assets',
      description: 'Debt To Assets',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'priceToSalesRatioTTM',
      headerName: 'Price/Sales Ratio',
      description: 'Price To Sales Ratio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 150,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'freeCashFlowYieldTTM',
      headerName: 'FCF Yield %',
      description: 'Free Cash Flow Yield Percentage',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
    },
    {
      field: 'ptbRatioTTM',
      headerName: 'PTB Ratio',
      description: 'Price To Book Ratio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'grahamNetNet',
      headerName: 'Net-Net',
      description: 'Graham Net-Net',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'roe',
      headerName: 'RoE %',
      description: 'Return on Equity Percentage',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(2),
    },
    {
      field: 'roic',
      headerName: 'RoIC %',
      description: 'Return on Invested Capital Percentage',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(2),
    },
    {
      field: 'quickRatioTTM',
      headerName: 'Quick Ratio',
      description: "Relationship between a company's cash, assets and liabilities",
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'currentRatioTTM',
      headerName: 'Current Ratio',
      description: "Relationship between a company's assets and liabilities",
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'pegRatioTTM',
      headerName: 'PEG',
      description: 'PEG builds on the P/E ratio by considering expected earnings growth',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'priceCashFlowRatioTTM',
      headerName: 'P/CF',
      description: 'Price to Cash Flow Ratio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'priceToFreeCashFlowsRatioTTM',
      headerName: 'P/FCF',
      description: 'Price to Free Cash Flow Ratio',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(3),
    },
    {
      field: 'grossProfitMarginTTM',
      headerName: 'Gross Profit Margin %',
      description: 'Percentage of sales revenue that a company is able to convert into gross profit',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(2),
    },
    {
      field: 'netProfitMarginTTM',
      headerName: 'Net Profit Margin %',
      description: 'Measures how much net income is generated as a percentage of revenues received',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(2),
    },
    {
      field: 'returnOnAssetsTTM',
      headerName: 'RoA %',
      description: 'Return on Assets Percentage',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 120,
      ...getFloatNumberColDef(2),
    },
    {
      field: 'earningsYieldTTM',
      headerName: 'Earnings Yield %',
      description: 'Earning Yield Percentage',
      flex: 0,
      resizable: true,
      type: 'number',
      width: 150,
      ...getFloatNumberColDef(2),
    },
    {
      field: 'sector',
      headerName: 'Sector',
      flex: 0,
      resizable: true,
      width: 180,
    },
    {
      field: 'industry',
      headerName: 'Industry',
      flex: 0,
      resizable: true,
      width: 200,
    },
  ];
  const [columns] = useState(defaultColumns.filter((elem) => !hiddenColumns.includes(elem.field)));

  return (
    <Paper sx={{ height: '100%', display: 'block', position: 'relative' }}>
      <DataGridPro
        apiRef={dataGridRef}
        rows={tableData}
        columns={columns}
        getRowId={(row) => row.symbol}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ...defaultColumnVisibilityModel,
              ...customColumnVisibilityModel,
            },
          },
          pinnedColumns: { left: ['actions'] },
        }}
        sx={{
          fontSize: '13px',
          '& .MuiDataGrid-row:nth-of-type(even) .MuiDataGrid-cell:not(.actions-column)': {
            backgroundColor: '#F9F9F9',
          }
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          toolbar: {
            resetDataGridState,
            displayCustomGridState,
            changeDividendGoal,
            resetDataGridConfiguration,
            dividendGoal,
            setIsCustomStatesMenuOpen,
            isCustomStatesMenuOpen,
            isSaveButtonShown,
            setIsSaveButtonShown,
            setLastDisplayedConfiguration,
            lastDisplayedConfigurationLabel,
            getActualGridConfiguration,
            showGoalTextField,
            setLastDisplayedConfigurationLabel,
            screenerName,
            actualInitialState,
            autoSave,
            selectedExchanges,
            selectedWatchlists,
            setSelectedWatchlists,
            selectedIndexes,
            setSelectedExchanges,
            setSelectedIndexes,
            constituentsTreeData,
            watchlists,
            setConstituentsTreeData,
            showExchangesTree: !(onlyFavourite || onlyIgnored),
            isLoadingAllTableDataQuery,
            isLoading,
            showOnlyDividendStocksButton,
            onlyDividendStocks,
            setOnlyDividendStocks,
            showShareButton: !(onlyFavourite || onlyIgnored),
          },
        }}
        loading={isLoading || isLoadingAllTableDataQuery}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onFilterModelChange={() => handleStateChange(true)}
        onSortModelChange={() => handleStateChange(true)}
        onColumnVisibilityModelChange={() => handleStateChange(false)}
        onColumnWidthChange={() => handleStateChange(false)}
        onColumnOrderChange={() => handleStateChange(false)}
        onPinnedColumnsChange={() => handleStateChange(false)}
        pageSizeOptions={[5, 10, 15, 20]}
        density="compact"
        pagination
      />
    </Paper>
  );
}
