import {
  Box, Button, InputBase, Link, Paper, Typography,
} from '@mui/material';
import './dashboard.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import BackgroundImage from './components/BackgroundImage';
import ROUTES from '../../constants/routes';
import StocksSearch from '../../components/StockSearch/StocksSearch';
import TopPerformersWidget from './components/TopPerformersWidget';

export default function Dashboard() {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState<boolean>(false);

  function handleChangeTextFieldValue(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(() => e.target.value);
    setSearchMenuIsOpen(() => true);
  }

  function handleClickMenuItem(companySymbol: string) {
    if (companySymbol === '') return;
    navigate(ROUTES.stockDetailedData(companySymbol));
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        overflowY: 'hidden',
      }}
    >
      <BackgroundImage />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
        <Box
          sx={{
            flexGrow: 14,
            zIndex: 1,
            paddingLeft: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              zIndex: '1',
              gap: '20px',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              marginTop: '60px',
              marginBottom: '80px',
            }}
          >
            <Typography
              className="dashboard-typography-heaer"
              sx={{
                color: 'common.white', fontSize: '64px', fontFamily: 'Inter', fontWeight: '600', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.7)'
              }}
            >
              Look for your stock
            </Typography>
            <Box sx={{ alignSelf: 'left' }}>
              <StocksSearch
                inputValue={searchValue}
                onClickMenuItem={handleClickMenuItem}
                searchMenuIsOpen={searchMenuIsOpen}
                setSearchMenuIsOpen={setSearchMenuIsOpen}
              >
                <Paper
                  style={{
                    borderRadius: '50px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', // Optional: add a shadow for better visibility,
                    height: '55px',
                    width: '400px'
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '5px 10px 5px 0',
                    position: 'relative',
                    alignItems: 'center',
                    backgroundColor: 'white',
                  }}
                >
                  <InputBase
                    value={searchValue}
                    placeholder="Search..."
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextFieldValue(e)}
                    sx={{ width: '400px', marginLeft: '20px', color: 'black' }}
                  />
                </Paper>
              </StocksSearch>
            </Box>
          </Box>
          <Box
            sx={{
              zIndex: '1',
              display: 'flex',
              flexDirection: 'column',
            }}
          />
          <Box
            sx={{
              zIndex: '1',
              display: 'flex',
              flexDirection: 'column',
            }}
          />
          <Box
            sx={{
              zIndex: '1',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Typography
              sx={{
                color: 'common.white',
              }}
            >
              OR
            </Typography>
          </Box>
          <Box
            sx={{
              zIndex: '1',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              marginTop: '4%'
            }}
          >
            <Typography
              sx={{
                color: 'common.white',
                fontSize: '48px',
                fontFamily: 'inter',
                maxWidth: '500px',
                fontWeight: '600', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.7)'
              }}
            >
              Search investing opportunities
            </Typography>
            <Typography
              sx={{
                color: 'common.white',
                fontSize: '20px',
                fontFamily: 'inter',
                fontWeight: '500'
              }}
            >
              Search for well-doing stocks
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'left',
                gap: '20px',
                width: '100%',
              }}
            >
              <div>
                <Button
                  style={{
                    padding: '12px 50px',
                    margin: '10px',
                    textTransform: 'none',
                    borderRadius: '50px',  // Rounded edges
                    background: 'linear-gradient(90deg, #330867 0%, #30CFD0 100%)',  // Gradient background
                    color: 'white' // Set color based on the current path
                  }}
                >
                  <Link sx={{ color: 'common.white', fontSize: '18px' }} underline="none" to={ROUTES.stockScreener.dividend} title='Stock screener' component={RouterLink}>
                    STOCK SCREENER
                  </Link>
                </Button>
              </div>
            </Box>
            <Box sx={{ marginTop: '20px' }}>
              <TopPerformersWidget />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
