const ROUTES = {
  dashboard: '/',
  stockScreener: {
    quick: '/stockScreener/quick',
    dividend: '/stockScreener/screener',
    shared: '/stockScreener/shared',
    favourite: '/stockScreener/favourite',
    ignored: '/stockScreener/ignored',
  },
  portfolio: {
    management: '/portfolio/management',
    analysis: '/portfolio/analysis',
    transactions: '/portfolio/transactions',
  },
  watchlist: '/watchlist',
  stockDetailedData: (stock: string) => `/detailedData/${stock}`,
  support: {
    bugReport: '/support/bugReport',
    contactUS: '/support/contactUS',
    featureRequest: '/support/featureRequest',
  },
  documents: {
    PrivacyPolicy: '/documents/PrivacyPolicy',
    CookiePolicy: '/documents/CookiePolicy',
    TermsAndConditions: '/documents/TermsAndConditions',
    TermsAndConditionsForPaid: '/documents/TermsAndConditionsForPaid',
  },
  news: '/news',
  blog: '/blog',
};

export default ROUTES;
