import { Box, Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import React from 'react';

interface EditToolbarProps {
  setRows: React.Dispatch<React.SetStateAction<GridRowsProp>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  setRowIsInWaitingMode: React.Dispatch<React.SetStateAction<any>>;
}

let number = -1;

function randomId() {
  const newId = number;
  number -= 1;
  return newId;
}

export default function EditToolbar(props: EditToolbarProps) {
  const {
    setRows, setRowModesModel, setRowIsInWaitingMode,
  } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      {
        id,
        symbol: null,
        date: null,
        quantity: null,
        isNew: true,
      },
      ...oldRows,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'symbol' },
    }));
    setRowIsInWaitingMode((oldModel: any) => ({
      ...oldModel,
      [id]: false,
    }));
  };

  return (
    <GridToolbarContainer sx={{ width: '100%', margin: '20px' }}>
      <Button sx={{ color: 'white', width: 'max(160px, 15%)', background: '#2196F3', height: '45px', borderRadius: '4px', marginLeft: '20px' }} variant='contained' onClick={handleClick}>
        <Stack direction={'row'}>
          <AddIcon />
          <Typography sx={{ color: 'white', width: '100%', fontSize: '14px', marginLeft: '20px', marginTop: '2px' }} >
            Add new
          </Typography>
        </Stack>
      </Button>
    </GridToolbarContainer>
  );
}
