import React from 'react';
import { Paper } from '@mui/material';
import './GrowingPage.css';

interface newsPagePropsModel {}

export default function GrowingPage({ children }: React.PropsWithChildren<newsPagePropsModel>) {
  return (
    <Paper elevation={10} className="growing-page" sx={{ display: 'inline-block', overflow: 'visible' }}>
      {children}
    </Paper>
  );
}
