import { createTheme } from '@mui/material/styles';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-date-pickers-pro/themeAugmentation';

const globalLightTheme = createTheme({
  palette: {
    primary: {
      main: '#4D4D4D',
      light: '#8C8C8C',
      dark: '#333333',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    info: {
      main: '#0F8BD9',
      dark: '#0B669E',
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.15)',
      selected: 'rgba(0, 0, 0, 0.0001)',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        main: {
          backgroundImage: 'linear-gradient(to bottom, #F4FCFF, #FCF3FF)',
        },
      },
    }
  }
});

export default globalLightTheme;
