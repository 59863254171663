import axios from 'axios';

async function getPortfolioNames() {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/GetPortfolios`;
  const response = await axios.get(URL);
  return response.data;
}

async function postUpsertPortfolio(name: string) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/UpsertPortfolio`;
  const response = await axios.post(URL, name);
  return response.data;
}

const deletePortfolioById = async (id: number) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/DeletePortfolio?id=${id}`;
  await axios.delete(URL);
};

async function getAllPortfolioValues(ids: number[]) {
  const queryString = ids.map(id => `ids=${id}`).join('&');
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/DayValueHistory?${queryString}`;
  const response = await axios.get(URL);
  return response.data;
}
async function getDividendValueHistory(ids: number[]) {
  const queryString = ids.map(id => `ids=${id}`).join('&');
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/DayDividendValueHistory?${queryString}`;
  const response = await axios.get(URL);
  return response.data;
}
async function getPortfolioValueBySectors(ids: number[]) {
  const queryString = ids.map(id => `ids=${id}`).join('&');
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/BySector?${queryString}`;
  const response = await axios.get(URL);
  return response.data;
}
async function getPortfoliosValueByCompanies(portfolioIds: number[]) {
  const queryString = portfolioIds.map(id => `ids=${id}`).join('&');
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/ByCompany?${queryString}`;
  const response = await axios.get(URL);
  return response.data;
}

async function getPortfoliosValueByCompaniesByName(name: string) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/ByCompanyByName?name=${name}`;
  const response = await axios.get(URL);
  return response.data;
}

const PortfolioAnalysisService = {
  getDividendValueHistory,
  getAllPortfolioValues,
  getPortfolioValueBySectors,
  getPortfoliosValueByCompanies,
  getPortfolioNames,
  postNewPortfolio: postUpsertPortfolio,
  deletePortfolioById,
  getPortfoliosValueByCompaniesByName
};

export default PortfolioAnalysisService;
