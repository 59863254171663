import { Box } from '@mui/material';
import { useAdsContext } from '../../../contexts/AdsContext';
import TransactionsGrid from './components/TransactionsGrid';
import './transactions.css';
import { Helmet } from 'react-helmet';

interface Props {
  portfolioName: string,
  portfolioId: number
}

export default function Transactions({ portfolioName, portfolioId }: Props) {


  return (
    <Box
      sx={{
        minHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', gap: '20px',
      }}
    >
      <Helmet>
        <title>Manage Portfolio Transactions | Easy Tracking & Updates | StockTradeIQ</title>
      </Helmet>
      <TransactionsGrid portfolioName={portfolioName} portfolioId={portfolioId}/>
    </Box>
  );
}
