import { Box, Paper, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import CartesianChart from '../../../../../components/charts/CartesianChart';
import ToolbarToggle from '../../../../../components/Toolbar/ToolbarToggle';
import ChartServise from '../../../../../services/chartsService';
import useCompanyPriceChartData from '../../hooks/useCompanyPriceChartData';

interface CompanyOverviewChartPropsModel {
    symbol: string,
    currency: string | undefined,
    showDatePicker: boolean,
    width?: number,
    height?: number,
    full: boolean
}

export default function CompanyOverviewChart({
  symbol,
  currency,
  showDatePicker,
  width,
  height,
  full
} : CompanyOverviewChartPropsModel) {
  const [interval, setInterval] = useState<string>('30day');
  const [startZoom, setStartZoom] = useState<number>(0);
  const [endZoom, setEndZoom] = useState<number>(100);
  const { data: chartDataQuery, isLoading: chartDataQueryIsLoading } = useCompanyPriceChartData(symbol, '1day', full);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (chartDataQuery) {
      ChartServise.calculateStartEndZoom(chartDataQuery, interval, setStartZoom, setEndZoom);
      setChartData(chartDataQuery.map((row: any) => ({ ...row, date: row.date.slice(0, 10) })));
    }
  }, [interval, chartDataQuery]);

  return (
    <Stack direction={'column'} style={{ height: '100%'}}>
      { showDatePicker && <ToolbarToggle value={interval} setValue={setInterval} valuesToSelect={['30day', '3month', '6month', '1year', '5year', 'all']} />}
      <Box style={{ flexGrow: 1, height: '100%' }}>
        <CartesianChart
          inverse
          data={chartData}
          isLoading={chartDataQueryIsLoading}
          width={width}
          height={height}
          series={[{
            type: 'line',
            name: `${symbol}`,
            showSymbol: false,
            encode: {
              x: 'date',
              y: 'close',
              tooltip: 'close',
            },
            itemStyle: {
              color: 'rgb(255, 70, 131)',
            },
            areaStyle: {
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgb(255, 158, 68)',
                  },
                  {
                    offset: 1,
                    color: 'rgb(255, 70, 131)',
                  },
                ],
                x: 0,
                x2: 0,
                y: 0,
                y2: 1,
              },
            },
          }]}
          yValueSufix={currency as string}
          showLegend={false}
          dataZoom={[{
            type: 'inside',
            xAxisIndex: [0, 1],
            moveOnMouseMove: false,
            zoomOnMouseWheel: false,
            start: startZoom,
            end: endZoom,
          }]}
        />
      </Box>
    </Stack>
  );
}
