import React, { useState } from 'react';
import {
  Button, TextField, Box, Accordion, Typography, AccordionSummary, AccordionDetails, FormControlLabel, Switch,
  Grid,
  Stack,
  Paper,
} from '@mui/material';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector,
  GridToolbarExport, GridFilterPanel,
} from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { TreeNode } from 'react-dropdown-tree-select';
import CustomStatesButtons from './CustomStatesButtons';
import './CustomToolbar.css';
import DataGridConfigurationModel from '../../../models/dataGridConfigurationModel';
import TreeExchangeSelect from './components/TreeExchangeSelect';
import { AccountCircleRounded, Public, VisibilityRounded } from '@mui/icons-material';
import ShareButton from './components/ShareButton';
import WatchlistsSelect from './components/WatchlistsSelect';

interface CustomToolbarProps {
  dividendGoal: number,
  changeDividendGoal: React.Dispatch<React.SetStateAction<number>>,
  resetDataGridConfiguration: () => void,
  // eslint-disable-next-line no-unused-vars
  displayCustomGridState: (newGridState: DataGridConfigurationModel, label: string) => void,
  setIsCustomStatesMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isCustomStatesMenuOpen: boolean,
  isSaveButtonShown: boolean,
  setIsSaveButtonShown: React.Dispatch<React.SetStateAction<boolean>>,
  setLastDisplayedConfiguration: React.Dispatch<React.SetStateAction<DataGridConfigurationModel>>,
  lastDisplayedConfigurationLabel: string,
  getActualGridConfiguration: () => DataGridConfigurationModel,
  showGoalTextField: boolean,
  setLastDisplayedConfigurationLabel: React.Dispatch<React.SetStateAction<string>>,
  screenerName: string,
  actualInitialState: GridInitialStatePro,
  autoSave: string,
  selectedExchanges: string[],
  selectedWatchlists: string[],
  watchlists: string[],
  selectedIndexes: string[],
  setSelectedExchanges: React.Dispatch<React.SetStateAction<string[]>>,
  setSelectedIndexes: React.Dispatch<React.SetStateAction<string[]>>,
  setSelectedWatchlists: React.Dispatch<React.SetStateAction<string[]>>,
  constituentsTreeData: TreeNode[],
  setConstituentsTreeData: React.Dispatch<React.SetStateAction<TreeNode[]>>,
  showExchangesTree: boolean,
  isLoadingAllTableDataQuery: boolean,
  isLoading: boolean,
  showOnlyDividendStocksButton: boolean;
  onlyDividendStocks: boolean,
  setOnlyDividendStocks: React.Dispatch<React.SetStateAction<boolean>>,
  showShareButton: boolean,
}

export default function CustomToolbar(props: CustomToolbarProps) {
  const {
    dividendGoal, changeDividendGoal, resetDataGridConfiguration,
    displayCustomGridState, setIsCustomStatesMenuOpen, isCustomStatesMenuOpen,
    isSaveButtonShown, setIsSaveButtonShown, setLastDisplayedConfiguration, lastDisplayedConfigurationLabel,
    getActualGridConfiguration, showGoalTextField, setLastDisplayedConfigurationLabel,
    screenerName, actualInitialState, autoSave, selectedExchanges, selectedIndexes, selectedWatchlists,
    setSelectedExchanges, setSelectedIndexes, setSelectedWatchlists, constituentsTreeData,
    setConstituentsTreeData, showExchangesTree, isLoadingAllTableDataQuery, showOnlyDividendStocksButton,
    onlyDividendStocks, setOnlyDividendStocks, showShareButton, watchlists,
  } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      changeDividendGoal(0);
    } else if (Number(event.target.value)) { changeDividendGoal(Number(event.target.value)); }
  };

  const [expanded, setExpanded] = useState('');

  const handleAccordionChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <GridToolbarContainer
      className="toolbar"
      sx={{
        display: 'flex', gap: '5px', margin: '0', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'space-between', background: '#F4FCFF'
      }}
    >
      <Grid container sx={{ marginRight: '35%', zIndex: '4' }}>
        <Grid item xs={3}>
          <Accordion className="filters-toolbar" expanded={expanded === 'range'} onChange={handleAccordionChange('range')}>
            <AccordionSummary className="filters-toolbar-header" expandIcon={<ExpandMoreIcon />}
              aria-controls="range-content"
              id="range-header">
              <FilterListIcon sx={{ color: '#707070' }} />
              <Typography sx={{ fontWeight: '600', marginLeft: '10px' }}>Filter by range</Typography>
            </AccordionSummary>
          </Accordion>
        </Grid>
        <Grid item xs={3}>
          <Accordion className="filters-toolbar" expanded={expanded === 'markets'} onChange={handleAccordionChange('markets')}>
            <AccordionSummary className="filters-toolbar-header" expandIcon={<ExpandMoreIcon />}
              aria-controls="markets-content"
              id="markets-header">
              <Public sx={{ color: '#707070' }} />
              <Typography sx={{ fontWeight: '600', marginLeft: '10px' }}>Filter by markets</Typography>
            </AccordionSummary>
          </Accordion>
        </Grid>
        <Grid item xs={3}>
          <Accordion className="filters-toolbar" expanded={expanded === 'watchlist'} onChange={handleAccordionChange('watchlist')}>
            <AccordionSummary className="filters-toolbar-header" expandIcon={<ExpandMoreIcon />}
              aria-controls="watchlist-content"
              id="watchlist-header">
              <VisibilityRounded sx={{ color: '#707070' }} />
              <Typography sx={{ fontWeight: '600', marginLeft: '10px' }}>Filter by watchlists</Typography>
            </AccordionSummary>
          </Accordion>
        </Grid>
        <Grid item xs={3}>
          <Accordion expanded={expanded === 'personalize'} onChange={handleAccordionChange('personalize')}>
            <AccordionSummary className="filters-toolbar-header" expandIcon={<ExpandMoreIcon />}
              aria-controls="personalize-content"
              id="personalize-header">
              <AccountCircleRounded sx={{ color: '#707070' }} />
              <Typography sx={{ fontWeight: '600', marginLeft: '10px' }}>Personalize</Typography>
            </AccordionSummary>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <AccordionDetails className="accordion-details">
            <Box sx={{ mt: 2 }}>
              <Box sx={{ width: '100%', p: 2 }} style={expanded === 'range' ? {} : { display: 'none' }}>
                <Paper elevation={6} sx={{ padding: '5px' }}>
                  <Box className="accordion-details-elem">
                    <GridFilterPanel
                      sx={{
                        '& .MuiDataGrid-panelFooter': {
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center', // Center-align the items
                          gap: '28px', // 28px spacing between items
                          padding: '8px', // Add padding if needed
                        },
                        '& .MuiButton-root': {
                          color: 'white',
                          width: 'max(160px, 20%)',
                          background: '#2196F3',
                          height: '30px',
                          borderRadius: '4px',
                          '& .MuiTypography-root': {
                            color: 'white',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '14px',
                          },
                        },
                      }}
                    />
                    <Box className="accordion-details-elem" display="flex" alignItems="center" justifyContent="space-between">
                      {/* Only Dividend Stocks Switch */}
                      {showOnlyDividendStocksButton && (
                        <FormControlLabel
                          control={(
                            <Switch
                              checked={onlyDividendStocks}
                              onChange={() => { setOnlyDividendStocks((oldValue) => !oldValue); }}
                            />
                          )}
                          label="Only dividend stocks"
                          sx={{ marginLeft: 'auto', marginRight: '28px' }} // Adjust the margin to position the switch
                        />
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ width: '100%', p: 2 }} style={expanded === 'markets' ? {} : { display: 'none' }}>
                <Paper elevation={6} sx={{ padding: '5px', overflow: 'visible' }}>
                  <Box className="accordion-details-elem">
                    {showExchangesTree && (
                      <TreeExchangeSelect
                        selectedExchanges={selectedExchanges}
                        selectedIndexes={selectedIndexes}
                        setSelectedExchanges={setSelectedExchanges}
                        setSelectedIndexes={setSelectedIndexes}
                        constituentsTreeData={constituentsTreeData}
                        setConstituentsTreeData={setConstituentsTreeData}
                        isLoading={isLoadingAllTableDataQuery}
                      />
                    )}
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ width: '100%', p: 2 }} style={expanded === 'watchlist' ? {} : { display: 'none' }}>
                <Paper elevation={6} sx={{ padding: '5px' }}>
                  <Box className="accordion-details-elem">
                    <WatchlistsSelect selectedWatchlists={selectedWatchlists}
                      setExpanded={setExpanded}
                      setSelectedWatchlists={setSelectedWatchlists}
                      watchlists={watchlists}
                      isLoading={isLoadingAllTableDataQuery || props.isLoading} />
                  </Box>
                </Paper>
              </Box>
              <Box sx={{ width: '100%', p: 2 }} style={expanded === 'personalize' ? {} : { display: 'none' }}>
                <Paper elevation={6} sx={{ padding: '5px' }}>
                  <Box className="accordion-details-elem">
                    {showGoalTextField
                      && (
                        <TextField
                          size="small"
                          inputProps={{ inputMode: 'numeric' }}
                          label="Goal"
                          value={dividendGoal}
                          onChange={handleChange}
                          helperText="Money you want earn annually from dividends"
                          className="goalItem"
                        />
                      )}
                  </Box>
                </Paper>
              </Box>
            </Box>
          </AccordionDetails>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          margin: '5px 10px',
          flexWrap: 'wrap',
          width: '100%'
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', flexWrap: 'wrap', alignItems: 'center' }}>
          <CustomStatesButtons
            displayCustomGridState={displayCustomGridState}
            setIsCustomStatesMenuOpen={setIsCustomStatesMenuOpen}
            setIsSaveButtonShown={setIsSaveButtonShown}
            isCustomStatesMenuOpen={isCustomStatesMenuOpen}
            isSaveButtonShown={isSaveButtonShown}
            setLastDisplayedConfiguration={setLastDisplayedConfiguration}
            lastDisplayedConfigurationLabel={lastDisplayedConfigurationLabel}
            getActualGridConfiguration={getActualGridConfiguration}
            setLastDisplayedConfigurationLabel={setLastDisplayedConfigurationLabel}
            screenerName={screenerName}
            actualInitialState={actualInitialState}
            autoSave={autoSave === 'true'}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', marginLeft: 'auto', marginRight: '100px' }}>
          <Stack direction={'row'} sx={{ width: '100%' }} spacing={4}>
            <Button sx={{ color: 'white', width: '160px', background: '#2196F3', height: '30px', borderRadius: '4px' }} variant='contained' startIcon={<DeleteIcon />} onClick={resetDataGridConfiguration} >
              <Typography sx={{ color: 'white', width: '100%', textAlign: 'center', fontSize: '14px' }} >
                Clear
              </Typography>
            </Button>
            <Button sx={{ color: 'white', width: '160px', background: '#2196F3', height: '30px', borderRadius: '4px', padding: '0' }} variant='contained'>
              <GridToolbarColumnsButton sx={{ width: '100%', height: '100%', color: 'white', paddingLeft: '30px', paddingRight: '30px' }} />
            </Button>
            {showShareButton && (
              <ShareButton
                getActualGridConfiguration={getActualGridConfiguration}
                screenerName={screenerName}
              />
            )}
          </Stack>
        </Box>
      </Box>
    </GridToolbarContainer>
  );
}
