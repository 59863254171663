import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';
import TransactionsGridService from '../../../../services/transactionsGridService';
import usePortfolioNames from '../../../../hooks/usePortfolioNames';
import { useState } from 'react';

interface AddToPortfolioDialogProps {
  symbol: string,
  isOpen: boolean,
  closePopup: () => void
}

export default function AddToPortfolioDialog({ isOpen, closePopup, symbol }: AddToPortfolioDialogProps) {
  const queryClient = useQueryClient();
  const [selectedPortfolio, setSelectedPortfolio] = useState<string>('');

  const { data: portfolios, isFetching } = usePortfolioNames();

  const postNewTransaction = useMutation((data: Object) => (
    TransactionsGridService.postNewTransaction(data)), {
    onMutate: ((variables: any) => {
      const updatedRow = { ...variables, isNew: false };
      return { updatedRow };
    }),
    onError: (() => {
      enqueueSnackbar('Entered data is incorrect', { variant: 'error' });
    }),
    onSuccess: ((data: { data: any; }, variables: any, context: any) => {
      queryClient.refetchQueries(['getAllPortfolioValues']);
      queryClient.refetchQueries(['getDividendValueHistory']);
      queryClient.refetchQueries(['getPortfolioValueBySectors']);
      queryClient.refetchQueries(['getPortfoliosValueByCompanies']);
      queryClient.refetchQueries(['getTransactionsData']);
      queryClient.refetchQueries(['getPortfolioNames']);
      enqueueSnackbar('New transaction has been saved succesfully');
    }),
  });

  const handleChange = (event: SelectChangeEvent<typeof selectedPortfolio>) => {
    const {
      target: { value },
    } = event;

    setSelectedPortfolio(value);
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-CA', { // Use 'en-CA' for YYYY-MM-DD format
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const formik = useFormik({
    initialValues: {
      date: formattedDate,
      quantity: 1,
      symbol,
      isNew: true,
      portfolioId: 0,
      id: -1,
    },
    validationSchema: Yup.object({
      quantity: Yup.number()
        .min(0, 'Quantity must be greater than 0')
        .required('Required'),
      date: Yup.date()
        .min('1900-01-01', 'Date must be greater than 1900-01-01')
        .max((new Date()).toDateString(), 'Date must be in the past')
        .required('Required'),
    }),
    onSubmit: (values) => {
      postNewTransaction.mutate(values);
      closePopup();
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={closePopup}
      PaperProps={{
        onSubmit: (event: any) => {
          event.preventDefault();
          closePopup();
        },
      }}
    >
      <DialogTitle>Add to portfolio</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter details to add transaction to your portfolio
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
            <FormLabel>Quantity</FormLabel>
            <TextField
              id="quantity"
              name="quantity"
              type="number"
              variant='standard'
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
            />
          </FormControl>
          <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
            <FormLabel>Date</FormLabel>
            <TextField
              id="date"
              name="date"
              type="date"
              variant='standard'
              value={formik.values.date}
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />
          </FormControl>
          <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
            <FormLabel>Portfolio</FormLabel>
            <Select
              input={<OutlinedInput label="Select portolio" />}
              id="portfolioId"
              name="portfolioId"
              type="string"
              variant='standard'
              value={formik.values.portfolioId}
              onChange={formik.handleChange}
              error={formik.touched.portfolioId && Boolean(formik.errors.portfolioId)}
            >
              {portfolios?.map((portfolio: any) => (
                <MenuItem key={portfolio.id} value={portfolio.id}>
                  <ListItemText primary={portfolio.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogActions>
            <Button onClick={closePopup}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
