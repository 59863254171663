import axios from 'axios';

const config = { headers: { 'Content-Type': 'application/json' } };

const postFeature = async (feature: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Support/feature`;
  const result = await axios.post(URL, feature, config);
  return result;
};

const postBugReport = async (bug: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Support/bug`;
  const result = await axios.post(URL, bug, config);
  return result;
};

const postContact = async (contact: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Support/contact`;
  const result = await axios.post(URL, contact, config);
  return result;
};

const postContactNoLoggedUser = async (contact: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Support/guestContact`;
  const result = await axios.post(URL, contact, config);
  return result;
};

const SupportService = {
  postFeature,
  postBugReport,
  postContact,
  postContactNoLoggedUser,
};

export default SupportService;
