import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import CustomCell from '../../../../components/analyticsGrid/CustomCell';

interface DividendsTableProps{
  dividendValueHistoryQuery: any,
  isFetching: boolean,
  isLoading: boolean,
}

export default function DividendsTable({
  dividendValueHistoryQuery, isFetching, isLoading,
}: DividendsTableProps) {
  const [rows, setRows] = useState();
  useEffect(() => {
    if (dividendValueHistoryQuery) {
      const newRows: any = [];
      dividendValueHistoryQuery.forEach((stock: any) => {
        stock.data.forEach((coordinates: any) => {
          if (stock.id !== 'SUMMED') {
            newRows.push({
              symbol: stock.id,
              date: new Date(coordinates.x),
              value: coordinates.y,
            });
          }
        });
      });
      setRows(() => newRows);
    }
  }, [dividendValueHistoryQuery]);
  const columns : GridColDef[] = [
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 1,
      resizable: true,
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      resizable: true,
      type: 'date',
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      resizable: true,
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
  ];
  return (
    <DataGridPro
      columns={columns}
      rows={rows || []}
      density="standard"
      getRowId={(row) => `${row.symbol}-${row.date}`}
      initialState={{
        sorting: {
          sortModel: [{ field: 'date', sort: 'desc' }],
        },
      }}
    />
  );
}
