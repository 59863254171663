import {
  Box, Typography, Tooltip,
} from '@mui/material';
import useKeyTakeawaysStyles from '../useKeyTakeawaysStyles';
import './Badge.css';

interface BadgePropsModel{
  badgeSrc: string,
  label: string,
  description: string
}

export default function Badge({
  badgeSrc, label, description,
}: BadgePropsModel) {
  const classes = useKeyTakeawaysStyles();
  return (
    <Box>
      <div>
        <Tooltip title={description} placement="right" arrow classes={{ tooltip: classes.keyTakeawaysTooltip }}>
          <img src={badgeSrc} alt={label} className="stock-badge" />
        </Tooltip>
      </div>
    </Box>
  );
}
