import { Box, List } from '@mui/material';
import DRAWER_LIST from '../constants/DrawerList';
import { DrawerItem } from '../DrawerItem';
import { MenuItem } from './MenuItem';
import './MenuItemList.css';

export default function MenuItemsList() {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%',
    }}
    >
      <List className="list">
        {DRAWER_LIST.UP.map(({
          literal, route, routePrefix, Icon, submenu,
        }: DrawerItem) => (
          <MenuItem
            Icon={Icon}
            literal={literal}
            route={route}
            routePrefix={routePrefix}
            key={literal}
            submenu={submenu}
          />
        ))}
      </List>
      <List className="list">
        {DRAWER_LIST.DOWN.map(({
          literal, route, Icon, submenu,
        }: DrawerItem) => (
          <MenuItem
            Icon={Icon}
            literal={literal}
            route={route}
            key={literal}
            submenu={submenu}
            expandUp
          />
        ))}
      </List>
    </Box>
  );
}
