import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import React from 'react';

interface NewViewDialogType{
  newLabel: string,
  setNewLabel: React.Dispatch<React.SetStateAction<string>>,
  handleSaveNewState: () => void,
  isAddingView: boolean,
  setIsAddingView: React.Dispatch<React.SetStateAction<boolean>>
}

export default function NewViewDialog({
  newLabel, setNewLabel, handleSaveNewState, isAddingView, setIsAddingView,
}: NewViewDialogType) {
  function onLabelChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setNewLabel(e.target.value);
  }

  return (
    <Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
      <DialogTitle>New custom view</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          value={newLabel}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onLabelChange(e);
          }}
          margin="dense"
          size="small"
          label="Custom view label"
          variant="standard"
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button type="button" onClick={() => setIsAddingView(false)}>
          Cancel
        </Button>
        <Button type="submit" onClick={() => handleSaveNewState()}>
          Create view
        </Button>
      </DialogActions>
    </Dialog>
  );
}
