import { Box, Paper, Typography } from '@mui/material';
import useGlobalStyles from '../../../styles/useGlobalStyles';

export default function StockUnauthorizedAdvancedKeyTakeaways() {
  const globalClasses = useGlobalStyles();

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          padding: '5px 0px 5px 0',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
        variant="h6"
        className={globalClasses.header}
      >
        Key Takeaways
      </Typography>
      <Box sx={{ width: '100%', minHeight: '150px', flexGrow: 1 }} className="center-items">
        Sign in to see the content
      </Box>
    </Paper>
  );
}
