import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './DisplayPDF.css';

interface DisplayPDFprops{
  document: any
}

export default function DisplayPDF({
  document,
}: DisplayPDFprops) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () => setPageNumber(
    pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
  );
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '0', height: '100%',
    }}
    >
      <Box sx={{
        flexGrow: 1, display: 'flex', alignItems: 'center',
      }}
      >
        <Document
          file={document}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </Box>
      <Box sx={{
        display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center',
      }}
      >
        <Button onClick={goToPrevPage}>Prev</Button>
        <Button onClick={goToNextPage}>Next</Button>
        <Typography>
          Page
          {' '}
          {pageNumber}
          {' '}
          of
          {' '}
          {numPages}
        </Typography>
      </Box>
    </Box>
  );
}
