import React, {
  createContext, useState, useContext, useMemo,
} from 'react';

type DialogContextType = {
  isDialogOpened: boolean;
  setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
  dialogText: string;
  setDialogText: React.Dispatch<React.SetStateAction<string>>;
};

type DialogContextProviderProps = {
  children: React.ReactNode;
};

const DialogContext = createContext<DialogContextType | undefined>(undefined);

function DialogContextProvider({ children }: DialogContextProviderProps) {
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [dialogText, setDialogText] = useState<string>('');

  const value = useMemo(() => ({
    isDialogOpened, setIsDialogOpened, dialogText, setDialogText,
  }), [isDialogOpened, dialogText]);

  return (
    <DialogContext.Provider value={value}>
      {children}
    </DialogContext.Provider>
  );
}

const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error(
      'useDialogContext must be used within a DialogContextProvider',
    );
  }
  return context;
};

export { DialogContextProvider, useDialogContext };
