import { Box, Paper, Theme, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PortfolioAnalysisService from '../../../../services/portfolioAnalysisService';
import MainLinearChart from '../../../../components/charts/MainLinearChart';
import useGlobalStyles from '../../../../styles/useGlobalStyles';

interface Props{
  selectedPortfolioIds: number[]
}

export default function ResponsiveLinePortfolioValue({selectedPortfolioIds}: Props) {
  const { data: allPortfolioValuesQuery, isFetching, isLoading } = useQuery(['getAllPortfolioValues', selectedPortfolioIds], () => PortfolioAnalysisService.getAllPortfolioValues(selectedPortfolioIds));
  const globalClasses = useGlobalStyles();

  return (
    <Paper
      sx={{
        height: '680px', width: '100%', padding: '20px', display: 'flex', flexDirection: 'column',
      }}
    >
      <Typography variant="h1" sx={{ padding: '10px 0 10px 0', width: '100%', fontSize: '1.5rem', fontWeight: '400' }} 
      className={globalClasses.header}>
        Portfolio Value Analysis
      </Typography>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <MainLinearChart
          data={allPortfolioValuesQuery}
          title="Portfolio Value"
          step={false}
          isLoading={isFetching || isLoading}
          showTitle={false}
        />
      </Box>
    </Paper>
  );
}