import React, {
    createContext, useState, useContext, useMemo,
  } from 'react';
  
  type FreeResourcesExceededDialogContextType = {
    isDialogOpened: boolean;
    setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
    dialogText: string;
    setDialogText: React.Dispatch<React.SetStateAction<string>>;
  };
  
  type FreeResourcesExceededDialogContextProviderProps = {
    children: React.ReactNode;
  };
  
  const DialogContext = createContext<FreeResourcesExceededDialogContextType | undefined>(undefined);
  
  function FreeResourcesExceededDialogContextProvider({ children }: FreeResourcesExceededDialogContextProviderProps) {
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
    const [dialogText, setDialogText] = useState<string>('');
  
    const value = useMemo(() => ({
      isDialogOpened, setIsDialogOpened, dialogText, setDialogText,
    }), [isDialogOpened, dialogText]);
  
    return (
      <DialogContext.Provider value={value}>
        {children}
      </DialogContext.Provider>
    );
  }
  
  const useFreeResourcesExceededDialogContext = () => {
    const context = useContext(DialogContext);
    if (context === undefined) {
      throw new Error(
        'useFreeResourcesExceededDialogContext must be used within a FreeResourcesExceededDialogContextProvider',
      );
    }
    return context;
  };
  
  export { FreeResourcesExceededDialogContextProvider, useFreeResourcesExceededDialogContext };
  