import { Theme } from '@mui/material/styles';
import _ from 'lodash';
import React from 'react';
import Humanize from './humanize';

const day = 1000 * 60 * 60 * 24;

const timeRangeTypesInMiliseconds: Record<string, number> = {
  '1day': day,
  '5day': day * 5,
  '30day': day * 30,
  '3month': day * 90,
  '6month': day * 182,
  '1year': day * 365,
  '5year': 5 * day * 365,
  all: Infinity,
};

function calculateStartEndZoom(
  data: any[],
  timeRange: string,
  setZoomStart: React.Dispatch<React.SetStateAction<number>>,
  setZoomEnd: React.Dispatch<React.SetStateAction<number>>,
) {
  const timeToSetInMs = timeRangeTypesInMiliseconds[timeRange];
  const zoomStartDateInMs = new Date().getTime() - timeToSetInMs;
  const newStart = 0;
  let newEndIndex = 0;
  data.forEach((elem, index) => {
    if (Date.parse(elem.date) >= zoomStartDateInMs) {
      newEndIndex = index;
    }
  });
  const newEnd = (newEndIndex / data.length) * 100;
  setZoomStart(newStart);
  setZoomEnd(newEnd);
  return {
    newStart, newEnd,
  };
}

const getChartsGlobalOption = (theme: Theme, customOption: any, yValueSufix: string = '$') => {
  const globalChartOption = {
    title: {
      textStyle: {
        color: theme.palette.text.primary,
      },
    },
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      textStyle: {
        color: theme.palette.text.primary,
      },
      valueFormatter(value: string | Array<any> | null) {
        if (!value) {
          return '-';
        }
        return `${Humanize.compactNumber(Number(value), 3)} ${yValueSufix}`;
      },
    },
    legend: {
      textStyle: {
        color: theme.palette.text.primary,
      },
    },
  };
  return _.merge(globalChartOption, customOption);
};

function renderJapaneseItem(params: any, api: any) {
  const xValue = api.value(0);
  const openPoint = api.coord([xValue, api.value('open')]);
  const closePoint = api.coord([xValue, api.value('close')]);
  const lowPoint = api.coord([xValue, api.value('low')]);
  const highPoint = api.coord([xValue, api.value('high')]);
  const halfWidth = api.size([1, 0])[0] * 0.35;
  const color = api.value('close') >= api.value('open')
    ? 'green'
    : 'red';
  const style = api.style({
    stroke: color,
  });
  return {
    type: 'group',
    children: [
      {
        type: 'line',
        shape: {
          x1: lowPoint[0],
          y1: lowPoint[1],
          x2: highPoint[0],
          y2: highPoint[1],
        },
        style,
      },
      {
        type: 'line',
        shape: {
          x1: openPoint[0],
          y1: openPoint[1],
          x2: openPoint[0] - halfWidth,
          y2: openPoint[1],
        },
        style,
      },
      {
        type: 'line',
        shape: {
          x1: closePoint[0],
          y1: closePoint[1],
          x2: closePoint[0] + halfWidth,
          y2: closePoint[1],
        },
        style,
      },
    ],
  };
}
function getCandleSeriesConfigByChartType(type: string, symbol: string) {
  if (type === 'candlestick') {
    return ([
      {
        type: 'candlestick',
        name: `${symbol} OHLC`,
        itemStyle: {
          color: 'green',
          color0: 'red',
          borderColor: 'green',
          borderColor0: 'red',
        },
        encode: {
          x: 'date',
          y: ['open', 'close', 'high', 'low'],
          tooltip: ['open', 'close', 'high', 'low'],
        },
      },
    ]);
  } if (type === 'linear') {
    return ([
      {
        type: 'line',
        name: `${symbol}`,
        showSymbol: false,
        encode: {
          x: 'date',
          y: 'close',
          tooltip: 'close',
        },
      },
    ]);
  }
  if (type === 'japanese') {
    return ([
      {
        type: 'custom',
        name: `${symbol} OHLC`,
        renderItem: renderJapaneseItem,
        encode: {
          x: 'date',
          y: ['open', 'close', 'high', 'low'],
          tooltip: ['open', 'close', 'high', 'low'],
        },
      },
    ]);
  }
  return null;
}

const getMarkerByColor = (color: String) => `<span style='display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${color};margin-right:5px;'></span>`;

const formatterOHLCTooltip = (args: any) => {
  let result = `${args[0].value.date}</br>`;
  const markerColor = args[0].value.close >= args[0].value.open
    ? 'green'
    : 'red';
  const marker = getMarkerByColor(markerColor);
  result += `${marker} open: ${args[0].value.open}$ </br>`;
  result += `${marker} close: ${args[0].value.close}$ </br>`;
  result += `${marker} highest: ${args[0].value.high}$ </br>`;
  result += `${marker} lowest: ${args[0].value.low}$ </br>`;
  return result;
};

function getCandleChartOption(
  theme: Theme,
  datasetSource: any[],
  startZoom: number,
  endZoom: number,
  yValueSufix: string,
  chartType: string,
  symbol: string,
) {
  let candleChartOption = {
    dataset: {
      source: datasetSource,
    },
    xAxis: {
      axisLabel: {
        color: theme.palette.text.primary,
      },
      type: 'category',
      inverse: true,
    },
    yAxis: {
      scale: true,
      axisLabel: {
        color: theme.palette.text.primary,
        formatter(value: string) {
          return `${Humanize.compactNumber(Number(value), 1)} ${yValueSufix}`;
        },
      },
    },
    tooltip: {
      trigger: 'axis',
      ...(chartType !== 'linear' && { formatter: formatterOHLCTooltip }),
    },
    legend: {
      show: false,
    },
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0, 1],
        start: startZoom,
        end: endZoom,
      },
      {
        show: true,
        xAxisIndex: [0, 1],
        type: 'slider',
        bottom: 10,
        start: startZoom,
        end: endZoom,
      },
    ],
    series: getCandleSeriesConfigByChartType(chartType, symbol),
  };
  candleChartOption = getChartsGlobalOption(theme, candleChartOption, yValueSufix);
  return candleChartOption;
}

function getLineChartOption(theme: Theme, customOption: any, yValueSufix: string = '') {
  let lineChartOption = {
    xAxis: {
      axisLabel: {
        color: theme.palette.text.primary,
      },
    },
    yAxis: [{
      alignTicks: true,
      splitLine: {
        show: false
      },
      scale: true,
      axisLabel: {
        color: theme.palette.text.primary,
        formatter(value: string) {
          return `${Humanize.compactNumber(Number(value), 1)} ${yValueSufix}`;
        },
      },
    }, {
      alignTicks: true,
      scale: true,
      axisLabel: {
        color: theme.palette.text.primary,
        formatter(value: string) {
          return `${Humanize.compactNumber(Number(value), 1)} ${yValueSufix}`;
        },
      },
    }],
    tooltip: {
      trigger: 'axis',
    },
  };
  lineChartOption = getChartsGlobalOption(theme, lineChartOption, yValueSufix);
  return _.merge(lineChartOption, customOption);
}

function getPieChartOption(theme: Theme, customOption: any, yValueSufix: string = '') {
  let pieChartOption = {
  };
  pieChartOption = getChartsGlobalOption(theme, pieChartOption, yValueSufix);
  return _.merge(pieChartOption, customOption);
}

const ChartServise = {
  calculateStartEndZoom,
  getLineChartOption,
  getCandleChartOption,
  getPieChartOption,
};

export default ChartServise;
