import { Box } from '@mui/material';
import { useState } from 'react';
import useCompanyKeyMetrics from '../../../hooks/useCompanyKeyMetrics';
import StatisticsChart from './components/StatisticsChart';
import StatisticsGrid from './components/StatisticsGrid';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

interface InteractiveGridPropsModel{
  symbol: string
}

const rowLabels = {
  revenuePerShare: 'Revenue Per Share',
  netIncomePerShare: 'Net Income Per Share',
  operatingCashFlowPerShare: 'Operating Cash Flow Per Share',
  freeCashFlowPerShare: 'Free Cash Flow Per Share',
  cashPerShare: 'Cash Per Share',
  bookValuePerShare: 'Book Value Per Share',
  tangibleBookValuePerShare: 'Tangible Book Value Per Share',
  shareholdersEquityPerShare: 'Shareholders Equity Per Share',
  interestDebtPerShare: 'Interest Debt Per Share',
  marketCap: 'Market Cap',
  enterpriseValue: 'Enterprise Value',
  peRatio: 'PE Ratio',
  priceToSalesRatio: 'Price To Sales Ratio',
  pocfratio: 'Pocfratio',
  pfcfRatio: 'PfcfRatio',
  pbRatio: 'PB Ratio',
  ptbRatio: 'PTB Ratio',
  evToSales: 'EV To Sales',
  enterpriseValueOverEBITDA: 'Enterprise Value Over EBITDA',
  evToOperatingCashFlow: 'EV To Operating Cash Flow',
  evToFreeCashFlow: 'EV To Free Cash Flow',
  earningsYield: 'Earnings Yield',
  freeCashFlowYield: 'Free Cash Flow Yield',
  debtToEquity: 'Debt To Equity',
  debtToAssets: 'Debt To Assets',
  netDebtToEBITDA: 'Net Debt To EBITDA',
  currentRatio: 'Current Ratio',
  interestCoverage: 'Interest Coverage',
  incomeQuality: 'Income Quality',
  payoutRatio: 'Payout Ratio',
  salesGeneralAndAdministrativeToRevenue: 'Sales General And Administrative To Revenue',
  researchAndDdevelopementToRevenue: 'Research And Ddevelopement To Revenue',
  intangiblesToTotalAssets: 'Intangibles To Total Assets',
  capexToOperatingCashFlow: 'Capex To Operating Cash Flow',
  capexToRevenue: 'Capex To Revenue',
  capexToDepreciation: 'Capex To Depreciation',
  stockBasedCompensationToRevenue: 'Stock Based Compensation To Revenue',
  grahamNumber: 'Graham Number',
  roic: 'Roic',
  returnOnTangibleAssets: 'Return On Tangible Assets',
  grahamNetNet: 'Graham Net Net',
  workingCapital: 'Working Capital',
  tangibleAssetValue: 'Tangible Asset Value',
  netCurrentAssetValue: 'Net Current Asset Value',
  investedCapital: 'Invested Capital',
  averageReceivables: 'Average Receivables',
  averagePayables: 'Average Payables',
  averageInventory: 'Average Inventory',
  daysSalesOutstanding: 'Days Sales Outstanding',
  daysPayablesOutstanding: 'Days Payables Outstanding',
  daysOfInventoryOnHand: 'Days Of Inventory On Hand',
  receivablesTurnover: 'Receivables Turnover',
  payablesTurnover: 'Payables Turnover',
  inventoryTurnover: 'Inventory Turnover',
  roe: 'Roe',
  capexPerShare: 'Capex Per Share',
  dividendYield: 'Dividend Yield',
};

export default function InteractiveGrid({ symbol }: InteractiveGridPropsModel) {
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([
    'revenuePerShare',
    'netIncomePerShare',
    'cashPerShare',
  ]);

  const {
    data: companyKeyMetrics,
    isLoading: isLoadingCompanyKeyMetrics,
  } = useCompanyKeyMetrics(symbol);

  return (
    <Box sx={{ width: '100%', gap: '20px' }} className="center-items">
      <StatisticsChart
        selectedRows={selectedRows as string[]}
        data={companyKeyMetrics}
        isLoading={isLoadingCompanyKeyMetrics}
        rowLabels={rowLabels}
      />
      <StatisticsGrid
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        data={companyKeyMetrics}
        rowLabels={rowLabels}
      />
    </Box>
  );
}
