import { Box, Checkbox, Chip, Divider, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ChipsNavigator from '../../../../components/ChipsNavigator';
import React from 'react';
import { PortfolioNameDto } from './PortfolioNameDto';

interface HeaderPropsModel {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    menuItems: string[];
    portfolios: PortfolioNameDto[];
    setSelectedPortfolioIds: Dispatch<SetStateAction<number[]>>
}

export default function Header({
    value,
    setValue,
    menuItems,
    portfolios: portfolios,
    setSelectedPortfolioIds
}: HeaderPropsModel) {

    const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        if (portfolios && portfolios.length > 0) {
            const preselectedPortfolios = [portfolios[0].name];
            handleSelectedPortfoliosChange(preselectedPortfolios);
        }
    }, [portfolios])


    const handleChange = (event: SelectChangeEvent<typeof selectedPortfolios>) => {
        const {
            target: { value },
        } = event;

        const selectedPortfolioNames = typeof value === 'string' ? value.split(',') : value;
        handleSelectedPortfoliosChange(selectedPortfolioNames);
    };

    const handleSelectedPortfoliosChange = (names: string[]) => {
        setSelectedPortfolios(
            // On autofill we get a stringified value.
            names,
        );

        const selectedPortfolioIds = portfolios
            .filter(portfolio => names.includes(portfolio.name))
            .map(portfolio => portfolio.id);

        setSelectedPortfolioIds(selectedPortfolioIds);
    }

    const headerLabel = 'Portfolio Analysis';

    return (
        <Stack direction={'row'}>
            <Stack direction={'column'}>
                <Box
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        width: '100%',
                        minHeight: '80px',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 10px 0 10px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '10px', flexGrow: 10 }}>
                        <Typography sx={{ alignSelf: 'center', fontSize: '36px', fontWeight: '500', marginLeft: '25px', marginTop: '25px' }}>
                            {headerLabel}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ zIndex: '4' }}>
                    <ChipsNavigator value={value} setValue={setValue} menuItems={menuItems} />
                </Box>
            </Stack>
            <Box justifyContent={'flex-end'} style={{ marginLeft: 'auto', marginRight: '10%', marginTop: '3%' }} sx={{ zIndex: '4'}}>
                <Paper sx={{ width: '100%', height: '100%', padding: '10px'}}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Portfolios</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={selectedPortfolios}
                            onChange={handleChange}
                            input={<OutlinedInput label="Portfolios" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} variant="outlined" style={{ backgroundColor: '#F9F9F9'}}/>
                                  ))}
                                </Box>
                              )}                            MenuProps={MenuProps}
                        >
                            {portfolios?.map((portfolio, i) => (
                                <MenuItem key={portfolio.name} value={portfolio.name}>
                                    <Stack direction="column" sx={{ width: '100%' }}>
                                        <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
                                            <ListItemText primary={portfolio.name} />
                                            <Checkbox
                                                checked={selectedPortfolios.indexOf(portfolio.name) > -1}
                                            />
                                        </Stack>
                                        {i + 1 !== portfolios.length && <Divider sx={{ width: '100%' }} />}
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Paper>
            </Box>
        </Stack>
    );
}
