import { useIsAuthenticated } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import StockService from '../services/stockService';

export default function useIgnoredStocks() {
  const isAuthenticated = useIsAuthenticated();
  return useQuery(['getIgnoredStocks'], () => StockService.getIgnoredStocks(), {
    enabled: isAuthenticated,
    placeholderData: [],
  });
}
