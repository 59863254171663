import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import {
  Button, Popover, Typography, Box, Paper,
} from '@mui/material';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import ROUTES from '../constants/routes';
import StockQuickKeyTakeaways from './KeyTakeaways/StockQuickKeyTakeaways/StockQuickKeyTakeaways';
import StockUnauthorizedKeyTakeaways from './KeyTakeaways/StockQuickKeyTakeaways/StockUnauthorizedQuickKeyTakeaways copy';
import StockAdvancedKeyTakeaways from './KeyTakeaways/StockAdvancedKeyTakeaways/StockAdvancedKeyTakeaways';

interface StockQuickViewCellButtonProps {
  name: string | undefined | any;
  columnWidth: number | undefined;
  symbol: string;
}

export default function StockQuickViewCellButton(props: StockQuickViewCellButtonProps) {
  const { name, columnWidth, symbol } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [xTransform, setXTransform] = useState(0);
  const [keyTakeawaysIsLoading, setKeyTakeawaysIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState<any>(null);

  function handleMouseEnter() {
    setDelayHandler(
      setTimeout(() => {
        setOpen(true);
      }, 500),
    );
  }

  function handleMouseLeave() {
    clearTimeout(delayHandler);
    setOpen(false);
  }

  function handleStockButtonClick() {
    window.open(ROUTES.stockDetailedData(symbol), '_blank');
    window.scrollTo(0, 0);
  }

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setAnchorEl(buttonRef.current);
  }, []);

  useEffect(() => {
    if (!buttonRef.current?.clientWidth) return;
    if (!columnWidth) {
      setXTransform(buttonRef.current?.clientWidth);
    } else if (columnWidth - 10 > buttonRef.current.clientWidth) {
      setXTransform(buttonRef.current.clientWidth);
    } else {
      setXTransform(columnWidth - 10);
    }
  }, [columnWidth]);

  const cellButton = useMemo(
    () => (
      <Button
        sx={{
          maxWidth: '100%',
          height: '100%',
          textAlign: 'left',
          alignItems: 'center',
        }}
        variant="text"
        ref={buttonRef}
        onClick={() => handleStockButtonClick()}
      >
        <Typography sx={{ overflow: 'hidden' }} variant="inherit">
          {name}
        </Typography>
      </Button>
    ),
    [],
  );

  if (!symbol) {
    return null;
  }

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {cellButton}
        <div>
          <Popover
            id={name}
            key={JSON.stringify([name, keyTakeawaysIsLoading])}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              transform: `translateX(${xTransform}px)`,
              margin: 0,
              padding: 0,
              pointerEvents: 'none',
            }}
            disableEnforceFocus
          >
            <Paper
              sx={{
                width: '700px',
                height: 'fit-content',
                margin: 0,
                padding: 0,
                pointerEvents: 'auto',
              }}
            >
              <AuthenticatedTemplate>
                <StockAdvancedKeyTakeaways
                  symbol={symbol}
                  paperHeight='400px'
                />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <StockUnauthorizedKeyTakeaways />
              </UnauthenticatedTemplate>
            </Paper>
          </Popover>
        </div>
      </div>
    </Box>
  );
}
