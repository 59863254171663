import { Gavel } from '@mui/icons-material';
import {
  Button, Link,
  IconButton, Menu, MenuItem, Theme, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/styles';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import ROUTES from '../../constants/routes';

export default function AuthorizedUserMenu() {
  const navigate = useNavigate();

  const [anchorDocEl, setAnchorDocEl] = useState<null | HTMLElement>(null);

  const theme: Theme = useTheme();

  const handleDocMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDocEl(event.currentTarget);
  };

  const handleDocClose = () => {
    setAnchorDocEl(null);
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="documents"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleDocMenu}
        color="inherit"
        style={{ float: 'left', borderRadius: '0px' }}
      >
        <Gavel />
        <Typography sx={{ overflow: 'hidden', fontWeight: '600' }} variant="body2">
          Documents
        </Typography>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorDocEl}
        style={{ zIndex: theme.zIndex.tooltip, float: 'left' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorDocEl)}
        onClose={handleDocClose}
      >
        <MenuItem aria-label="Privacy policy document" onClick={() => navigate(ROUTES.documents.PrivacyPolicy)}>
          <Link sx={{ color: theme.palette.text.primary }} to={ROUTES.documents.PrivacyPolicy} underline="none" component={RouterLink} title="Privacy policy document">
            Privacy policy
          </Link>
        </MenuItem>
        <MenuItem aria-label="Cookie policy document" onClick={() => navigate(ROUTES.documents.CookiePolicy)}>
          <Link sx={{ color: theme.palette.text.primary }} to={ROUTES.documents.CookiePolicy} underline="none" component={RouterLink} title="Cookie policy document">
            Cookie policy
          </Link>
        </MenuItem>
        <MenuItem aria-label="Terms and conditions document" onClick={() => navigate(ROUTES.documents.TermsAndConditions)}>
          <Link sx={{ color: theme.palette.text.primary }} to={ROUTES.documents.TermsAndConditions} underline="none" component={RouterLink} title="Terms and Conditions document">
            Terms and Conditions
          </Link>
        </MenuItem>
        <MenuItem aria-label="Terms and conditions for paid services document" onClick={() => navigate(ROUTES.documents.TermsAndConditionsForPaid)}>
          <Link sx={{ color: theme.palette.text.primary }} to={ROUTES.documents.TermsAndConditionsForPaid} underline="none" component={RouterLink} title="Terms and Conditions for Paid Services document">
            Terms and Conditions for Paid Services
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
