import { Paper } from '@mui/material';
import {
  DataGridPro, GridRowSelectionModel, GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import Humanize from '../../../../../../../services/humanize';

interface StatisticsGridPropsModel{
  selectedRows: GridRowSelectionModel,
  setSelectedRows: Dispatch<SetStateAction<GridRowSelectionModel>>,
  data: any,
  rowLabels: Record<string, string>,
}

export default function StatisticsGrid({
  selectedRows,
  setSelectedRows,
  data,
  rowLabels,
}: StatisticsGridPropsModel) {
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const newGridData: any = {};
      let newColumns: any[] = [{
        field: 'name',
        headerName: '',
        flex: 0,
        resizable: true,
        width: 200,
        valueFormatter: (params: GridValueFormatterParams<string>) => (
          rowLabels[params.value]
        ),
      }];
      [...data].reverse().forEach((row: Record<string, number | string>) => {
        Object.entries(rowLabels).forEach(([key]: [string, string]) => {
          if (row[key]) {
            newGridData[key] = {
              ...newGridData[key],
              [row.calendarYear as string]: (row[key] as number).toFixed(2),
            };
          }
        });
        newColumns = [...newColumns, {
          field: row.calendarYear,
          headerName: row.calendarYear,
          flex: 1,
          resizable: false,
          sortable: false,
          hidable: false,
          filterable: false,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams<number>) => (
            Humanize.compactNumber(Number(params.value), 3)
          ),
        }];
      });
      setColumns(() => newColumns);
      const newRows: any[] = [];
      Object.entries(newGridData).forEach(([key, value]: [string, any]) => {
        newRows.push({ name: key, ...value });
      });
      setRows(newRows);
    }
  }, [data]);

  return (
    <Paper sx={{ width: '100%' }} className="center-items">
      <DataGridPro
        rows={rows}
        columns={columns}
        getRowId={(row) => row.name}
        density="compact"
        autoHeight
        checkboxSelection
        onRowSelectionModelChange={(newModel) => {
          setSelectedRows(() => newModel);
        }}
        rowSelectionModel={selectedRows}
        keepNonExistentRowsSelected
      />
    </Paper>
  );
}
