import { AccountCircle, Gavel } from '@mui/icons-material';
import {
  IconButton, Menu, MenuItem, Theme, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/styles';
import { useMsal } from '@azure/msal-react';
import { useColorModeContext } from '../../contexts/ColorModeContext';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import DocumentsMenu from './DocumentsMenu';

export default function AuthorizedUserMenu() {
  const navigate = useNavigate();

  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null);
  const [anchorDocEl, setAnchorDocEl] = useState<null | HTMLElement>(null);

  const { instance } = useMsal();
  const theme: Theme = useTheme();
  const colorMode = useColorModeContext();

  const handleDocMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDocEl(event.currentTarget);
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorUserEl(null);
  };

  const handleDocClose = () => {
    setAnchorDocEl(null);
  };

  const handleSignOut = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    setAnchorUserEl(null);
  };

  const handleChangeColorTheme = () => {
    colorMode.toggleIsLightMode();
    setAnchorUserEl(null);
  };

  return (
    <div style={{position: 'relative'}}>
      <div style={{float: 'left'}}>
        <DocumentsMenu/>
      </div>
      <div style={{float: 'left'}}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleUserMenu}
          color="inherit"
          style={{float: 'left', borderRadius: '0px'}}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorUserEl}
          style={{ zIndex: theme.zIndex.tooltip, float: 'left' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorUserEl)}
          onClose={handleUserClose}
        >
          {/* <MenuItem onClick={handleChangeColorTheme}>Change theme</MenuItem> */}
          <MenuItem sx={{ minWidth: '300px' }} onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
