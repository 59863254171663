/* eslint-disable react/jsx-props-no-spreading */
import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { useDraggable } from 'react-use-draggable-scroll';
import './Swiper.css';

interface SwiperPropsModel{
  swiperHeight: string,
  horizontalScrollDistanceOnClick?: number,
}

export default function Swiper({
  swiperHeight, horizontalScrollDistanceOnClick = 280, children,
}: React.PropsWithChildren<SwiperPropsModel>) {
  const swiperRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const { events } = useDraggable(swiperRef);
  const theme = useTheme();

  function scrollSwiperHorizontal(scrollDistance: number) {
    swiperRef.current.scrollLeft += scrollDistance;
  }

  function onSwiperScroll() {
    const { scrollLeft } = swiperRef.current;
    const { clientWidth } = swiperRef.current;
    const { scrollWidth } = swiperRef.current;
    setShowLeftArrow(() => scrollLeft !== 0);
    setShowRightArrow(() => scrollLeft + clientWidth !== scrollWidth);
  }

  function getGradient(direction: string, color: string) {
    return `linear-gradient( to ${direction}, ${color} 0%,transparent 100% )`;
  }

  useEffect(() => {
    onSwiperScroll();
  }, [children]);

  return (
    <Box
      ref={swiperRef}
      {...events}
      onScroll={() => onSwiperScroll()}
      className="swiper"
      sx={{ height: swiperHeight}}
    >
      {children}
      {showLeftArrow && (
        <Box
          sx={{ left: '0', height: swiperHeight, backgroundImage: getGradient('right', theme.palette.background.paper) }}
          className="center-items swiper-arrow"
        >
          <NavigateBefore fontSize="large" onClick={() => { scrollSwiperHorizontal(-horizontalScrollDistanceOnClick); }} />
        </Box>
      )}
      {showRightArrow && (
        <Box
          sx={{ right: '0', height: swiperHeight, backgroundImage: getGradient('left', theme.palette.background.paper) }}
          className="center-items swiper-arrow"
        >
          <NavigateNext fontSize="large" onClick={() => { scrollSwiperHorizontal(horizontalScrollDistanceOnClick); }} />
        </Box>
      )}
    </Box>
  );
}
