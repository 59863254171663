import {
  Box, Button, MenuItem, IconButton, Typography,
  Divider,
} from '@mui/material';
import { SaveAs, Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIsAuthenticated } from '@azure/msal-react';
import { useSnackbar } from 'notistack';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import DataGridService from '../../../services/dataGridService';
import CustomGridStateDto from '../../../models/dtos/customGridStateDto';
import DataGridConfigurationModel from '../../../models/dataGridConfigurationModel';
import { useDialogContext } from '../../../contexts/DialogContext';
import PopperMenu from '../../Menu/PopperMenu';
import NewViewDialog from '../Dialog/NewViewDialog';
import NewViewConfirmation from '../Dialog/NewViewConfirmation';
import { useFreeResourcesExceededDialogContext } from '../../../contexts/FreeResourcesExceededDialogContext';

interface CustomStatesButtonsProps {
  // eslint-disable-next-line no-unused-vars
  displayCustomGridState: (newGridState: DataGridConfigurationModel, label: string) => void;
  setIsCustomStatesMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCustomStatesMenuOpen: boolean;
  isSaveButtonShown: boolean;
  setIsSaveButtonShown: React.Dispatch<React.SetStateAction<boolean>>;
  setLastDisplayedConfiguration: React.Dispatch<React.SetStateAction<DataGridConfigurationModel>>;
  lastDisplayedConfigurationLabel: string;
  getActualGridConfiguration: () => DataGridConfigurationModel;
  setLastDisplayedConfigurationLabel: React.Dispatch<React.SetStateAction<string>>;
  screenerName: string;
  actualInitialState: GridInitialStatePro;
  autoSave: boolean;
}

export default function
  CustomStatesButtons(props: CustomStatesButtonsProps) {
  const QueryClient = useQueryClient();
  const timerRef = useRef<any>(null);

  const isAuthenticated = useIsAuthenticated();

  const { enqueueSnackbar } = useSnackbar();
  const {
    displayCustomGridState,
    setIsCustomStatesMenuOpen,
    isCustomStatesMenuOpen,
    isSaveButtonShown,
    setIsSaveButtonShown,
    setLastDisplayedConfiguration,
    lastDisplayedConfigurationLabel,
    getActualGridConfiguration,
    setLastDisplayedConfigurationLabel,
    screenerName,
    actualInitialState,
    autoSave,
  } = props;
  const { data: customStatesQuery, isFetching: isFetchingCustomStates } = useQuery(
    ['getCustomStates', screenerName],
    () => DataGridService.getCustomGridStates(screenerName),
    {
      placeholderData: [],
      enabled: isAuthenticated,
    },
  );
  const [haveDefaultGridState, setHaveDefaultGridState] = useState<boolean>(false);
  async function handleDisplayCustomState(state: CustomGridStateDto) {
    const configuration: DataGridConfigurationModel = JSON.parse(state.configuration);
    displayCustomGridState(configuration, state.id);
    setIsSaveButtonShown(false);
  }

  useEffect(() => {
    if (!isFetchingCustomStates && autoSave) {
      // eslint-disable-next-line no-restricted-syntax
      for (const customState of customStatesQuery) {
        if (customState.id === 'default') {
          setHaveDefaultGridState(true);
          handleDisplayCustomState(customState);
          break;
        }
      }
    }
  }, [isFetchingCustomStates]);

  const postCustomGridStateByLabel = useMutation((data: CustomGridStateDto) => DataGridService.postCustomGridStateByLabel(data), {
    onError: ((error: any) => {
      if (error?.response?.status == 403) {
        freeResourcesExceededDialogContext.setDialogText('Pay money!');
        freeResourcesExceededDialogContext.setIsDialogOpened(true);
      }
      else {
        enqueueSnackbar('Configuration saving failed, try again.', { variant: 'error' });
      }
    }),
    onSuccess: ((data: { data: any; }, variables: any, context: any) => {
      const configuration = getActualGridConfiguration();
      const configurationJSON = JSON.stringify(configuration);
      const newState = [{ id: newLabel, configuration: configurationJSON } as CustomGridStateDto];
      QueryClient.setQueryData(
        ['getCustomStates', screenerName],
        [...customStatesQuery, ...newState],
      );
      setIsAddingView(false);
      setNewLabel('');
      setIsCustomStatesMenuOpen(false);
      setLastDisplayedConfiguration(configuration);
      setLastDisplayedConfigurationLabel(newLabel);
      enqueueSnackbar('New configuration has been saved succesfully');
    }),
  });
  const putCustomGridStateByLabel = useMutation((data: CustomGridStateDto) => DataGridService.putCustomGridStateByLabel(data));
  const deleteCustomGridStateByLabel = useMutation((label: string) => DataGridService.deleteCustomGridStateByLabel(screenerName, label));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAddingView, setIsAddingView] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [newLabel, setNewLabel] = useState(autoSave ? 'default' : '');
  const notLoggedUserDialogContext = useDialogContext();
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();

  function handleClickCustomStatesMenuButton(event: React.MouseEvent<HTMLElement>) {
    if (isAuthenticated) {
      setIsCustomStatesMenuOpen(true);
      setAnchorEl(event.currentTarget);
    } else {
      notLoggedUserDialogContext.setDialogText('Please sign in to use custom screens');
      notLoggedUserDialogContext.setIsDialogOpened(true);
    }
  }

  function handleClickSaveViewButton() {
    if (isAuthenticated) {
      setIsAddingView(true);
      setIsSaveButtonShown(false);
    } else {
      notLoggedUserDialogContext.setDialogText('Please to sign in to use custom screens');
      notLoggedUserDialogContext.setIsDialogOpened(true);
    }
  }

  function updateExistingView(labelToUpdate: string) {
    const configuration = getActualGridConfiguration();
    const configurationJSON = JSON.stringify(configuration);
    const putData = {
      id: labelToUpdate,
      configuration: configurationJSON,
      screenerName,
    };
    putCustomGridStateByLabel.mutate(putData);
    setLastDisplayedConfiguration(configuration);
    QueryClient.setQueryData(
      ['getCustomStates', screenerName],
      customStatesQuery.map((elem: CustomGridStateDto) => (elem.id !== labelToUpdate ? elem : putData)),
    );
    setLastDisplayedConfigurationLabel(labelToUpdate);
    setIsSaveButtonShown(false);
  }

  function handleDeleteState(deletedStateLabel: string) {
    QueryClient.setQueryData(
      ['getCustomStates', screenerName],
      customStatesQuery.filter((state: any) => state.id !== deletedStateLabel),
    );
    if (lastDisplayedConfigurationLabel === deletedStateLabel) {
      setLastDisplayedConfiguration({
        initialState: {},
        dividendGoal: -1,
        exchanges: [],
        indexes: [],
        showOnlyDividendStocks: false,
      });
    }
    deleteCustomGridStateByLabel.mutate(deletedStateLabel);
    if (deletedStateLabel === lastDisplayedConfigurationLabel) {
      setLastDisplayedConfigurationLabel('');
    }
  }

  function handleSaveNewState() {
    if (newLabel === '') {
      enqueueSnackbar('Custom screen must have name', { variant: 'error' });
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const customState of customStatesQuery) {
      if (customState.id === newLabel) {
        setIsAddingView(false);
        setConfirmationDialogIsOpen(true);
        return;
      }
    }
    const configuration = getActualGridConfiguration();
    const configurationJSON = JSON.stringify(configuration);

    const postData = {
      id: newLabel,
      configuration: configurationJSON,
      screenerName,
    };
    postCustomGridStateByLabel.mutate(postData);
  }

  useEffect(() => {
    if (autoSave) {
      timerRef.current = setTimeout(() => {
        setLastDisplayedConfiguration(getActualGridConfiguration());
        if (haveDefaultGridState) {
          updateExistingView('default');
        } else {
          handleSaveNewState();
          setHaveDefaultGridState(true);
        }
      }, 5000);
    }
    return () => clearTimeout(timerRef.current);
  }, [actualInitialState]);

  if (autoSave) {
    return (
      <Box className="center-items">
        {!isSaveButtonShown ? (
          <Typography variant="body1">Saved</Typography>
        ) : (
          <Typography variant="body1">Unsaved</Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ background: "rgba(240, 240, 240, 0.4)", width: '400px', height: '45px', marginLeft: '10px', marginRight: '40px', borderRadius: '8px' }}>
        <Button onClick={(event) => handleClickCustomStatesMenuButton(event)} sx={{ width: '100%', htight: '100%' }}>
          <Typography sx={{ width: '100%', textAlign: 'left', fontSize: '20px', fontWeight: '600', marginLeft: '15px', textTransform: 'none' }} >
            {typeof lastDisplayedConfigurationLabel == 'string' && lastDisplayedConfigurationLabel.length > 0 ? lastDisplayedConfigurationLabel : 'My screens'}
          </Typography>
        </Button>
      </Box>
      <PopperMenu
        anchorEl={anchorEl}
        open={isCustomStatesMenuOpen && customStatesQuery.length > 0}
        onClose={() => setIsCustomStatesMenuOpen(false)}
        sx={{ width: '400px'}}
      >
        {customStatesQuery
          && customStatesQuery.map((state: any, i: number, { length }: any) => (
            <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
              <MenuItem key={state.id} sx={{ margin: '0', padding: '0' }}>
                <Box
                  sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
                  onClick={() => handleDisplayCustomState(state)}
                >
                  <Typography
                    sx={{ marginLeft: '20px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {state.id}
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '15px' }}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      handleDeleteState(state.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </MenuItem>
              {i + 1 !== length && <Divider sx={{ width: '80%', margin: 'auto' }} />}
            </Box>
          ))}
      </PopperMenu>
      <Box sx={{ background: "rgba(240, 240, 240, 0.4)", height: '45px', marginLeft: '10px', marginRight: '10px', borderRadius: '8px' }}>

        {isSaveButtonShown && (
          <Button
            id="SaveButton"
            key="SaveButton"
            endIcon={<SaveAs />}
            sx={{ height: '100%'}}
            onClick={() => handleClickSaveViewButton()}
          >
            Save as
          </Button>
        )}
        {isSaveButtonShown && lastDisplayedConfigurationLabel && (
          <Button
            id="UpdateButton"
            key="UpdateButton"
            endIcon={<Save />}
            sx={{ height: '100%'}}
            onClick={() => updateExistingView(lastDisplayedConfigurationLabel)}
          >
            Save
          </Button>
        )}
      </Box>
      <NewViewDialog
        newLabel={newLabel}
        setNewLabel={setNewLabel}
        handleSaveNewState={() => handleSaveNewState()}
        isAddingView={isAddingView}
        setIsAddingView={setIsAddingView}
      />
      <NewViewConfirmation
        confirmationDialogIsOpen={confirmationDialogIsOpen}
        setConfirmationDialogIsOpen={setConfirmationDialogIsOpen}
        setIsAddingView={setIsAddingView}
        newLabel={newLabel}
        setNewLabel={setNewLabel}
        updateExistingView={(labelToUpdate: string) => updateExistingView(labelToUpdate)}
      />
    </>
  );
}
