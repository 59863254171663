import { LinearProgress, Paper } from '@mui/material';
import {
  DataGridPro, GridColDef, GridRowModel, GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import CompanyDividendDto from '../../../../../models/dtos/companyDividendDto';

interface DividendsGridPropsModel {
  dividendsData: CompanyDividendDto[];
  isLoadingDividendsData: boolean;
}

export default function DividendsGrid({
  dividendsData, isLoadingDividendsData,
}: DividendsGridPropsModel) {
  const [rows, setRows] = useState<GridRowModel[]>([]);

  function formatDate(date: GridValueFormatterParams<Date>): string {
    const dateThreshold = new Date('1900-01-01');
    const parsedDate = new Date(date.value);

    if (date.value == null || parsedDate < dateThreshold) {
      return 'No Data';
    }

    return parsedDate.toLocaleDateString();
  }

  useEffect(() => {
    if (dividendsData) {
      setRows(dividendsData);
    }
  }, [dividendsData]);

  const columns: GridColDef[] = [
    {
      field: 'date',
      type: 'date',
      headerName: 'Date',
      editable: false,
      valueFormatter: formatDate,
      flex: 1,
    },
    {
      field: 'recordDate',
      type: 'date',
      headerName: 'Record Date',
      editable: false,
      valueFormatter: formatDate,
      flex: 1,
    },
    {
      field: 'paymentDate',
      type: 'date',
      headerName: 'Payment Date',
      editable: false,
      valueFormatter: formatDate,
      flex: 1,
    },
    {
      field: 'declarationDate',
      type: 'date',
      headerName: 'Declaration Date',
      editable: false,
      valueFormatter: formatDate,
      flex: 1,
    },
    {
      field: 'dividend',
      type: 'number',
      headerName: 'Dividend',
      editable: false,
      flex: 1,
    },
  ];

  return (
    <Paper
      sx={{
        width: '90%',
        flexFlow: 'column',
        flex: '0 1 auto',
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        autoHeight
        density="compact"
        loading={isLoadingDividendsData}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        getRowId={(row) => row.date}
      />
    </Paper>
  );
}
