import {
  Box,
} from '@mui/material';
import React from 'react';
import ToolbarSelectForm from '../../../../../../../components/Toolbar/ToolbarSelectForm';
import ToolbarToggle from '../../../../../../../components/Toolbar/ToolbarToggle';

const intervalTypes = [
  '1min', '5min', '15min', '30min', '1hour', '4hour', '1day',
];

const chartTypes = [
  'candlestick', 'linear', 'japanese',
];

const timeRangeTypes = [
  '1day', '5day', '30day', '3month', '6month', '1year', 'all',
];

interface BasicPriceChartToolbarPropsModel{
    interval: string,
    setInterval: React.Dispatch<React.SetStateAction<string>>,
    chartType: string,
    setChartType: React.Dispatch<React.SetStateAction<string>>,
    timeRange: string,
    setTimeRange: React.Dispatch<React.SetStateAction<string>>,
}

export default function BasicPriceChartToolbar({
  interval, setInterval, chartType, setChartType, timeRange, setTimeRange,
}: BasicPriceChartToolbarPropsModel) {
  return (
    <Box sx={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
      <ToolbarSelectForm
        label="Interval"
        value={interval}
        setValue={setInterval}
        valuesToSelect={intervalTypes}
      />
      <ToolbarSelectForm
        label="Type"
        value={chartType}
        setValue={setChartType}
        valuesToSelect={chartTypes}
      />
      <ToolbarToggle
        value={timeRange}
        setValue={setTimeRange}
        valuesToSelect={timeRangeTypes}
      />
    </Box>
  );
}
