import { Box, Dialog, Typography } from '@mui/material';
import React from 'react';
import useGlobalStyles from '../../styles/useGlobalStyles';

interface NotLoggedUserPropsModel{
    isDialogOpened: boolean,
    setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>,
    dialogText: string
}

export default function NotLoggedUserDialog({
  isDialogOpened,
  setIsDialogOpened, dialogText,
}: NotLoggedUserPropsModel) {
  const globalClasses = useGlobalStyles();

  return (
    <Dialog open={isDialogOpened} onClose={() => setIsDialogOpened(false)}>
      <Box sx={{
        width: '400px', height: '400px', maxWidth: '80vw', minWidth: '320px',
      }}
      >
        <Box sx={{ paddingTop: '5px', paddingBottom: '5px' }} className={globalClasses.header}>
          <Typography variant="h3" className="center-items">
            Please sign in
          </Typography>
        </Box>
        <Box sx={{ height: '80%' }} className="center-items">
          <Typography variant="h6">
            {dialogText}
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}
