import { useQuery } from '@tanstack/react-query';
import userInfoService from '../services/userInfoService';
import useLocalStorage from './useLocalStorage';
import useCookiesEnabled from './useCookiesEnabled';

export default function useUserIpData() {
  const [userCultureData, setUserCultureData] = useLocalStorage('userCultureData', null);
  useQuery(['userCultureData'], () => userInfoService.getUserCultureData(), {
    onSuccess: (data) => {
      if (useCookiesEnabled('functional')) {
        setUserCultureData(data);
      }
    },
    onError: () => {
      if (useCookiesEnabled('functional')) {
        setUserCultureData({ country: 'US' });
      }
    },
    enabled: userCultureData === null,
    retry: false,
  });
  if (userCultureData) {
    if (userCultureData.country == null) {
      userCultureData.country = 'US';
    }
    return { data: userCultureData, isLoading: false };
  }
  return { data: null, isLoading: true };
}
