import { makeStyles } from '@mui/styles';

const useMenuItemStyles = makeStyles((theme: any) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
    },
    backgroundColor: 'inherit',
    color: theme.palette.common.white,
  },
}));

export default useMenuItemStyles;
