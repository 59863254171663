import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React from 'react';
import { enqueueSnackbar } from 'notistack';
import Loader from '../../../Loader';
import SocialMediaShareIcons from './components/SocialMediaShareIcons';

interface NewViewDialogType{
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  link: string | undefined,
}

export default function NewViewDialog({
  isOpen, setIsOpen, link,
}: NewViewDialogType) {
  function onCopyLink() {
    if (link) {
      enqueueSnackbar('Link has been copied');
    }
  }

  return (
    <Dialog
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <DialogTitle
        sx={{ textAlign: 'center' }}
      >
        Share the screen
      </DialogTitle>
      <DialogContent
        sx={{
          maxWidth: '500px',
          minHeight: '50px',
          flexWrap: 'wrap',
          overflow: 'hidden',
          display: 'flex',
          position: 'relative',
        }}
      >
        <SocialMediaShareIcons
          shareUrl={link as string}
          title="StockTradeIQ Screener"
        />
        <Box sx={{ overflowWrap: 'break-word', width: '100%', padding: '10px 0 10px 0' }}>
          {link ? (
            <TextField
              hiddenLabel
              fullWidth
              variant="filled"
              size="small"
              value={link}
              multiline
            />
          ) : (
            <Loader />
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button type="button" onClick={() => setIsOpen(false)}>
          Close
        </Button>
        <CopyToClipboard text={link || ''} onCopy={() => onCopyLink()}>
          <Button>
            Copy link
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>
  );
}
