import {
  ClickAwayListener, MenuList, Paper, Popper,
} from '@mui/material';
import Grow from '@mui/material/Grow';

interface PopperMenuPropsModel{
    open: boolean,
    anchorEl: any,
    onClose: () => void,
    sx?: any,
}

export default function PopperMenu({
  open, anchorEl, children, onClose, sx = {},
}:
    React.PropsWithChildren<PopperMenuPropsModel>) {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
      style={{ zIndex: '1000', ...sx }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TransitionProps}
          style={{
            transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper sx={{ overflow: 'hidden' }}>
            <ClickAwayListener onClickAway={() => onClose()}>
              <MenuList sx={{ padding: 0 }}>
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
