import { Box } from '@mui/material';
import InteractiveGrid from './components/InteractiveGrid/InteractiveGrid';

interface StatisticsPropsModel{
  symbol: string,
}

export default function Statistics({ symbol }: StatisticsPropsModel) {
  return (
    <Box className="center-items" sx={{ gap: '20px' }}>
      <InteractiveGrid
        symbol={symbol}
      />
    </Box>
  );
}
