import { useQuery } from '@tanstack/react-query';
import NewsService from '../../../services/newsService';
import MostChangeStocks from './MostChangeStocks';

export default function MostGainerStocks() {
  const { data: mostLoserCompaniesData } = useQuery(['getMostLoserStockCompanies'], NewsService.getMostLoserStockCompanies);

  return (
    <MostChangeStocks
      label="Losers"
      data={mostLoserCompaniesData}
    />
  );
}
