import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

// eslint-disable-next-line import/no-mutable-exports
let globalDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4D4D4D',
      light: '#8C8C8C',
      dark: '#333333',
    },
    background: {
      paper: '#1A1A1A',
      default: '#121212',
    },
    info: {
      main: '#0F8BD9',
      dark: '#063959',
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.1)',
      selected: 'rgba(0, 0, 0, 0.3)',
    },
  },
});

export default globalDarkTheme;
