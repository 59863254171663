import { useQuery } from '@tanstack/react-query';
import NewsService from '../../../services/newsService';
import MostChangeStocks from './MostChangeStocks';

export default function MostActiveStocks() {
  const { data: mostActiveCompaniesData } = useQuery(['getMostActiveStockCompanies'], NewsService.getMostActiveStockCompanies);

  return (
    <MostChangeStocks
      label="Most Active"
      data={mostActiveCompaniesData}
    />
  );
}
