import { createTheme } from '@mui/material/styles';
import type { } from '@mui/x-data-grid-pro/themeAugmentation';
import globalDarkTheme from './globalDarkTheme';
import globalLightTheme from './globalLightTheme';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-date-pickers-pro/themeAugmentation';

declare global {
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

export default function globalTheme(isLight: boolean) {
  let theme = isLight ? globalLightTheme : globalDarkTheme;
  theme = createTheme(
    {
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-columnHeaders': {
                marginBottom: '10px', // Adjust the margin as needed
              },
              '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell': {
                borderBottom: 'none', // Remove bottom borders of headers and cells
              },
              '& .actions-column': {
                backgroundColor: '#F4FCFF', // Set your desired color here
              },
              '& .MuiDataGrid-cell': {
                borderRight: 'none', // Remove the right border of cells
              },
              '& .MuiDataGrid-row': {
                borderBottom: 'none', // Remove the border between rows
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            contained: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          },
        },
        MuiPaper: {
          defaultProps: {
            elevation: 2
          },
          styleOverrides: {
            root: {
              overflow: 'hidden',
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                backgroundColor: theme.palette.primary.main,
              },
              '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor: theme.palette.primary.light,
                minHeight: 24,
                border: '3px solid primary.main',
              },
              '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                backgroundColor: theme.palette.primary.light,
              },
              '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                backgroundColor: theme.palette.primary.light,
              },
              '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                backgroundColor: theme.palette.primary.light,
              },
              '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
    theme,
  );
  return theme;
}
