import {
  Box, Button, Dialog, Divider, Grid, Paper, Typography, styled,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Label } from '@mui/icons-material';

interface FreeResourcesExceededDialogSubscriptionPropsModel {
    buySubscription: (isTrial: boolean) => void,
    subscriptionName: string,
    price: number,
    oldPrice: number,
    isPrefered: boolean,
    subscriptionResources: string[],
    isAnnual: boolean
}

interface SubscriptionResourcePropsModel {
    label: string
}

function SubscriptionResource({ label }: SubscriptionResourcePropsModel) {
  return (
    <Box sx={{ position: 'relative', paddingLeft: '22px' }}>
      <ArrowRightIcon style={{ position: 'absolute', left: '-10px', top: '-5px' }} />
      <Typography style={{
        fontSize: '12px', fontWeight: '600', marginTop: '4px', marginBottom: '4px',
      }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default function FreeResourcesExceededDialogSubscription(
  {
    buySubscription, subscriptionName, price, oldPrice, isPrefered, subscriptionResources, isAnnual
  }: FreeResourcesExceededDialogSubscriptionPropsModel,
) {
  return (
    <>
      <Box sx={{
        height: '5%', marginTop: '10px', color: '#FFAB00', fontWeight: '700', marginBlock: '0 0 1rem', fontSize: '1.5rem',
      }}
      >
        {isPrefered && <RocketLaunchIcon />}
      </Box>
      <Box sx={{
        height: '6%', marginBottom: '20px', color: isPrefered ? '#FFAB00' : '#0288D1', fontWeight: '700', marginBlock: '0 0 1rem', fontSize: '1.5rem',
      }}
      >
        {subscriptionName}
      </Box>
      <Box sx={{
        height: '42%', margin: '20px', fontWeight: '400', fontSize: '0.75rem', textAlign: 'left',
      }}
      >
        {subscriptionResources.map((r, i, { length }) => (
          <Box>
            <SubscriptionResource label={r} />
            {i + 1 !== length && <Divider />}
          </Box>
        ))}
      </Box>
      <Box sx={{
        height: '2%', fontWeight: '750', fontSize: '8px',
      }}
      >
        LIMITED PRICE:
      </Box>
      <Box sx={{
        height: '8%', margin: '10px', fontWeight: '600', fontSize: '12px',
      }}
      >
        <s style={{ fontWeight: '800' }}>
          {oldPrice}
          $
        </s>
        {' '}
        <DoubleArrowIcon style={{ fontSize: '12px', color: '#FFB633' }} />
        {' '}
        <a style={{ fontWeight: '800' }}>
          {price}
          $
          {' '}
        </a>
        <a style={{ fontWeight: '600', fontSize: '11px' }}>
          /{isAnnual ? 'year' : 'month'}
        </a>
      </Box>
      <Box sx={{
        height: '10%', fontWeight: '400', borderRadius: '8px', fontSize: '1.5rem',
      }}
      >
        {isPrefered
          ? (
            <Button variant="contained" color="success" sx={{ width: '70%', fontSize: '10px', backgroundColor: '#7E57C2' }} onClick={() => buySubscription(false)}>
              I choose
              {' '}
              {subscriptionName}
            </Button>
          )
          : (
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: '70%', fontSize: '10px', borderColor: '#0288D180', color: '#0288D180',
              }}
              onClick={() => buySubscription(false)}
            >
              I choose
              {' '}
              {subscriptionName}
            </Button>
          )}
          <Button style={{ fontSize: '12px', color: '#0094FF'}} onClick={() => buySubscription(true)}>Try 7 days for free!</Button>

      </Box>
    </>
  );
}
