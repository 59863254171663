import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import AnalyticsGrid from '../../../components/analyticsGrid/analyticsGrid';

export default function IgnoredStocksScreener() {
  return (
    <Box sx={{ minHeight: '100%', width: '100%', minWidth: '600px' }}>
      <Helmet>
        <title>Stock Screener | Ignored Symbols | StockTradeIQ</title>
      </Helmet>
      <AnalyticsGrid
        customColumnVisibilityModel={{
          consecutiveYearsOfDividendIncrease: false,
          qShiftPreviousYear: false,
          grahamNetNet: false,
          roe: false,
          roic: false,
          quickRatioTTM: false,
          currentRatioTTM: false,
          pegRatioTTM: false,
          priceCashFlowRatioTTM: false,
          priceToFreeCashFlowsRatioTTM: false,
          grossProfitMarginTTM: false,
          netProfitMarginTTM: false,
          returnOnAssetsTTM: false,
        }}
        showGoalTextField={false}
        hiddenColumns={['moneyNeededToGoal']}
        screenerName="Ignored"
        autoSave="true"
        showAll={false}
        showFavourite={false}
        showIgnored
        onlyIgnored
      />
    </Box>
  );
}
