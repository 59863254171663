import axios from "axios";

const config = { headers: { 'Content-Type': 'application/json' } };

const getSymbolNote = async (symbol: string) => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Note/bySymbol/${symbol}`;
    const response = await axios.get(URL);
    return response.data;
};

const postSymbolNote = async (symbol: string, note: string) => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Note`;
    const result = await axios.post(URL, {symbol, note}, config);
    return result;
};

const NoteService = {
    getSymbolNote,
    postSymbolNote
};
export default NoteService;