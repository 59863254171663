import {
  Box,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import humanize from '../services/humanize';

interface PricePositionPropsModel {
  price: number,
  currency: string,
  positionQuantity?: number
  change: number
}

export default function PricePosition(props: PricePositionPropsModel) {
  const { } = props;

  const Container = styled(Paper)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '450px',
    margin: '30px',
  });

  const Text = styled(Typography)({
    marginLeft: '5px',
    fontSize: '15px',
    fontWeight: '600'
  });

  const PercentageChange = styled(Typography)({
    fontSize: '0.7rem',
    fontWeight: '600',
    marginBottom: '10px',
    marginLeft: '3px'
  });

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
      <Container>
        <Stack direction="row" sx={{ width: '100%', padding: '10px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography>Price:</Typography>
            <Text sx={{ color: props.change >= 0 ? '#0EAF00' : 'red' }}>
              {humanize.compactNumber(props.price, 2)}&nbsp;{props.currency}
            </Text>
            <PercentageChange sx={{ color: props.change >= 0 ? '#0EAF00' : 'red' }}>
              ({humanize.compactNumber(props.change, 2)}&nbsp;{props.currency})
            </PercentageChange>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography>Your&nbsp;position: </Typography>
            <Text style={{ color: '#0EAF00' }}>{props.positionQuantity && humanize.compactNumber(props.positionQuantity * props.price, 2)}&nbsp;{props.currency}</Text>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
