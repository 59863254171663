import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import DisplayPDF from '../../components/DisplayPDF/DisplayPDF';
import document from '../../assets/documents/terms_and_conditions_paid.pdf';

export default function TermsOfServiceForPaid() {
  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <title>Terms and Conditions for Paid Services | StockTradeIQ</title>
      </Helmet>
      <DisplayPDF
        document={document}
      />
    </Box>
  );
}
