import {
  Button, Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import React from 'react';

interface NewViewConfirmationType{
  confirmationDialogIsOpen: boolean,
  setConfirmationDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  updateExistingView: (label: string) => void,
  newLabel: string,
  setNewLabel: React.Dispatch<React.SetStateAction<string>>,
  setIsAddingView: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function NewViewConfirmation({
  confirmationDialogIsOpen, setConfirmationDialogIsOpen,
  updateExistingView, setNewLabel, setIsAddingView, newLabel,
}: NewViewConfirmationType) {
  function handleUpdateView() {
    setConfirmationDialogIsOpen(false);
    setIsAddingView(false);
    updateExistingView(newLabel);
    setNewLabel('');
  }

  function handleCancelClick() {
    setConfirmationDialogIsOpen(false);
    setIsAddingView(true);
  }

  return (
    <Dialog onClose={() => setConfirmationDialogIsOpen(false)} open={confirmationDialogIsOpen}>
      <DialogContent>
        <Typography>
          View with specific label already exist, are you sure you want update it?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button type="button" onClick={() => handleCancelClick()}>
          Cancel
        </Button>
        <Button type="submit" onClick={() => handleUpdateView()}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
