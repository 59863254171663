import { Box } from '@mui/material';
import {Helmet} from 'react-helmet';
import AnalyticsGrid from '../../../components/analyticsGrid/analyticsGrid';
import ShareGridStateDto from '../../../models/dtos/shareGridStateDto';


interface DividendStockScreenerProps{
  sharedScreenConfiguration?: ShareGridStateDto | null;
}

export default function DividendStockScreener({
  sharedScreenConfiguration = undefined,
}: DividendStockScreenerProps) {
  return (
    <Box sx={{ minHeight: '100%', width: '100%', minWidth: '600px' }}>
      <Helmet>
        <title>Stock Screener | Find Top Dividend Stocks | StockTradeIQ</title>
      </Helmet>
      <AnalyticsGrid
        customColumnVisibilityModel={{
          grahamNetNet: false,
          roe: false,
          roic: false,
          quickRatioTTM: false,
          currentRatioTTM: false,
          pegRatioTTM: false,
          priceCashFlowRatioTTM: false,
          priceToFreeCashFlowsRatioTTM: false,
          grossProfitMarginTTM: false,
          netProfitMarginTTM: false,
          returnOnAssetsTTM: false,
        }}
        showGoalTextField
        hiddenColumns={[]}
        screenerName="dividend"
        showAll
        showFavourite
        showIgnored={false}
        showOnlyDividendStocksButton
        sharedScreenConfiguration={sharedScreenConfiguration}
      />
    </Box>
  );
}
