import { number } from "yup";

function compactNumber(num: number, rounding: number) {

  if (!num){
    return 0;
  }

  const suffixes = [
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];
  const foundedSufix = suffixes.slice().reverse().find((suffix: any) => (
    Math.abs(num) >= suffix.value
  ));
  return foundedSufix
    ? (num / foundedSufix.value).toFixed(rounding) + foundedSufix.symbol
    : num.toFixed(rounding);
}

function readableValue(value: any) {
  if (value === null || value === undefined){
    return value;
  }

  if (typeof value === 'number' && (value - Math.floor(value)) !== 0) {
    return Humanize.compactNumber(value, 3);
  }

  if (typeof value.getMonth === 'function'){
    return value.toLocaleDateString();
  }

  // if (typeof value === 'Date'){
  //   return date(value as Date);
  // }
  return value;
}

const Humanize = {
  compactNumber,
  readableValue
};

export default Humanize;
