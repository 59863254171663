import { Box } from '@mui/material';
import SingleNews from '../../../../components/SingleNews/SingleNews';
import CompanyNewsDto from '../../../../models/dtos/comapnyNewsDto';
import useCompanyNews from '../hooks/useCompanyNews';

interface CompanyNewsPropsModel{
  symbol: string | undefined,
  limit?: number
}

export default function CompanyNews({ symbol, limit = 10 }: CompanyNewsPropsModel) {
  const { data: companyNews } = useCompanyNews(symbol, limit);

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', gap: '1rem', padding: '0 1rem 0 1rem',
    }}
    >
      {companyNews && companyNews.map((news: CompanyNewsDto) => (
        <SingleNews
          newsData={news}
          key={news.url}
        />
      ))}
    </Box>
  );
}
