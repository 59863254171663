import PieChart from '../../../../components/charts/PieChart';
import usePortfolioValueByCompanyByPortfolioIds from '../../../../hooks/usePortfoliosValueByCompany';

interface Props {
  selectedPortfolioIds: number[]
}

export default function PieChartBySector({selectedPortfolioIds}: Props) {
  const { data, isFetching } = usePortfolioValueByCompanyByPortfolioIds(selectedPortfolioIds);

  return (
    <PieChart
      data={data}
      label="Companies"
      isLoading={isFetching}
      xName="symbol"
      yName="value"
      seriesName="Company"
    />
  );
}
