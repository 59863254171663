import { Box, Grid, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAdsContext } from '../../../contexts/AdsContext';
import PortfolioAnalysisService from '../../../services/portfolioAnalysisService';
import useGlobalStyles from '../../../styles/useGlobalStyles';
import DividendsTable from './components/DividendTable';
import MainDividendChart from './components/MainDividendChart';
import './dividends.css';
import { Helmet } from 'react-helmet';

interface Props {
  selectedPortfolioIds: number[]
}

export default function Dividends({ selectedPortfolioIds }: Props) {
  const {
    data: dividendValueHistoryQuery,
    isFetching,
    isLoading,
  } = useQuery(['getDividendValueHistory', selectedPortfolioIds], () => PortfolioAnalysisService.getDividendValueHistory(selectedPortfolioIds));
  const adsContext = useAdsContext();
  const globalClasses = useGlobalStyles();
  return (
    <Box
      sx={{
        width: '100%', height: '100%',
      }}
      className="center-items"
    >
      <Paper
        sx={{
          height: '95%',
          width: '100%',
          padding: '20px'
        }}
      >
        <Helmet>
          <title>Dividend Portfolio Analysis | Maximize Your Dividend Returns | StockTradeIQ</title>
        </Helmet>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{ width: '80%', fontSize: '1.5rem', fontWeight: '400', marginBottom: '20px'}}
              className={globalClasses.header}
            >
              Portfolio Dividend Payments
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ height: '70vh' }}>
              <DividendsTable
                dividendValueHistoryQuery={dividendValueHistoryQuery}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{
              height: '70vh',
            }}
            >
              <MainDividendChart
                dividendValueHistoryQuery={dividendValueHistoryQuery}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
