import axios from 'axios';

function isEmptyOrSpaces(str: string) {
  return str === null || str.match(/^ *$/) !== null;
}

const getFoundedCompanies = async (text: string, countryCode: string) => {
  if (isEmptyOrSpaces(text)) return [];
  const URL = `${process.env.REACT_APP_SERVER_URL}/AvailableStock/findTopFive/${text}?country=${countryCode}`;
  const response = await axios.get(URL);
  return response.data;
};

const DashboardService = {
  getFoundedCompanies,
};

export default DashboardService;
