import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { protectedResources } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import CustomNavigationClient from '../AADB2C/CustomNavigationClient';

interface RequestInterceptorProps {
  children: JSX.Element;
}

// eslint-disable-next-line react/function-component-definition
const RequestInterceptor: React.FC<RequestInterceptorProps> = ({
  children,
}: RequestInterceptorProps) => {
    const { instance, inProgress } = useMsal();

    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    instance.setNavigationClient(navigationClient);

  useEffect(() => {
    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(async (request: any) => {

      if (inProgress !== InteractionStatus.None){
        const redirectPromiseResult = await instance.handleRedirectPromise();
      }

      const activeAccount = instance.getActiveAccount();
      if (!activeAccount) {
        return request;
      }

      const response = await instance.acquireTokenSilent({
        scopes: protectedResources.apiHello.scopes,
        account: activeAccount,
      // eslint-disable-next-line consistent-return
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect({scopes:protectedResources.apiHello.scopes, extraQueryParameters: ['redirectUri'], account: activeAccount, ...request});
        }
      });

      if (response?.accessToken == undefined){
        return instance.acquireTokenRedirect({scopes:protectedResources.apiHello.scopes, extraQueryParameters: ['redirectUri'], account: activeAccount, ...request});
      }

      const bearer = `Bearer ${response?.accessToken}`;
      request.headers.Authorization = bearer;
      axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

      return request;
    });
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default RequestInterceptor;
