import { Box } from '@mui/material';
import AdvancedPriceChart from './components/AdvancedPriceChart/AdvancedPriceChart';

interface ChartPricePropsModel{
  symbol: string,
}

export default function StockPriceChart({ symbol }: ChartPricePropsModel) {
  return (
    <Box sx={{ width: '100%', height: '100%' }} className="center-items">
      <AdvancedPriceChart
        symbol={symbol}
        full={false}
      />
    </Box>
  );
}
