import axios from 'axios';
import CustomGridStateDto from '../models/dtos/customGridStateDto';
import ExchangesByCountry from '../models/dtos/exchangesByCountry';
import ShareGridStateDto from '../models/dtos/shareGridStateDto';
import ShareGridState from '../models/shareGridState';

async function postCustomGridStateByLabel(data: CustomGridStateDto) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration`;
  const response = await axios.post(URL, data);
  return response;
}

async function putCustomGridStateByLabel(data: CustomGridStateDto) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration`;
  const response = await axios.put(URL, data);
  return response;
}

async function getCustomGridStates(screenerName: string) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration/${screenerName}`;
  const response = await axios.get(URL);
  return response.data;
}

async function deleteCustomGridStateByLabel(screenerName: string, label: string) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration/${screenerName}/${label}`;
  const responce = await axios.delete(URL);
  return responce;
}

async function getExchanges() {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Stock/conglomerateByCountry`;
  const responce = await axios.get<ExchangesByCountry[]>(URL);
  return responce.data;
}

async function postScreenShareConfiguration(configuration: ShareGridState) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration/Share`;
  const responce = await axios.post<string>(URL, configuration);
  return responce.data;
}

async function getScreenShareConfiguration(id: string) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration/Share/${id}`;
  const responce = await axios.get<ShareGridStateDto>(URL);
  return responce.data;
}

async function deleteScreenShareConfiguration(id: string) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration/Share/${id}`;
  const responce = await axios.delete(URL);
  return responce.data;
}

async function putScreenShareConfigurationName(hash: string, name: string) {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration/Share/${hash}?name=${name}`;
  const response = await axios.put(URL);
  return response;
}

async function getSharedConfigurations() {
  const URL = `${process.env.REACT_APP_SERVER_URL}/GridConfiguration/Share`;
  const responce = await axios.get<ShareGridStateDto[]>(URL);
  return responce.data;
}

const DataGridService = {
  postCustomGridStateByLabel,
  putCustomGridStateByLabel,
  getCustomGridStates,
  deleteCustomGridStateByLabel,
  getExchanges,
  postScreenShareConfiguration,
  getScreenShareConfiguration,
  getSharedConfigurations,
  deleteScreenShareConfiguration,
  putScreenShareConfigurationName,
};

export default DataGridService;
