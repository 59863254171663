import {
  createContext, useState, useContext, useMemo,
} from 'react';

type DrawerContextType = {
  isOpened: boolean;
  toggleIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

type DrawerContextProviderProps = {
  children: React.ReactNode;
};

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

function DrawerContextProvider({ children }: DrawerContextProviderProps) {
  const [isOpened, toggleIsOpened] = useState(false);

  const value = useMemo(() => ({
    isOpened,
    toggleIsOpened,
  }), [isOpened]);

  return (
    <DrawerContext.Provider value={value}>
      {children}
    </DrawerContext.Provider>
  );
}

const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error('useDrawerContext must be used within a DrawerContextProvider');
  }
  return context;
};

export { DrawerContextProvider, useDrawerContext };
