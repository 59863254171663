import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import {
  createContext, useContext, useMemo,
} from 'react';
import TransactionsGridService from '../services/transactionsGridService';

type MutationsContextType = {
  postTransactionsData: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>,
};

type MutationsContextProviderProps = {
  children: React.ReactNode;
};

const MutationsContext = createContext<MutationsContextType | undefined>(undefined);

function MutationsContextProvider({ children }: MutationsContextProviderProps) {
  const queryClient = useQueryClient();

  const postTransactionsData = useMutation(
    (params: any) => (TransactionsGridService.postTransactionsData(params.file, params.portfolioId)),
    {
      onError: () => {
        enqueueSnackbar('Import failed.', { variant: 'error' });
      },
      onSuccess: (data: { data: any; }, variables: any, context: any) => {
        console.log(data);
        queryClient.refetchQueries(['getTransactionsData']);
        queryClient.refetchQueries(['getPortfolioNames']);
        queryClient.refetchQueries(['getAllPortfolioValues']);
        queryClient.refetchQueries(['getDividendValueHistory']);
        queryClient.refetchQueries(['getPortfolioValueBySectors']);
        queryClient.refetchQueries(['getPortfoliosValueByCompanies']);
        enqueueSnackbar('New transactions have been imported succesfully');
      },
    },
  );

  const value = useMemo(() => ({
    postTransactionsData,
  }), [postTransactionsData]);

  return (
    <MutationsContext.Provider value={value}>
      {children}
    </MutationsContext.Provider>
  );
}

const useMutationsContext = () => {
  const context = useContext(MutationsContext);
  if (context === undefined) {
    throw new Error('useMutationsContext must be used within a MutationsContextProvider');
  }
  return context;
};

export { MutationsContextProvider, useMutationsContext };
