import clsx from 'clsx';
import {
  Drawer, useMediaQuery, useTheme, Box,
  IconButton,
} from '@mui/material';
import { useDrawerContext } from '../../contexts/DrawerContext';
import MenuItemsList from './MenuItemsList';
import drawer from '../../constants/drawer';
import './Drawer.css';
import useDrawerStyles from '../styles/useDrawerStyles';
import { useAdsContext } from '../../contexts/AdsContext';
import { ChevronLeft, Menu } from '@mui/icons-material';
import useToolbarStyles from '../styles/useToolbarStyles';

function CustomDrawer() {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const toolbarClasses = useToolbarStyles();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useDrawerStyles();
  const adsContext = useAdsContext();

  return (
    <Drawer
      className={clsx('drawer', { 'temporary-drawer': !isLargeScreen })}
      variant={isLargeScreen ? 'permanent' : 'temporary'}
      open={!!(!isLargeScreen && isOpened)}
      onClose={() => toggleIsOpened(!isOpened)}
      classes={{ paper: classes.drawerPaper }}
      sx={{
        width: isOpened ? drawer.OpenDrawerWidth : drawer.CloseDrawerWidth,
        zIndex: theme.zIndex.appBar,
      }}
    >
      <Box>
        <IconButton
          color="inherit"
          onClick={() => toggleIsOpened(!isOpened)}
          classes={{ root: toolbarClasses.icon }}
          sx={{ paddingLeft: '15px' }}
        >
          {isOpened ? <ChevronLeft /> : <Menu />}
        </IconButton>
      </Box>
      <Box sx={{ paddingLeft: isOpened ? '20px' : '0' }}>
        <Box className="drawer-elems" sx={{ height: '100%', paddingTop: '20px' }}>
          <MenuItemsList />
        </Box>
        {adsContext.adsAreVisible && <Box className="drawer-ads center-items ad">ads</Box>}
      </Box>
    </Drawer>
  );
}

export default CustomDrawer;
