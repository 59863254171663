import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface ToolbarTogglePropsModel {
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  valuesToSelect: string[],
}

export default function ToolbarToggle({
  value, setValue, valuesToSelect,
}: ToolbarTogglePropsModel) {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue !== null) {
      setValue(newValue);
    } else {
      setValue((oldValue) => oldValue);
    }
  };
  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      {valuesToSelect.map((valueToSelect: string) => (
        <ToggleButton sx={{ height: '40px', alignSelf: 'center' }} value={valueToSelect} key={valueToSelect}>{valueToSelect}</ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
