import { InputBase } from '@mui/material';
import {
  GridRenderEditCellParams, useGridApiContext,
} from '@mui/x-data-grid-pro';

export default function QuantityEditCell(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <InputBase sx={{ width: '90%'}} value={value} onChange={handleValueChange} placeholder='Enter a Quantity'/>
  );
}
