import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSpacingParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import CancelIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Paper, CircularProgress, LinearProgress,
  TableCell,
  Box,
  InputBase,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import TransactionsGridService from '../../../../services/transactionsGridService';
import EditToolbar from './EditToolbar';
import { useMutationsContext } from '../../../../contexts/MutationsContext';
import SymbolEditCell from './SymbolEditCell';
import './transactionsGrid.css';
import QuantityEditCell from './QuantityEditCell';
import DateEditCell from './DateEditCell';
import CustomCell from '../../../../components/analyticsGrid/CustomCell';
import TransactionsGridEditToolbar from './TransactionsGridEditToolbar';
import { reject } from 'lodash';

interface Props {
  portfolioName: string,
  portfolioId: number
}

export default function TransactionsGrid( { portfolioName, portfolioId }: Props) {
  const queryClient = useQueryClient();
  const { data: transactionsDataQuery, isLoading: isLoadingTransactionsData } = useQuery([`getTransactionsData`, portfolioName], () => TransactionsGridService.getTransactionsDataByPortfolio(portfolioName));
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowIsInWaitingMode, setRowIsInWaitingMode] = useState<any>();
  const { postTransactionsData } = useMutationsContext();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  useEffect(() => {
    if (transactionsDataQuery) {
      const newRowIsInWaitingMode = Object();
      transactionsDataQuery.forEach((element: GridRowModel) => {
        newRowIsInWaitingMode[element.id] = false;
      });
      setRowIsInWaitingMode(newRowIsInWaitingMode);
      setRows(transactionsDataQuery.map((row: GridRowModel) => (
        { ...row, date: new Date(row.date) }
      )));
    }
  }, [isLoadingTransactionsData, transactionsDataQuery]);

  function updateRows(newRows: GridRowModel[]) {
    queryClient.setQueryData([`getTransactionsData`, portfolioName], newRows);
    return newRows;
  }

  const postNewTransaction = useMutation((data: Object) => (
    TransactionsGridService.postNewTransaction(data)), {
    onMutate: ((variables: any) => {
      const updatedRow = { ...variables, isNew: false };
      return { updatedRow };
    }),
    onError: ((error, variables: any) => {
      setRowModesModel((oldModel) => (
        { ...oldModel, [variables.id]: { mode: GridRowModes.Edit } }
      ));
      enqueueSnackbar('Entered data is incorrect', { variant: 'error' });
    }),
    onSuccess: ((data, variables: any, context: any) => {
      queryClient.refetchQueries(['getAllPortfolioValues']);
      queryClient.refetchQueries(['getDividendValueHistory']);
      queryClient.refetchQueries(['getPortfolioValueBySectors']);
      queryClient.refetchQueries(['getPortfoliosValueByCompanies']);
      queryClient.refetchQueries(['getPortfolioNames']);
      
      setRows((oldRows) => updateRows(oldRows.map((row) => (
        row.id === variables.id ? { ...context.updatedRow, id: data.data } : row
      ))));
      enqueueSnackbar(`New ${variables.symbol} transactions has been saved succesfully`);
    }),
    onSettled: ((data, error, variables: any) => (
      setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [variables.id]: false }))
    )),
  });
  const deleteTransactionById = useMutation((params: any) => (
    TransactionsGridService.deleteTransactionById(params.id)), {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(['getAllPortfolioValues']);
      queryClient.refetchQueries(['getDividendValueHistory']);
      queryClient.refetchQueries(['getPortfolioValueBySectors']);
      queryClient.refetchQueries(['getPortfoliosValueByCompanies']);
      enqueueSnackbar(`${variables.row.symbol} transactions has been deleted succesfully`);
    },
  });

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    };
  }, []);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleSaveClick = (params: any) => () => {
    setRowModesModel((oldModel) => ({ ...oldModel, [params.row.id]: { mode: GridRowModes.View } }));
    setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [params.id]: true }));
  };

  const handleDeleteClick = (params: any) => () => {
    setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== params.id)));
    deleteTransactionById.mutate(params);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== id)));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    if (newRow.symbol == null || newRow.symbol?.trim() === ''){
      reject(new Error("Symbol can't be empty"));
    }
    postNewTransaction.mutate({ ...newRow, portfolioId });
    return newRow;
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    enqueueSnackbar(error.message, { variant: 'error' });
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      editable: false,
      width: 100,
      flex: 0,
      getActions: (params: GridRowParams) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(params.id)}
            />,
          ];
        }

        if (rowIsInWaitingMode[params.id]) {
          return [
            <CircularProgress />,
          ];
        }

        return [
          // <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   onClick={handleEditClick(params.id)}
          // />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params)}
          />,
        ];
      },
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      editable: true,
      flex: 1,
      renderEditCell: (params: any) => (
        <SymbolEditCell {...params} />
      ),
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
    {
      field: 'date',
      type: 'date',
      headerName: 'Date',

      editable: true,
      flex: 1,
      renderEditCell: (params: any) => (
        <DateEditCell {...params}/>
      ),
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
    {
      field: 'quantity',
      type: 'number',
      headerAlign: 'left',
      headerName: 'Quantity',
      editable: true,
      flex: 1,
      renderEditCell: (params: any) => (
        <QuantityEditCell {...params}/>
      ),
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
  ];

  return (
    <Paper sx={{
      width: '90%', flexFlow: 'column', flex: '0 1 auto'
    }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        density="standard"
        editMode="row"
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 15]}
        autoHeight
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        getRowSpacing={getRowSpacing}
        processRowUpdate={processRowUpdate}
        onRowModesModelChange={(newModel) => (
          setRowModesModel((oldModel) => ({ ...oldModel, ...newModel }))
        )}
        getRowId={(row) => row.id}
        components={{
          Toolbar: TransactionsGridEditToolbar,
          LoadingOverlay: LinearProgress,
        }}
        loading={postTransactionsData.isLoading || isLoadingTransactionsData}
        componentsProps={{
          toolbar: {
            setRows, setRowModesModel, setRowIsInWaitingMode,
          },
        }}
      />
    </Paper>
  );
}
