import { Paper } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import Loader from '../Loader';
import './PieChart.css';
import ChartsService from '../../services/chartsService';

interface CustomResponsivePieProp {
  data: any;
  label: string;
  isLoading: boolean;
  xName: string;
  yName: string;
  seriesName: string;
}

export default function PieChart(props: CustomResponsivePieProp) {
  const {
    data, label, isLoading, xName, yName, seriesName,
  } = props;
  const [option, setOption] = useState<any>();
  const theme: Theme = useTheme();

  useEffect(() => {
    if (data) {
      const customOption = {
        title: {
          text: label,
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        xName: yName,
        yName: xName,
        series: [
          {
            name: seriesName,
            type: 'pie',
            radius: '50%',
            data: data.map((row: any) => ({
              value: row[yName],
              name: row[xName],
            })),
            label: {
              color: theme.palette.text.primary,
            },
          },
        ],
      };
      const newOption = ChartsService.getPieChartOption(theme, customOption);
      setOption(() => newOption);
    }
  }, [data, theme]);

  return (
    <Paper className="pie-chart center-items">
      {(isLoading || !option)
        ? <Loader />
        : (
          <ReactECharts
            notMerge
            option={option}
            style={{ width: '100%', height: '100%' }}
          />
        )}
    </Paper>
  );
}
