import { Paper } from '@mui/material';
import { useAdsContext } from '../../../contexts/AdsContext';

export default function NewsAds() {
  const adsContext = useAdsContext();

  if (adsContext.adsAreVisible) {
    return (
      <Paper sx={{ height: '285px' }} className="ad center-items">
        ads
      </Paper>
    );
  }
  return null;
}
