import {
  Box, Button, Paper, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import MostGainerStockCompany from '../../../models/dtos/mostGainerStockCompany';
import MostLoserStockCompany from '../../../models/dtos/mostLoserStockCompany';
import './MostChangeStocks.css';
import GrowingPage from './GrowingPage';
import Loader from '../../../components/Loader';
import Swiper from '../../../components/Swiper/Swiper';
import useGlobalStyles from '../../../styles/useGlobalStyles';

interface MostChangeStocksProps{
    data: MostGainerStockCompany[] | MostLoserStockCompany[] | undefined;
    label: string
}

export default function MostChangeStocks(props: MostChangeStocksProps) {
  const { data, label } = props;
  const navigate = useNavigate();
  const globalClasses = useGlobalStyles();

  function handleStockNameClick(symbol: string) {
    navigate(ROUTES.stockDetailedData(symbol));
  }

  return (
    <Paper
      sx={{
        height: '285px', display: 'inline-block', position: 'relative',
      }}
      className="center-items"
      elevation={0}
    >
      <Typography
        variant="h2"
        sx={{
          width: '100%', padding: '10px 0 10px 0', fontSize: '1.5rem', fontWeight: '400',
        }}
        className={globalClasses.header}
      >
        {label}
      </Typography>
      <Swiper
        swiperHeight="241px"
      >
        {data
          ? data.map((stock: MostGainerStockCompany) => (
            <GrowingPage key={stock.name + stock.price}>
              <Box className="most-change-page" sx={{ textAlign: 'center' }}>
                <Paper
                  sx={{
                    height: '70px', overflow: 'hidden', backgroundColor: 'inherit', whiteSpace: 'normal',
                  }}
                  elevation={0}
                >
                  <Button sx={{ fontSize: '12px', width: '100%', textOverflow: 'ellipsis' }} onClick={() => (handleStockNameClick(stock.symbol))}>{stock.name}</Button>
                </Paper>
                <Box sx={{ height: '50px' }}>
                  <Typography color={(stock.changesPercentage > 0 ? 'green' : 'red')}>
                    {stock.changesPercentage}
                    %
                  </Typography>
                </Box>
                <Box sx={{ height: '30px' }}>
                  <Typography variant="body2">
                    change:
                    {' '}
                    {stock.change}
                    $
                  </Typography>
                  <Typography variant="body2">
                    actual price:
                    {' '}
                    {stock.price}
                    $
                  </Typography>
                </Box>
              </Box>
            </GrowingPage>
          ))
          : <Box sx={{ width: '100%' }} className="center-items"><Loader /></Box>}
      </Swiper>
    </Paper>
  );
}
