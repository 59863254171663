import { Box } from "@mui/material";
import {Helmet} from 'react-helmet';
import SharedConfigurationsGrid from "./components/SharedConfigurationsGrid";

export default function SharedConfigurations() {
  return (
    <Box
      sx={{
        minHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '8%', gap: '20px',
      }}
    >
      <Helmet>
        <title>Stock Screener | Community | Shared Configurations | StockTradeIQ</title>
      </Helmet>
      <SharedConfigurationsGrid />
    </Box>
  );
}