/* eslint-disable global-require */
import { Box } from '@mui/material';
import './Badges.css';
import DividendBadge from '../../../assets/badges/dividend.png';
import GrowthBadge from '../../../assets/badges/growth.png';
import Badge from './Badge';

interface BadgesPropsModel{
  showDividendBadge: boolean,
  showGrowthBadge: boolean,
}

export default function Badges({
  showDividendBadge, showGrowthBadge,
}: BadgesPropsModel) {
  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '15px', width: '100%', alignItems: 'center',
    }}
    >
      {showDividendBadge && (
        <Badge
          badgeSrc={DividendBadge}
          label="Dividend badge"
          description="DIVIDEND BADGE: annual dividend increases for at least 3 years"
        />
      )}
      {showGrowthBadge && (
        <Badge
          badgeSrc={GrowthBadge}
          label="Growth badge"
          description="GROWTH BADGE: revenue per share increases for at least 3 years"
        />
      )}
    </Box>
  );
}
