import axios from 'axios';
import TransactionModel from '../models/dtos/transactionModel';

const getTransactionsData = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio`;
  const response = await axios.get<TransactionModel[]>(URL);
  return response.data;
}

const getTransactionsDataByPortfolio = async ( portfolioName: string ) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/GetAllByPortfolio?name=${portfolioName}`;
  const response = await axios.get<TransactionModel[]>(URL);
  return response.data;
}

const postTransactionsData = async (file: any, portfolioId: number) => {
  const formData = new FormData();
  formData.append('formFile', file);
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/ImportFromFile/${portfolioId}`;
  const result = await axios.post(URL, formData);
  return result;
};

const deleteTransactionById = async (id: number) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio?id=${id}`;
  await axios.delete(URL);
};

const postNewTransaction = async (data: Object) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Portfolio/Add`;
  const result = await axios.post(URL, data);
  return result;
};

const TransactionsGridService = {
  getTransactionsData,
  postTransactionsData,
  deleteTransactionById,
  postNewTransaction,
  getTransactionsDataByPortfolio,
};
export default TransactionsGridService;
