import { useIsAuthenticated } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import NoteService from '../services/symbolNoteService';

export default function useSymbolNote(symbol: string) {
  const isAuthenticated = useIsAuthenticated();
  return useQuery(['getSymbolNote', symbol], () => NoteService.getSymbolNote(symbol), {
    enabled: isAuthenticated,
    placeholderData: [],
  });
}
