import { Box, Typography } from '@mui/material';
import useGlobalStyles from '../../../../styles/useGlobalStyles';
import TableRow from './components/TableRow';

interface BasicTablePropsModel{
  data: Record<string, string | undefined | number | any> | undefined,
  isLoading: boolean,
}

export default function BasicTable({ data, isLoading }: BasicTablePropsModel) {
  const globalClasses = useGlobalStyles();

  return (
    <Box>
      <Box sx={{ width: '100%', position: 'relative', background: '#FFFFFF', padding: '20px'}}>
        { data && Object.entries(data).map(([key, value], index) => (
          <TableRow 
            label={key} 
            value={value} 
            key={key} 
            rowNumber={index} 
            isLoading={isLoading}/>
        ))}
      </Box>
    </Box>
  );
}
